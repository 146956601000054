import { type AuthChangeEvent, type Session } from '@supabase/supabase-js'
import { supabase } from '../SupabaseClient'
import { type UserMetaData } from '../../providers/UserProvider'

export function setUpAuth(
  onEvent: (
    _event: AuthChangeEvent,
    _session: Session | null
  ) => void | Promise<void>
): void {
  supabase.auth.onAuthStateChange(onEvent)
}

export async function signInWithEmailOTP(email: string): Promise<boolean> {
  // eslint-disable-next-line no-console -- Logging email for debugging purposes
  console.debug('signInWithEmailOTP', email)
  const { error } = await supabase.auth.signInWithOtp({
    email,
    options: {
      emailRedirectTo: 'https://app.noteplan.co',
    },
  })

  if (error) {
    // eslint-disable-next-line no-console -- TODO: report to mixpanel
    console.error(error)
    return false
  }

  return true
}

export async function verifyOTP(
  email: string,
  token: string
): Promise<boolean> {
  const { error } = await supabase.auth.verifyOtp({
    email,
    token,
    type: 'email',
  })

  if (error) {
    // eslint-disable-next-line no-console -- TODO: report to mixpanel
    console.error(error)
    return false
  }

  return true
}

export async function signOut(): Promise<void> {
  const { error } = await supabase.auth.signOut({ scope: 'local' })

  if (error) {
    // eslint-disable-next-line no-console -- TODO: report to mixpanel
    console.error(error)
  }
}

export async function session(): Promise<Session | null> {
  const { data, error } = await supabase.auth.getSession()

  if (error) {
    // eslint-disable-next-line no-console -- TODO: report to mixpanel
    console.error(error)
    throw error
  }

  return data.session ?? null
}

export async function setUserMetaData(meta: UserMetaData): Promise<boolean> {
  // Get the user id
  const { data: userData, error: userError } = await supabase.auth.getUser()

  if (userError) {
    // eslint-disable-next-line no-console -- TODO: report to mixpanel
    console.error(userError)
    throw userError
  }

  // Update the meta column in the user_profiles table
  const { error } = await supabase
    .from('user_profiles')
    .update({ meta })
    .eq('id', userData.user.id)

  if (error) {
    // eslint-disable-next-line no-console -- TODO: report to mixpanel
    console.error(error)
    throw new Error(error.message)
  }

  return true
}

export async function getUserMetaData(): Promise<UserMetaData> {
  // Get the user id
  const { data: userData, error: userError } = await supabase.auth.getUser()

  if (userError) {
    // eslint-disable-next-line no-console -- TODO: report to mixpanel
    console.error(userError)
    throw new Error(userError.message)
  }

  // Update the meta column in the user_profiles table
  const { data, error } = await supabase
    .from('user_profiles')
    .select('meta')
    .eq('id', userData.user.id)

  if (error) {
    // eslint-disable-next-line no-console -- TODO: report to mixpanel
    console.error(error)
    throw new Error(error.message)
  }

  // Edit: error is not cought, so throwing it here will stop the execution
  // if (data.length === 0 || !data[0]?.meta) {
  //   throw new Error('Meta data not found')
  // }

  return data?.[0]?.meta
}
