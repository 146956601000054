import { useQueryClient } from '@tanstack/react-query'
import { useCloudKitClient } from '../providers/CloudKitClientProvider'
import { useUserState } from '../providers/UserProvider'
import { useCachedNotesQueryClient } from '../providers/CachedNotesProvider'
import {
  type Note,
  type NoteType,
  isCalendarNote,
  isTeamspaceNote,
} from '../utils/syncUtils'
import { cacheKeys, projectNoteQueryKey } from '../utils/queryKeyFactory'
import { mapDeletes } from '../utils/mapAsState'
import { deleteNote } from '../lib/supabase/NoteOperations'
import { useSafeMutation } from './useSafeMutation'
import { deleteReferenceCache } from './useNoteReferences'
import { trackEvent } from '../lib/analytics'

type DeleteOptions = {
  recordName: string
  noteType: NoteType
  descendenceIds?: string[]
}

export function useDeleteNote() {
  const ck = useCloudKitClient()
  const user = useUserState()
  const privateUserId = user?.cloudKitUserId || user?.supabaseUserId
  const queryClient = useQueryClient()
  const cachedNotesQueryClient = useCachedNotesQueryClient()

  return useSafeMutation({
    mutationFn: async ({
      recordName,
      noteType,
      descendenceIds,
    }: DeleteOptions) => {
      // console.debug('[useDeleteNote] deleting', recordName)

      // Teamspace notes are managed by supabase
      if (user?.cloudKitUserId && !isTeamspaceNote(noteType)) {
        const ids = [recordName, ...(descendenceIds ?? [])].reverse()
        return ck.deleteNotes(ids)
      }

      if (user?.supabaseUserId) {
        return deleteNote(recordName)
      }

      throw new Error('Not signed in')
    },
    onMutate: async ({
      recordName,
      noteType,
      descendenceIds,
    }: DeleteOptions) => {
      // console.debug(
      //   '[useDeleteNote] onMutate',
      //   recordName,
      //   noteType,
      //   descendenceIds
      let previousNotes = new Map<string, Note>()
      let queryKey: string[] | undefined

      if (isTeamspaceNote(noteType)) {
        if (isCalendarNote(noteType)) {
          if (user?.supabaseUserId) {
            queryKey = [...cacheKeys.teamCalendarNotes(user.supabaseUserId)]
          }
        } else if (user?.supabaseUserId) {
          queryKey = [...cacheKeys.teamProjectNotes(user.supabaseUserId)]
        }
      } else if (isCalendarNote(noteType)) {
        if (privateUserId) {
          queryKey = [...cacheKeys.privateCalendarNotes(privateUserId)]
        }
      } else if (privateUserId) {
        queryKey = [...cacheKeys.privateProjectNotes(privateUserId)]
      }

      if (queryKey) {
        await cachedNotesQueryClient?.cancelQueries(queryKey)
        previousNotes =
          cachedNotesQueryClient?.getQueryData<Map<string, Note>>(queryKey) ||
          new Map<string, Note>()
      }

      // optimistically remove the note
      try {
        cachedNotesQueryClient.setQueriesData<Map<string, Note>>(
          cacheKeys.notes,
          (oldData: Map<string, Note>) => {
            if (!oldData) {
              return new Map()
            }
            return mapDeletes(oldData, [recordName, ...(descendenceIds || [])])
          }
        )
      } catch (error) {
        // console.error('Error in setQueriesData:', error)
      }

      queryClient.removeQueries(projectNoteQueryKey({ recordName, noteType }))

      // return a context object with the snapshotted value
      return previousNotes
    },
    onError: (_err, variables: DeleteOptions, context: Map<string, Note>) => {
      console.error('[useDeleteNote] onError', _err, variables, context)
      const queryKey = isTeamspaceNote(variables.noteType)
        ? isCalendarNote(variables.noteType)
          ? cacheKeys.teamCalendarNotes(user.supabaseUserId)
          : cacheKeys.teamProjectNotes(user.supabaseUserId)
        : isCalendarNote(variables.noteType)
          ? cacheKeys.privateCalendarNotes(privateUserId)
          : cacheKeys.privateProjectNotes(privateUserId)

      cachedNotesQueryClient.setQueryData<Map<string, Note>>(queryKey, context)
    },
    onSuccess: (
      _data,
      { recordName, noteType, descendenceIds }: DeleteOptions
    ) => {
      trackEvent('WEB - Note Deleted', {
        recordName,
        noteType,
        descendenceIds,
      })

      // Just update the regular notes references, you can't normally delete calendar notes
      if (!isCalendarNote(noteType)) {
        for (const id of [recordName, ...(descendenceIds ?? [])]) {
          deleteReferenceCache(queryClient, id, user)
        }
      }
    },
  })
}
