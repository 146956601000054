import { useCallback, useMemo, useState } from 'react'
import { v4 as uuid } from 'uuid'
import dayjs from 'dayjs'
import { type LinkMarkSuggestionItem } from '@packages/blocknote-core'
import { useCreateNote } from '../../hooks/useCreateNote'
import { useSelectedDateDispatch } from '../../providers/SelectedDateProvider'
import { useUserState } from '../../providers/UserProvider'
import {
  type SelectedDate,
  dateToWeek,
  selectedDateToKey,
  useSelectedDate,
} from '../../providers/SelectedDateProvider'
import { type FromNote, useNoteReferences } from '../../hooks/useNoteReferences'
import useNote, { type useNoteConfig } from '../../hooks/useNote'
import {
  type Note,
  NoteType,
  filenameToKey,
  isCalendarNote,
  isTeamspaceNote,
} from '../../utils/syncUtils'
import { tagList } from '../../utils/tagList'
import { noteList } from '../../utils/noteList'
import { dateLinkSuggestions } from '../../utils/dateLinkSuggestionParser'
import { useSidebarProvider } from '../../providers/SidebarProvider'
import { useSelectedRecordName } from '../../providers/SelectedRecordNameProvider'
import {
  usePrivateProjectNotes,
  useTeamProjectNotes,
} from '../../providers/CachedNotesProvider'
import TipTapEditor from './TipTapEditor'
import { WeekNoteReference } from './WeekNoteReference'
import { NoteReference } from './NoteReference'
import { NoteHeader } from './NoteHeader'

type Props = {
  showCalendar: boolean
  toggleCalendar: () => void
  onCommandBarOpen: (_visible: boolean, _search: string) => void
  isSubscribed?: boolean
  isGuest?: boolean
  isTrialLoading?: boolean
  isTrialRunning?: string | null
  setDialogOpen?: (_value: boolean) => void
}

export default function NoteEditor({
  showCalendar,
  toggleCalendar,
  onCommandBarOpen,
  isSubscribed,
  isGuest,
  isTrialRunning,
  isTrialLoading,
  setDialogOpen,
}: Props) {
  const selectedRecordName = useSelectedRecordName()
  const {
    noteKey,
    handleSelectRecordName,
    handleRevealNote,
    shouldForceUpdateEditor,
    privateNotesMap,
    teamNotesMap,
    notesMap,
  } = useSidebarProvider()
  const { isLoading, data: note } = useNote(noteKey)
  const selectedDate = useSelectedDate()
  const [needsUpload, setNeedsUpload] = useState(false)
  const selectedDay: SelectedDate = useMemo(
    () => ({
      active: 'day',
      date: selectedDate.date,
      ...dateToWeek(selectedDate.date),
    }),
    [selectedDate]
  )
  const weekNoteKey: useNoteConfig | null = useMemo(() => {
    if (isCalendarNote(noteKey?.noteType) && selectedDate.active === 'day') {
      // because selectedDate can store a different day and week, we need always the selected day for the key
      const key = selectedDateToKey({ ...selectedDay, active: 'week' })
      return { ...noteKey, recordName: 'weekly', filename: key, key }
    }
    return null
  }, [noteKey, selectedDate.active, selectedDay])
  const { isLoading: isLoadingWeek, data: weekNote } = useNote(weekNoteKey)

  // # region references
  const { isLoading: isLoadingPrivateReferences, data: privateReferences } =
    useNoteReferences('private', privateNotesMap)
  const { isLoading: isLoadingTeamReferences, data: teamReferences } =
    useNoteReferences('team', teamNotesMap)
  const noteReferences: Map<string, FromNote> | null = useMemo(() => {
    if (!note) return null
    const noteID = isCalendarNote(note.noteType)
      ? filenameToKey(note.filename)
      : note.recordName ?? selectedRecordName

    // private and team references complete loading at different times, show what finished first
    // merge private and team references when both are loaded
    // get the references for the selected note
    const privateRefs = privateReferences?.get(noteID)?.incoming ?? new Map()
    const teamRefs = teamReferences?.get(noteID)?.incoming ?? new Map()

    return new Map(
      [...privateRefs, ...teamRefs].filter(
        ([_, fromNote]) => fromNote.blocks.length > 0
      )
    )
  }, [privateReferences, teamReferences, note, selectedRecordName])
  // # endregion

  // # region mark
  const user = useUserState()
  const { data: privateNotesByTitle } = usePrivateProjectNotes(user, true)
  const privateNotesByTitleMap = privateNotesByTitle as Map<string, Note[]>
  const { data: teamNotesByTitle } = useTeamProjectNotes(user, true)
  const teamNotesByTitleMap = teamNotesByTitle as Map<string, Note[]>
  const createNoteMutation = useCreateNote((note: Note) => {
    handleRevealNote(note.recordName)
  })
  const selectedDateDispatch = useSelectedDateDispatch()

  // When a user clicks on a mark such as a hashtag, wikilink, datelink...
  const handleMarkClicked = useCallback(
    (event: MouseEvent) => {
      const element = event.target as HTMLElement

      if (element.hasAttribute('data-hashtag')) {
        onCommandBarOpen(true, `tag: ${element.innerText}`)
      } else if (element.hasAttribute('data-wikilink')) {
        const wikiLink = element.innerText
        const notes = isTeamspaceNote(noteKey.noteType)
          ? teamNotesByTitleMap?.get(wikiLink)
          : privateNotesByTitleMap?.get(wikiLink)
        const altNotes = isTeamspaceNote(noteKey.noteType)
          ? privateNotesByTitleMap?.get(wikiLink)
          : teamNotesByTitleMap?.get(wikiLink)
        // attempt to open the 'closest' note first
        const note =
          (notes && notes.length > 0 && notes[0]) ||
          (altNotes && altNotes.length > 0 && altNotes[0]) ||
          null

        if (note?.recordName) {
          handleSelectRecordName(note.recordName)
        } else {
          const recordName = uuid()
          createNoteMutation.mutate({
            content: `# ${wikiLink}`,
            filename: wikiLink,
            parent: noteKey.parent,
            recordName,
            noteType: isTeamspaceNote(noteKey.noteType)
              ? NoteType.TEAM_SPACE_NOTE
              : NoteType.PROJECT_NOTE,
            isDir: false,
          })
          handleSelectRecordName(recordName)
        }
      } else if (element.hasAttribute('data-datelink')) {
        const dateLink = element.innerText
        // if (dateLink === '@today') {
        //   selectedDateDispatch({ type: 'today', forceDay: true })
        // } else if (dateLink === '@tomorrow') {
        //   selectedDateDispatch({ type: 'add', amount: 1, unit: 'day' })
        // } else if (dateLink === '@yesterday') {
        //   selectedDateDispatch({ type: 'subtract', amount: 1, unit: 'day' })
        // } else {
        const weekRegex = />(\d{4})-W(\d{1,2})/
        const matches = weekRegex.exec(dateLink)

        if (matches) {
          const year = parseInt(matches[1])
          const week = parseInt(matches[2])

          selectedDateDispatch({ type: 'setWeek', year, week })
        } else {
          const cleanedDateLink = dateLink.replace(/[@><]/g, '')
          const date = dayjs(cleanedDateLink)

          if (date.isValid()) {
            selectedDateDispatch({ type: 'setDay', date })
          }
        }
        // }
      }
    },
    [
      onCommandBarOpen,
      noteKey.noteType,
      noteKey.parent,
      teamNotesByTitleMap,
      privateNotesByTitleMap,
      handleSelectRecordName,
      createNoteMutation,
      selectedDateDispatch,
    ]
  )

  const handleLoadSuggestions = useCallback(
    (prefix: string, keyword: string): (string | LinkMarkSuggestionItem)[] => {
      // Implemented logic to load hashtags
      const notes = Array.from(notesMap.values()).filter(
        (note) => !note.isFolder && !note.filename.startsWith('@')
      )

      if (['@', '#'].includes(prefix)) {
        return tagList(notes, prefix)
      }

      // WikiLinks auto-completion
      if (prefix == '[[') {
        return noteList(notes)
      }

      if (prefix == '>') {
        return dateLinkSuggestions(keyword)
      }

      return []
    },
    [notesMap]
  )
  // # endregion

  let isEditable: boolean | undefined
  if (
    isTrialLoading ||
    isGuest ||
    (isSubscribed !== undefined && isTrialRunning !== null)
  ) {
    isEditable = Boolean(
      isTrialLoading ||
        isSubscribed ||
        isGuest ||
        isTrialRunning == 'true' ||
        (selectedDate.date.isSame(new Date(), 'day') &&
          selectedRecordName === 'daily')
    )
  }

  return (
    <div className='note-body flex flex-col'>
      <NoteHeader
        showCalendar={showCalendar}
        toggleCalendar={toggleCalendar}
        needsUpload={needsUpload}
        noteType={noteKey?.noteType}
      />
      <div className='editor-container-wrapper'>
        {Boolean(weekNoteKey) && (
          <WeekNoteReference
            isLoading={isLoadingWeek}
            weekNote={weekNote}
            selectedDate={selectedDay}
            jumpToWeek={({ week, year }) => {
              selectedDateDispatch({ type: 'setWeek', week, year })
            }}
          />
        )}
        {noteReferences?.size > 0 && (
          <NoteReference
            isLoading={
              isLoadingTeamReferences ? isLoadingPrivateReferences : null
            }
            references={noteReferences}
            // onSelectNote={handleRevealNote}
            onSelectNote={handleSelectRecordName}
          />
        )}
        {(Boolean(weekNoteKey) || noteReferences?.size > 0) && (
          <div
            className='mx-auto mt-4 w-full max-w-3xl'
            style={{ paddingInline: '54px' }}
          >
            <hr className='my-1 w-full opacity-25' />
          </div>
        )}
        <TipTapEditor
          shouldForceUpdateEditor={shouldForceUpdateEditor}
          isLoading={isLoading}
          note={note}
          setNeedsUpload={setNeedsUpload}
          onMarkClicked={handleMarkClicked}
          onLoadSuggestions={handleLoadSuggestions}
          isEditable={isEditable}
          handleClick={() => {
            if (
              !(
                isTrialLoading ||
                isSubscribed ||
                isTrialRunning == 'true' ||
                isGuest ||
                (selectedDate.date.isSame(new Date(), 'day') &&
                  selectedRecordName === 'daily')
              ) &&
              setDialogOpen
            ) {
              setDialogOpen(true)
            }
          }}
        />
      </div>
    </div>
  )
}
