import { useEffect, useState } from 'react'
import { useCloudKitClient } from '../providers/CloudKitClientProvider'
import { type User, useUserState } from '../providers/UserProvider'
import { stripeRequest } from '../lib/stripe'
import { identify, peopleSet, trackEvent } from '../lib/analytics'
import { useFetchUserDefault } from './useFetchUserDefault'

export function useSubscriptionVerification() {
  const [error, setError] = useState<string | null>(null)
  const [isTrialLoading, setIsTrialLoading] = useState<boolean>(true)
  const user = useUserState()
  const cloudKitClient = useCloudKitClient()
  const [trialEndDate, setTrialEndDate] = useState<string | undefined>(
    undefined
  )
  const [dialogOpen, setDialogOpen] = useState(false)
  const [isSubscribed, setIsSubscribed] = useState(false)
  const [isGuest, setIsGuest] = useState(false)
  const [isTrialRunning, setIsTrialRunning] = useState<string | null>(null)
  const { data: revenueCatUserId } = useFetchUserDefault(
    'revenuecat_id',
    'web-app-subscription-rc-id'
  )
  const [stripeCustomerId, setStripeCustomerId] = useState<string | null>(null)
  const [stripeSubscriptionId, setStripeSubscriptionId] = useState<
    string | null
  >(null)
  const [mixpanelAcctCreatedEvnt, setMixpanelAcctCreatedEvnt] = useState<
    boolean | undefined
  >(false)

  async function manageTrial(
    currentUser: User,
    currentRevenueCatUserId: string
  ) {
    try {
      setIsTrialLoading(true)
      const response = await stripeRequest<object>('/trial.php', {
        user: currentUser,
        revenueCatUserId: currentRevenueCatUserId,
      })
      return response
    } catch (requestError) {
      setError(String(requestError))
      return { error: String(requestError) }
    } finally {
      setIsTrialLoading(false)
    }
  }

  async function manageSupabaseTrial(userId: string) {
    try {
      // setIsTrialLoading(true)
      const response = await stripeRequest<object>('/supabase-trial.php', {
        user_id: userId,
      })

      setIsTrialLoading(false)
      return response
    } catch (requestError) {
      setError(String(requestError))
      return { error: String(requestError) }
    } finally {
      setIsTrialLoading(false)
    }
  }

  function trackTrialBannerClicked() {
    trackEvent('WEB - Trial Banner Clicked')
  }

  useEffect(() => {
    const fetchData = async () => {
      if (!user) return

      if (user.cloudKitUserId) {
        if (revenueCatUserId === undefined) return

        // Check through RevenueCat, if the subscription exists,
        console.log('REVENUECAT ID', revenueCatUserId)
        const trialResponse = await manageTrial(user, revenueCatUserId)
        console.log(
          '[useSubscriptionVerification] CloudKit user response',
          trialResponse
        )

        if (!revenueCatUserId && trialResponse.revenuecat_id) {
          try {
            await cloudKitClient.setUserDefault(
              'web-app-subscription-rc-id',
              trialResponse.revenuecat_id
            )
          } catch (requestError) {
            // console.error('Failed to save revenuecat_id:', requestError)
            // TODO: implement error reporting and toast
          }
        }
        if (!mixpanelAcctCreatedEvnt && trialResponse.trial_start_date) {
          const trialStartDate = new Date(trialResponse.trial_start_date)
          const today = new Date()
          trialStartDate.setHours(0, 0, 0, 0), today.setHours(0, 0, 0, 0)
          const isToday = trialStartDate.getTime() === today.getTime()
          if (isToday) trackEvent('WEB - Account Created')
          setMixpanelAcctCreatedEvnt(true)
          // console.log('[batch]', isToday, trialStartDate)
        }

        setIsSubscribed(trialResponse.subscribed ?? false)

        if (!trialResponse.subscribed) {
          setTrialEndDate(trialResponse.trial_end_date)
          setIsTrialRunning(trialResponse.trial?.toString() ?? null)
        }
      } else if (user.supabaseUserId) {
        // handle subscription
        const res = await manageSupabaseTrial(user.supabaseUserId)
        // console.log('[useSubscriptionVerification] Supabase user response', res)

        setIsSubscribed(res.subscription_status ?? false)
        setStripeCustomerId(res.stripeCustomerId ?? null)
        setStripeSubscriptionId(res.stripeSubscriptionId ?? null)

        if (!mixpanelAcctCreatedEvnt && res.trial_start_date) {
          const trialStartDate = new Date(res.trial_start_date)
          const today = new Date()
          trialStartDate.setHours(0, 0, 0, 0), today.setHours(0, 0, 0, 0)
          const isToday = trialStartDate.getTime() === today.getTime()
          if (isToday) trackEvent('WEB - Account Created')
          setMixpanelAcctCreatedEvnt(true)
          // console.log('[batch]', isToday, trialStartDate)
        }

        if (res.subscription_status === false) {
          setTrialEndDate(res.trial_end_date)
          setIsTrialRunning(res.trial ?? null)
        }

        if (!res.trial || res.trial === 'expired') {
          setIsGuest(res.is_guest ?? false)
        } else {
          setIsGuest(false)
        }
      }

      if (user.supabaseUserId ?? user.cloudKitUserId) {
        identify(user.cloudKitUserId ?? user.supabaseUserId)
        peopleSet({
          supabaseUserId: user.supabaseUserId ?? '',
          cloudKitUserId: user.cloudKitUserId ?? '',
          revenueCatUserId: revenueCatUserId ?? '',
          stripeCustomerId: stripeCustomerId ?? '',
          stripeSubscriptionId: stripeSubscriptionId ?? '',
          email: user.email,
          source: 'web',
          subscribed: isSubscribed,
          trial: isTrialRunning,
          isGuest: isGuest,
        })
      }
      // setOpen(true)
    }

    void fetchData()
  }, [
    user,
    revenueCatUserId,
    mixpanelAcctCreatedEvnt,
    cloudKitClient,
    stripeCustomerId,
    stripeSubscriptionId,
    isSubscribed,
    isTrialRunning,
    isGuest,
  ])

  return {
    error,
    setError,
    isTrialLoading,
    manageTrial,
    setDialogOpen,
    dialogOpen,
    trialEndDate,
    isSubscribed,
    isGuest,
    isTrialRunning,
    stripeCustomerId,
    stripeSubscriptionId,
    trackTrialBannerClicked,
  }
}
