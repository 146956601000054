import { GoogleOAuthProvider } from '@react-oauth/google'
import { type Dayjs } from 'dayjs'
import { GapiProvider } from '../../providers/GapiProvider'
import {
  useSelectedDate,
  useSelectedDateDispatch,
} from '../../providers/SelectedDateProvider'
import { useIsMobile } from '../../hooks/useIsMobile'
import { Timeline } from './timeline/Timeline'
import { Calendar } from './Calendar'
import CalendarMobile from './CalendarMobile'

const gapiConfig = {
  apiKey: process.env.API_GOOGLE_APIKEY ?? '',
  discoveryDocs: [
    'https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest',
  ],
}

export function CalendarWrapper({
  showCalendar,
  toggleCalendar,
}: {
  showCalendar: boolean
  toggleCalendar: () => void
}) {
  const isMobile = useIsMobile()
  const selectedDate = useSelectedDate()
  const selectedDateDispatch = useSelectedDateDispatch()

  // const [timelineDays, setTimelineDays] = useLocalStorage('timelineDays', 1);
  const timelineDays = 1

  function setToday() {
    selectedDateDispatch({ type: 'today', forceDay: true })
  }

  function setWeek({ week, year }: { week: number; year: number }) {
    selectedDateDispatch({ type: 'setWeek', week, year })
  }

  function setDay(date: Dayjs) {
    selectedDateDispatch({ type: 'setDay', date })
  }

  return (
    <div className='calendar-body'>
      <GoogleOAuthProvider clientId={process.env.API_GOOGLE_CLIENTID ?? ''}>
        <GapiProvider gapiConfig={gapiConfig}>
          {isMobile ? (
            showCalendar && (
              <CalendarMobile
                selectedDate={selectedDate}
                onToday={setToday}
                onChangeWeek={setWeek}
                onChangeDay={setDay}
              />
            )
          ) : (
            <div className='sticky top-0 flex h-screen max-h-screen select-none items-center justify-center'>
              <button
                type='button'
                onClick={toggleCalendar}
                className='-ml-6 bg-transparent py-4 opacity-25 transition hover:bg-gray-200 hover:opacity-100 dark:hover:bg-gray-700'
              >
                <i
                  className={`fa-solid fa-chevron-${showCalendar ? 'right' : 'left'}`}
                />
              </button>
              {showCalendar ? (
                <div className='h-screen border-l-2'>
                  <div className='hidden flex-col items-center space-y-2 md:flex'>
                    <Calendar
                      selectedDate={selectedDate}
                      onToday={setToday}
                      onChangeWeek={setWeek}
                      onChangeDay={setDay}
                      timelineDays={timelineDays}
                      // onSetTimelineDays={setTimelineDays}
                    />
                    {/* When we have a week view that is a list instead of a timeline, uncomment and implement this, for now it just shifts things up and down */}
                    {/* {selectedDate.active === 'day' && ( */}
                    <Timeline timelineDays={timelineDays} />
                    {/* )} */}
                  </div>
                </div>
              ) : undefined}
            </div>
          )}
        </GapiProvider>
      </GoogleOAuthProvider>
    </div>
  )
}
