import useNote from '../../hooks/useNote'
import { SelectedDate, selectedDateToDay, useSelectedDate } from '../../providers/SelectedDateProvider'
import { useSelectedRecordName } from '../../providers/SelectedRecordNameProvider'
import { useSidebarProvider } from '../../providers/SidebarProvider'
import { NoteType, isCalendarNote, isTeamspaceNote } from '../../utils/syncUtils'
import { findSidebarEntry } from '../sidebar/MainSidebar'
import { SidebarEntry } from '../sidebar/SidebarBuilder'

function parsePageTitle(
  selectedDate: SelectedDate,
  isLoading: boolean,
  isError: boolean,
  noteTitle = '',
  noteType = NoteType.CALENDAR_NOTE,
  filename: string | null,
  sidebarEntries: SidebarEntry[],
  recordName: string,
  error?: unknown
): { title: string; folder?: string; detail?: string } {
  const date = selectedDateToDay(selectedDate)
  let title = 'NotePlan for Web'
  let detail = ''
  let folder: string | null = null

  if (isLoading) {
    return { title: 'Loading ...', folder: noteType === NoteType.PROJECT_NOTE ? 'Loading ...' : null }
  }

  if (isError) {
    const message: string = typeof error == 'object' && 'message' in error ? (error.message as string) : error?.toString()
    return { title: message || 'Error' }
  }

  if (noteTitle && noteTitle.length > 0) {
    title = noteTitle
  }

  if (isCalendarNote(noteType)) {
    if (selectedDate.active === 'week') {
      title = 'Week ' + selectedDate.week + ', ' + selectedDate.year

      // The detail is the start date to end date of this week like "Sep 18 - Sep 24"
      const startOfWeek = date.startOf('week')
      const endOfWeek = date.endOf('week')
      detail = startOfWeek.format('MMM D') + ' - ' + endOfWeek.format('MMM D')
    } else {
      const format = 'ddd, D MMM'
      title = date.calendar(null, {
        lastDay: `[Yesterday], ${format}`,
        sameDay: `[Today], ${format}`,
        nextDay: `[Tomorrow], ${format}`,
        nextWeek: format,
        lastWeek: format,
        sameElse: format,
      })
    }
  } else {
    const pathComponents = filename?.split('/')
    if (pathComponents && pathComponents.length > 1) {
      // Case CloudKit
      // Take all components except the last
      folder = pathComponents.slice(0, -1).join('/')
    } else {
      // Case Supabase
      folder = breadcrumbPath(sidebarEntries, recordName)
    }

    if (folder.length == 0) {
      folder = 'Notes'
    }
  }

  return { title, folder, detail }
}

// traverse the sidebar entries to compile the breadcrumb path
function breadcrumbPath(sidebarEntries: SidebarEntry[], recordName: string): string {
  const path: string[] = []
  const { parent } = findSidebarEntry(sidebarEntries, recordName)
  if (parent && !['notes', 'teamspaces'].includes(parent.recordName)) {
    path.push(breadcrumbPath(sidebarEntries, parent.recordName))
    path.push(parent.title)
  }
  return path.filter((p) => p.length > 0).join('/')
}

type Props = {
  onToday: (_isWeek: boolean) => void
}

export const NoteTitle = ({ onToday }: Props) => {
  const selectedDate = useSelectedDate()
  const selectedRecordName = useSelectedRecordName()
  const { noteKey, sidebarEntries, handleRevealNote } = useSidebarProvider()
  const { isLoading, isError, error, data: note } = useNote(noteKey)

  const { title, folder, detail } = parsePageTitle(
    selectedDate,
    isLoading,
    isError,
    note?.title,
    note?.noteType ?? NoteType.CALENDAR_NOTE,
    note?.filename,
    sidebarEntries,
    selectedRecordName,
    error
  )

  const isWeek = selectedDate?.active === 'week' && folder === null

  let icon = 'fa-light fa-calendar-star'
  if (isWeek) {
    icon = 'fa-light fa-calendar-week'
  }
  if (folder) {
    icon = 'fa-light fa-folder'
  }
  if (isTeamspaceNote(note?.noteType) && !isCalendarNote(note?.noteType)) {
    icon = 'far fa-screen-users text-green-500'
  }

  return (
    <button
      className={'note-title p-0' + (isWeek ? ' isWeek' : '') + (folder ? ' text-blue-500' : ' text-orange-500')}
      type="button"
      data-tooltip-id="my-tooltip"
      data-tooltip-content={folder ? 'Show note in sidebar' : 'Open today (⌃T)'}
      onClick={() => (folder ? handleRevealNote(selectedRecordName) : onToday(false))}
    >
      <i className={icon + ' mr-1.5'}></i>
      <h1 className="text-lg font-bold">{folder ?? title}</h1>
      <h2 className={'text-xs ml-2 text-gray-400 mb-0 align-bottom' + (detail ?? ' hidden')}>{detail}</h2>
    </button>
  )
}
