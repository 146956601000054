import { QueryClient } from '@tanstack/react-query'
import notificationTracker from './notificationTracker'
import { SupabaseNote } from './types'
import { fetchNoteById } from './fetch'
import { updateNote } from '../../providers/CachedNotesProvider'
import { RealtimePostgresChangesPayload } from '@supabase/supabase-js'
import {
  cacheKeys,
  noteQueryKey,
  privateKeys,
  teamKeys,
} from '../../utils/queryKeyFactory'
import { Note } from '../../utils/syncUtils'
import { supabase } from '../SupabaseClient'
import {
  deleteReferenceCache,
  updateReferencesCache,
} from '../../hooks/useNoteReferences'
import { mapDelete } from '../../utils/mapAsState'

function isSupabaseNote(
  data: object | Partial<SupabaseNote>
): data is SupabaseNote {
  return (data as Partial<SupabaseNote>).id !== undefined
}

export async function registerForNotifications(
  currentUserId: string,
  queryClient: QueryClient,
  cachedNotesQueryClient: QueryClient
) {
  // Skip notifications if we just saved the note for example when updating the titles
  async function handleChange(
    payload: RealtimePostgresChangesPayload<SupabaseNote>
  ) {
    if (isSupabaseNote(payload.new) || isSupabaseNote(payload.old)) {
      const id = isSupabaseNote(payload.new)
        ? payload.new.change_tag
        : isSupabaseNote(payload.old)
          ? payload.old.id
          : undefined
      if (notificationTracker.hasTag(id)) {
        // Remove this recordName from the skip list, so we don't ignore it again
        notificationTracker.removeTag(id)
        return
      }
    }

    // eslint-disable-next-line no-console
    console.debug('note change', payload)
    // update notes
    const updatedNote =
      (payload.eventType === 'INSERT' || payload.eventType === 'UPDATE') &&
      (await fetchNoteById(currentUserId, payload.new.id))
    switch (payload.eventType) {
      case 'INSERT':
        if (updatedNote) {
          updateNote(
            cachedNotesQueryClient,
            currentUserId,
            currentUserId,
            updatedNote
          )
          queryClient.setQueryData(noteQueryKey(updatedNote), () => updatedNote)
        }
        break
      case 'UPDATE':
        if (updatedNote) {
          updateNote(
            cachedNotesQueryClient,
            currentUserId,
            currentUserId,
            updatedNote
          )
          queryClient.setQueryData(noteQueryKey(updatedNote), () => updatedNote)
          updateReferencesCache(
            queryClient,
            cachedNotesQueryClient,
            updatedNote,
            {
              supabaseUserId: currentUserId,
            }
          )
        }
        break
      case 'DELETE': {
        // Because of delete cascade, we don't need to remove the children
        // Try to remove the note from the team notes first to prevent removing the wrong note when moving a note from team to private
        const teamProjectNotes = cachedNotesQueryClient.getQueryData<
          Map<string, Note>
        >(cacheKeys.teamProjectNotes(currentUserId))
        const teamCalendarNotes = cachedNotesQueryClient.getQueryData<
          Map<string, Note>
        >(cacheKeys.teamCalendarNotes(currentUserId))
        if (
          (teamProjectNotes?.has(payload.old.id) ?? false) ||
          (teamCalendarNotes?.has(payload.old.id) ?? false)
        ) {
          if (teamProjectNotes?.has(payload.old.id) ?? false) {
            cachedNotesQueryClient.setQueryData<Map<string, Note>>(
              cacheKeys.teamProjectNotes(currentUserId),
              (oldData: Map<string, Note>) => {
                return mapDelete(oldData, payload.old.id)
              }
            )
            // teamProjectNotes.delete(payload.old.id)
            // cachedNotesQueryClient.setQueryData(
            //   cacheKeys.teamProjectNotes(currentUserId),
            //   teamProjectNotes
            // )
          }

          if (teamCalendarNotes?.has(payload.old.id) ?? false) {
            cachedNotesQueryClient.setQueryData<Map<string, Note>>(
              cacheKeys.teamCalendarNotes(currentUserId),
              (oldData: Map<string, Note>) => {
                return mapDelete(oldData, payload.old.id)
              }
            )

            // teamCalendarNotes.delete(payload.old.id)
            // cachedNotesQueryClient.setQueryData(
            //   cacheKeys.teamCalendarNotes(currentUserId),
            //   teamCalendarNotes
            // )
          }
          queryClient
            .getQueriesData(teamKeys.all)
            .forEach(([queryKey, note]) => {
              if (payload.old.id === note?.['recordName']) {
                queryClient.removeQueries(queryKey)
                return false
              }
            })
        } else {
          cachedNotesQueryClient.setQueryData<Map<string, Note>>(
            cacheKeys.privateProjectNotes(currentUserId),
            (oldData: Map<string, Note>) => {
              return mapDelete(oldData, payload.old.id)
            }
          )
          cachedNotesQueryClient.setQueryData<Map<string, Note>>(
            cacheKeys.privateCalendarNotes(currentUserId),
            (oldData: Map<string, Note>) => {
              return mapDelete(oldData, payload.old.id)
            }
          )
          queryClient
            .getQueriesData<Note>(privateKeys.notes)
            .forEach(([queryKey, note]) => {
              if (payload.old.id === note?.recordName) {
                queryClient.removeQueries(queryKey)
                return false
              }
            })
        }

        deleteReferenceCache(queryClient, payload.old.id, {
          supabaseUserId: currentUserId,
        })
        break
      }
    }
  }

  if (
    !supabase.realtime.isConnected() ||
    supabase.realtime.channels.length === 0 ||
    supabase.realtime.channels[0].state !== 'joined'
  ) {
    supabase
      .channel('any')
      .on<SupabaseNote>(
        'postgres_changes',
        { event: '*', schema: 'public', table: 'notes' },
        handleChange
      )
      .subscribe()
  } else {
    // eslint-disable-next-line no-console
    console.log(
      "Didn't connect to realtime updates because we are already connected",
      supabase.realtime.channels
    )
  }
}
