import { type Dayjs } from 'dayjs'
import { type CalendarEvent } from '../../CalendarEvent'
import { type PlaceholderEvent } from '../types'
import { calculateOffsetAndHeight } from '../utils/event'
import { EventContent } from './EventContent'

type SingleEventProps = {
  event: CalendarEvent | PlaceholderEvent
  segmentHeight: number
  setCalendarEventDrag: (event: CalendarEvent | PlaceholderEvent) => void
  selectedDay: Dayjs
}

export function SingleEvent({
  event,
  segmentHeight,
  setCalendarEventDrag,
  selectedDay,
}: SingleEventProps) {
  const { eventHeight, offsetPxTop } = calculateOffsetAndHeight(
    event,
    segmentHeight,
    selectedDay
  )

  return (
    <li
      key={`event-${'id' in event ? event.id : 'placeholder'}`}
      id={`event-${'id' in event ? event.id : 'placeholder'}`}
      className='col-start-1 mt-px flex'
      style={{
        gridRow: event.position,
        transform: `translateY(${offsetPxTop.toString()}px)`,
        height: eventHeight - 2,
      }}
    >
      <EventContent
        setCalendarEventDrag={setCalendarEventDrag}
        event={event}
        eventHeight={eventHeight}
        segmentHeight={segmentHeight}
        selectedDay={selectedDay}
        className='h-full w-full'
      />
    </li>
  )
}
