export function mapSet<K, V>(map: Map<K, V>, key: K, value: V) {
  if (!map) {
    map = new Map<K, V>()
  }

  if (!(map instanceof Map)) {
    throw new Error('The provided map is not an instance of Map', map)
  }

  const copy = new Map(map)
  copy.set(key, value)
  return copy
}

export function mapDelete<K, V>(map: Map<K, V>, key: K) {
  return mapDeletes(map, [key])
}

export function mapDeletes<K, V>(map: Map<K, V>, keys: K[]) {
  if (!map) {
    map = new Map<K, V>()
  }

  if (!(map instanceof Map)) {
    throw new Error('The provided map is not an instance of Map', map)
  }

  const copy = new Map(map)
  for (const key of keys) {
    copy.delete(key)
  }
  return copy
}
