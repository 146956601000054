import { isFilenameImage } from '@packages/blocknote-core'
import dayjs, { Dayjs } from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'

dayjs.extend(advancedFormat)

export type Attachment = {
  title: string
  filename: string
  url?: string
  file?: File
}

export type Note = {
  noteType: NoteType
  filename: string
  title?: string
  content?: string
  recordName?: string
  recordChangeTag?: string
  fileModifiedAt?: Date
  attachments?: string
  encrypted?: boolean
  uploadedAttachments?: Attachment[]
  parent?: string
  isFolder?: boolean
  isShared?: boolean
  children?: Note[]
  source?: SourceDatabase
  tags?: string[]
  owner?: string
  admins?: string[]
  isEmpty?: boolean
}

export type Change = {
  content: string
  attachments: string[]
  recordName: string
  filename: string
  parent: string | null
  noteType: NoteType
  modificationDate?: Date
  forceCreate?: boolean
}

/* eslint-disable no-unused-vars */
export enum NoteType {
  CALENDAR_NOTE = 0,
  PROJECT_NOTE = 1,
  ASSET_PROJECT_NOTE = 5, // This is a project note which content is saved as an asset (=attachement) on CloudKit in order to encrypt it / or because it's more than 1MB
  ASSET_CALENDAR_NOTE = 7, // This is a calendar note which content is saved as an asset (=attachement) on CloudKit in order to encrypt it / or because it's more than 1MB
  TEAM_SPACE = 10,
  TEAM_SPACE_NOTE = 11,
  TEAM_SPACE_CALENDAR_NOTE = 12,
}

export function isCalendarNote(noteType: NoteType): boolean {
  return [
    NoteType.CALENDAR_NOTE,
    NoteType.TEAM_SPACE_CALENDAR_NOTE,
    NoteType.ASSET_CALENDAR_NOTE,
  ].includes(noteType)
}

export function isPrivateNote(noteType: NoteType): boolean {
  return [
    NoteType.CALENDAR_NOTE,
    NoteType.ASSET_CALENDAR_NOTE,
    NoteType.PROJECT_NOTE,
    NoteType.ASSET_PROJECT_NOTE,
  ].includes(noteType)
}

export function isTeamspaceNote(noteType: NoteType): boolean {
  return [
    NoteType.TEAM_SPACE_NOTE,
    NoteType.TEAM_SPACE_CALENDAR_NOTE,
    NoteType.TEAM_SPACE,
  ].includes(noteType)
}

export function isFolder(note: Note) {
  return note.isFolder || note.noteType === NoteType.TEAM_SPACE
}

export enum SourceDatabase {
  SUPABASE = 0,
  CLOUDKIT = 1,
}

// This converts the blob url of a file attachment into a blob and the blob into File object, which has the data, checksum, etc. will be accepted by CloudKit
export const convertAttachmentToFiles = async (
  attachments: string[] | undefined
): Promise<Attachment[] | undefined> => {
  if (!attachments) {
    return []
  }

  let didFail = false

  // Convert each attachment into a data buffer
  const imageBuffers: Attachment[] = []
  const fileBuffers: Attachment[] = []

  // Create an array of promises to process all attachments
  const attachmentPromises = attachments.map(async (attachment, index) => {
    const asset = JSON.parse(attachment)
    const isImage = isFilenameImage(asset.filename, '')

    if (asset.url === undefined) {
      didFail = true
      return { index, attachment: null }
    }

    const file = await blobURLToFile(asset.url, asset.filename)

    if (!file) {
      didFail = true
      return { index, attachment: null }
    }

    return {
      index,
      attachment: {
        title: asset.title,
        filename: asset.filename,
        file: file,
        isImage: isImage,
      },
    }
  })

  // Wait for all promises to resolve
  const processedAttachments = await Promise.all(attachmentPromises)

  if (didFail) {
    return undefined
  }

  // Sort attachments back to their original order and separate into image and file buffers
  processedAttachments
    .sort((a, b) => a.index - b.index)
    .forEach(({ attachment }) => {
      if (attachment) {
        if (attachment.isImage) {
          imageBuffers.push(attachment)
        } else {
          fileBuffers.push(attachment)
        }
      }
    })

  // We need to sort the images up and the files down (legacy requirement)
  return [...imageBuffers, ...fileBuffers]
}

export const blobURLToFile = async (
  blobURL: string,
  fileName: string
): Promise<File | undefined> => {
  if (!blobURL) {
    return undefined
  }

  try {
    // Fetch the blob from the URL
    const response = await fetch(blobURL)
    if (!response.ok) {
      throw new Error(`Failed to fetch blob. Status: ${response.status}`)
    }
    const blob = await response.blob()

    // Convert the blob to a File object
    return new File([blob], fileName, { type: blob.type })
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error converting blob URL to file:', error)
    return undefined
  }
}

export const fileAttachmentFromString = (stringData: string): File => {
  const blob = new Blob([stringData], { type: 'text/plain' })
  return new File([blob], 'note-file.txt', { type: blob.type })
}

export const findMatches = (
  note: Note,
  patterns: {
    regex: RegExp
    group: number
    cleanRegex: RegExp | null
    replace: string
  }[]
): { match: string; line: string; range: { from: number; to: number } }[] => {
  if (note.filename.startsWith('@') || (note.content ?? '').length == 0) {
    return []
  }

  const results: {
    match: string
    line: string
    range: { from: number; to: number }
  }[] = []
  const contentLines = note.content.split('\n')
  // Regex pattern to match hashtags and mentions, capturing only the relevant group
  const codeFencePattern = /^```/
  const inlineCodePattern = /`([^`]+)`/g
  const markdownLinkPattern = /\[.*?\]\((.*?)\)/g
  let insideCodeFence = false

  let offset = 0
  contentLines.forEach((line) => {
    if (codeFencePattern.test(line)) {
      insideCodeFence = !insideCodeFence
      return
    }
    if (insideCodeFence) {
      return
    }
    // Remove inline code segments and markdown links before matching tags
    const lineWithoutCodeAndLinks = line
      .replace(inlineCodePattern, '')
      .replace(markdownLinkPattern, '')

    // Store only unique matches for a single line
    const uniqueMatches = new Set<string>()

    patterns.forEach(({ regex, group, cleanRegex, replace }) => {
      let match: RegExpExecArray | null
      while ((match = regex.exec(lineWithoutCodeAndLinks)) !== null) {
        // Extract only the relevant group for the tag
        if (match && match[group]) {
          // Clean up rounded squares in mentions
          let sanitizedMatch = match[group]
          if (cleanRegex) {
            sanitizedMatch = sanitizedMatch.replace(cleanRegex, replace)
          }

          uniqueMatches.add(sanitizedMatch)
        }
      }
    })

    const from = offset
    const to = from + line.length + 4
    for (const match of uniqueMatches) {
      results.push({ match: match, line: line, range: { from: from, to: to } })
    }

    offset += line.length + 4
  })

  // Remove duplicates and exclude specific mentions
  return results
}

export const readTagsFromContent = (note: Note): string[] => {
  const tagPattern =
    /(?:^|\s|["'([{])(?:(?![@#][\d\p{P}\p{S}\p{Pc}]+($|\s))([@#]([^\p{P}\p{S}\p{Pc}\s]|[-_/])+?\(.*?\)|[@#]([^\p{P}\p{S}\p{Pc}\s]|[-_/])+))/gu
  const hashTagsAndMentions: string[] = findMatches(note, [
    { regex: tagPattern, group: 2, cleanRegex: /\(.*?\)/, replace: '' },
  ]).map((match) => match.match)

  // Remove duplicates and exclude specific mentions
  const excludeMentions = new Set(['@repeat', '@done', '@final-repeat'])
  return [...new Set(hashTagsAndMentions)].filter(
    (tag) => !excludeMentions.has(tag)
  )
}

export const calendarNoteTitleFrom = (filename: string): string => {
  return filename.split('.').slice(0, -1).join('').replaceAll('-', '')
}

export function filenameToKey(filename: string): string {
  const { date, timeframe } = filenameToDate(filename)
  if (timeframe === 'day') {
    return date.format('YYYY-MM-DD')
  } else if (timeframe === 'week') {
    return date.format('YYYY-[W]ww')
  } else if (timeframe === 'month') {
    return date.format('YYYY-MM')
  } else if (timeframe === 'quarter') {
    return date.format('YYYY-[Q]Q')
  } else if (timeframe === 'year') {
    return date.format('YYYY')
  }
}

export const readNoteTitleFromContent = (
  note: Note,
  escapeSpecialFileChars = true
): string | undefined => {
  if (isCalendarNote(note.noteType)) {
    return calendarNoteTitleFrom(note.filename)
  }

  if (!note.content || note.content.trim().length === 0) {
    return undefined
  }

  const frontmatter = readNoteTitleFromFrontmatter(note)
  if (frontmatter.title) {
    return frontmatter.title
  }

  // Find the first non-empty line (ignoring whitespace) and remove heading markdown (#'s at the beginngin)
  const lines = note.content?.split('\n')
  const startIndex =
    (frontmatter.bodyStartLineIndex ?? 0) < lines.length
      ? frontmatter.bodyStartLineIndex ?? 0
      : 0
  if (lines) {
    for (let i = startIndex; i < lines.length; i++) {
      const line = lines[i].trim()
      if (line.length > 0) {
        let title = line.replace(/^#+\s*/, '')

        // Remove special characters that are not allowed in filenames such as /, \, :, *, ?, ", <, >, |, and the null character.
        if (escapeSpecialFileChars) {
          title = title.replace(/[/\\:*?"<>|]/g, '')
        }

        // Never return a 0 length title, this gets us into trouble with Supabase encryption
        if (title.trim().length === 0) {
          return undefined
        }

        return title
      }
    }
  }

  return undefined
}

export const readNoteTitleFromFrontmatter = (
  note: Note
): { title?: string; bodyStartLineIndex?: number } => {
  const result: { title?: string; bodyStartLineIndex?: number } = {}

  if (isCalendarNote(note.noteType)) {
    result.title = calendarNoteTitleFrom(note.filename)
    return result
  }

  if (!note.content || note.content.trim().length === 0) {
    return result
  }

  const frontmatterMatch = note.content.match(/^\s*---\n([\s\S]*?)\n---/)
  let bodyStartLineIndex = 0
  if (frontmatterMatch) {
    const frontmatter = frontmatterMatch[1]
    const frontmatterLines = frontmatter.split('\n')
    bodyStartLineIndex = frontmatterLines.length + 2 // Adding 2 for the starting and ending lines of the frontmatter block

    const frontmatterProperties = frontmatterLines.reduce((acc, line) => {
      const [key, value] = line.split(':').map((part) => part.trim())
      if (key && value) {
        acc[key.toLowerCase()] = value
      }
      return acc
    }, {})

    const title =
      frontmatterProperties['title'] || frontmatterProperties['name']
    if (title) {
      result.title = title
    }
  } else {
    // If there's no frontmatter, the body starts at the first line
    bodyStartLineIndex = 0
  }

  result.bodyStartLineIndex = bodyStartLineIndex
  return result
}

export const getFilenameFromNoteTitle = (note: Note): string => {
  // Check if it's a full path and not just a filename (as it's usual with CloudKit)
  if (!isCalendarNote(note.noteType)) {
    const title = readNoteTitleFromContent(note)
    const path = note.filename.split('/')

    if ((title && note.source === SourceDatabase.CLOUDKIT) || path.length > 1) {
      // Set the last path component to the title

      // Remove any trailing slashes
      while (path[path.length - 1] === '') {
        path.pop()
      }

      // Get the extension of the last item
      const lastItem = path[path.length - 1]
      const extension = lastItem.split('.').pop()

      // Remove the last path component
      path.pop()

      // Add the title
      path.push(title + '.' + extension)

      const newFilename = path.join('/')
      if (newFilename.trim().length === 0) {
        return note.filename
      }

      return newFilename
    } else if (title) {
      return title
    }
  } else {
    return note.filename
  }

  return note.filename
}

export const readMetaField = (field: string | undefined): unknown | null => {
  if (!field) {
    return undefined
  }

  try {
    return JSON.parse(field)
  } catch (e) {
    // Nothing to see here
  }

  return null
}
export function getSupabaseFileExtension(): string {
  // By default use "md" with supabase, here we don't need to query it, because we won't save them as files and can pick a fixed default
  return 'md'
}

export type Timeframe = 'day' | 'week' | 'month' | 'quarter' | 'year'

export function filenameToDate(filename: string): {
  date: Dayjs | null
  timeframe: Timeframe | null
} {
  const datePattern =
    /^(?<year>\d{4})(?:(?<month>\d{2})(?:(?<day>\d{2}))?|-W(?<week>\d{2})?|-(?<monthOnly>\d{2})|-(Q(?<quarter>[1-4])))?\.\w{2,4}$/
  const match = filename.match(datePattern)

  if (match && match.groups) {
    const { year, month, week, day, monthOnly, quarter } = match.groups
    if (week) {
      const date = dayjs()
        .year(parseInt(year, 10))
        .week(parseInt(week, 10))
        .startOf('week')
      return { date, timeframe: 'week' }
    } else if (quarter) {
      const quarterMonth = (parseInt(quarter, 10) - 1) * 3
      const date = dayjs()
        .year(parseInt(year, 10))
        .month(quarterMonth)
        .startOf('month')
      return { date, timeframe: 'quarter' }
    } else if (day && month) {
      const date = dayjs(`${year}-${month}-${day}`)
      return { date, timeframe: 'day' }
    } else if (month || monthOnly) {
      const monthToUse = month || monthOnly
      const date = dayjs(`${year}-${monthToUse}-01`)
      return { date, timeframe: 'month' }
    } else {
      const date = dayjs().year(parseInt(year, 10)).startOf('year')
      return { date, timeframe: 'year' }
    }
  }

  return { date: null, timeframe: null }
}

export function calendarFilenameToTitle(
  filename: string,
  monthFirst = false
): string {
  const { date, timeframe } = filenameToDate(filename)
  if (timeframe === 'day') {
    return date.format(monthFirst ? 'MMMM D, YYYY' : 'ddd, D MMM')
  } else if (timeframe === 'week') {
    return 'Week ' + date.week()
  } else if (timeframe === 'month') {
    return date.format('MMMM YYYY')
  } else if (timeframe === 'quarter') {
    return 'Q' + date.quarter() + ' ' + date.year()
  } else if (timeframe === 'year') {
    return date.format('YYYY')
  }
}

export function toggleTodoStateInLine(line: string): string {
  if (!line) return line
  const todoPattern = /^\s*(\*|\+)( \[[^\]]*\])?( .*)$/
  const match = line.match(todoPattern)

  if (match) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [fullMatch, bullet, checkbox, task] = match
    if (checkbox && checkbox.trim() === '[x]') {
      // If there's a checkbox with [x], remove it
      return `${bullet}${task}`
    } else {
      // If there's a checkbox with anything other than [x], replace it with [x]
      return `${bullet} [x]${task}`
    }
  }

  return line
}
