// import { DataStore } from '../data-utils/DataStore';
// import { serialize } from '../modules/editor/TipTapMarkdown';

export function pluralize(word: string, count: number) {
  return count === 1 ? word : `${word}s`
}

export async function copyMarkdown() {
  // TODO
  // const markdown = serialize(window.editor.schema, window.editor.getJSON());
  // const mdBlob = new Blob([markdown], { type: 'text/plain' });
  // const htmlBlob = new Blob([window.editor.getHTML()], { type: 'text/html' });
  // await navigator.clipboard.write([new ClipboardItem({ 'text/plain': mdBlob, 'text/html': htmlBlob })]);
  // const check = document.getElementById('copy-done-check');
  // if (!check) {
  //   return;
  // }
  // check.className = '';
  // setInterval(function () {
  //   check.className = 'hidden';
  // }, 800);
}

// export async function copyNode(node, editor, mdToHTML) {
//   const nodeJSON = node.toJSON();

//   let markdown = '';
//   if (!node.type) {
//     markdown = serialize(editor.schema, { content: nodeJSON.content, type: 'doc' });
//   } else {
//     markdown = serialize(editor.schema, nodeJSON);
//   }

//   const mdBlob = new Blob([markdown], { type: 'text/plain' });
//   const htmlBlob = new Blob([mdToHTML.render(markdown)], { type: 'text/html' });
//   await navigator.clipboard.write([new ClipboardItem({ 'text/plain': mdBlob, 'text/html': htmlBlob })]);
// }

// export const buildParagraph = (content: string | undefined | object) => ({
//   type: 'paragraph',
//   content:
//     typeof content == 'string' && content.length > 0
//       ? [
//           {
//             type: 'text',
//             text: content,
//           },
//         ]
//       : content
//       ? [content]
//       : [],
// });

// export const buildList = (type: string, content: string | undefined | object, attrs = {}) => ({
//   type,
//   attrs,
//   content: [
//     {
//       type: type == 'taskList' ? 'taskItem' : 'listItem',
//       content: [buildParagraph(content)],
//     },
//   ],
// });

// export const listPasteHandler = ({ match, chain, range, state, type, listBuilder, level = 0 }) => {
//   const inList = state.tr.doc.resolve(range.from - 2)?.node(1)?.type.name == type;

//   let dataToInsert: Array<object> | object = listBuilder();
//   if (!inList) {
//     dataToInsert = [{ type: 'hardBreak' }, dataToInsert];
//   }

//   chain().deleteRange(range).insertContentAt(range.from, dataToInsert);

//   if (inList) {
//     state.tr.join(range.from - 1);
//   }

//   if (level > 0) {
//     window.debug('List item level', level);
//     // sink list item somehow
//   }
// };

// export async function changeToSelectedDay(day, setSelectedDay, currentNote, setCurrentNote, setIsLoading) {
//   setIsLoading(true);
//   await window.editor.saveNoteIfNeeded(currentNote);

//   setSelectedDay(day);
//   window.selectedDate = day;
//   const query = {
//     recordType: 'Note',
//     filterBy: [
//       {
//         fieldName: 'filename',
//         comparator: 'BEGINS_WITH',
//         fieldValue: {
//           value: DataStore.getNoteFileName(day),
//         },
//       },
//     ],
//   };

//   window.currentNote = { filename: null, noteType: null, record: null };
//   // const note = await CloudKitSync.shared.fetchNote(query, null);
//   setCurrentNote(note);
//   setIsLoading(false);

//   // TODO: might not be needed
//   // Fix window jumping to random position because of different note lengths
//   // wait for the DOM to update
//   // await new Promise((resolve) => setTimeout(resolve, 500));
//   // window.scrollTo(0, 0);
// }
