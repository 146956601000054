/* eslint-disable import/no-named-as-default-member -- we need to use mixpanel-browser directly */
import mixpanel, { type Dict } from 'mixpanel-browser'

let INITIALIZED = false

export function init() {
  if (!INITIALIZED) {
    if (process.env.MIXPANEL_TOKEN) {
      mixpanel.init(process.env.MIXPANEL_TOKEN, {
        debug: Boolean(import.meta.env.DEV),
        track_pageview: false,
        persistence: 'localStorage',
        ignore_dnt: true,
      })
      INITIALIZED = true
    }
  }
}

export function trackEvent(eventName: string, eventProps?: Dict) {
  if (import.meta.env.DEV) {
    // eslint-disable-next-line no-console -- show analytics events only in dev
    console.info(
      '%c[Analytics] %c%s',
      'color:green',
      'color:orange',
      eventName,
      eventProps
    )
  }
  if (INITIALIZED) {
    mixpanel.track(eventName, eventProps)
  }
}

export function identify(userId?: string) {
  if (userId) {
    if (import.meta.env.DEV) {
      // eslint-disable-next-line no-console -- show analytics events only in dev
      console.info('[Analytics] Identify', userId)
    }
    if (INITIALIZED) {
      mixpanel.identify(userId)
    }
  }
}

export function peopleSet(people: Dict) {
  if (import.meta.env.DEV) {
    // eslint-disable-next-line no-console -- show analytics events only in dev
    console.info('[Analytics] People Set', people)
  }
  if (INITIALIZED) {
    mixpanel.people.set(people)
  }
}
