class NotificationTracker {
  private changeTags: string[] = []

  public addTag(tag: string) {
    this.changeTags.push(tag)
  }

  public removeTag(tag: string) {
    this.changeTags = this.changeTags.filter((t) => t !== tag)
  }

  public hasTag(tag: string) {
    return this.changeTags.includes(tag)
  }
}

// Singleton instance to track skipped notifications
export default new NotificationTracker()
