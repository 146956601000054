import { useQueryClient } from '@tanstack/react-query'
import { useCloudKitClient } from '../providers/CloudKitClientProvider'
import {
  type Note,
  type Change,
  isTeamspaceNote,
  isCalendarNote,
  NoteType,
} from '../utils/syncUtils'
import { cacheKeys, noteQueryKey } from '../utils/queryKeyFactory'
import { useUserState } from '../providers/UserProvider'
import { useCachedNotesQueryClient } from '../providers/CachedNotesProvider'
import { mapSet } from '../utils/mapAsState'
import { saveNote } from '../lib/supabase/NoteOperations'
import { trackEvent } from '../lib/analytics'
import { useSafeMutation } from './useSafeMutation'
import { updateReferencesCache } from './useNoteReferences'

export function useSaveNote(
  successCallback?: (_data: Note | undefined) => void
) {
  const ck = useCloudKitClient()
  const user = useUserState()
  const privateUserId = user?.cloudKitUserId ?? user?.supabaseUserId

  const queryClient = useQueryClient()
  const cachedNotesQueryClient = useCachedNotesQueryClient()

  return useSafeMutation<Note | undefined, Error, Change>({
    mutationFn: (change: Change) => {
      // always get the latest version of the RecordChangeTag
      let note = queryClient.getQueryData<Note>(noteQueryKey(change))

      // Used by starter note creator, because we don't create the note by opening the calendar date
      if (change.forceCreate) {
        note = {
          recordName: change.recordName,
          noteType: change.noteType,
          parent: change.parent, // Assign ID of parent in case it's supabase, ignored by CloudKit
          content: change.content,
          filename: change.filename,
        }
      }

      // console.debug('[useSaveNote] saving', note, change)

      // Teamspace notes are managed by supabase
      if (user?.cloudKitUserId && !isTeamspaceNote(note?.noteType)) {
        return ck.saveNote(
          note,
          change.content,
          change.attachments ?? [],
          change.modificationDate
        )
      }

      if (user?.supabaseUserId) {
        return saveNote(
          user.supabaseUserId,
          note,
          change.content,
          change.attachments ?? []
        )
      }

      throw new Error('Not signed in')
    },
    onSuccess: (note: Note, change: Change) => {
      if (change.noteType !== undefined) {
        trackEvent('WEB - Note Updated', {
          recordName: change.recordName,
          noteType: NoteType[change.noteType],
          parent: change.parent,
          filename: change.filename,
        })

        updateReferencesCache(queryClient, cachedNotesQueryClient, note, user)
        queryClient.setQueryData(noteQueryKey(change), note)

        const queryKey = isTeamspaceNote(note.noteType)
          ? isCalendarNote(note.noteType)
            ? cacheKeys.teamCalendarNotes(user.supabaseUserId)
            : cacheKeys.teamProjectNotes(user.supabaseUserId)
          : isCalendarNote(note.noteType)
            ? cacheKeys.privateCalendarNotes(privateUserId)
            : cacheKeys.privateProjectNotes(privateUserId)

        cachedNotesQueryClient.setQueriesData(
          queryKey,
          (oldData: Map<string, Note>) => {
            return mapSet(oldData, note.recordName, note)
          }
        )
      }
      successCallback?.(note)
    },
    onError: (error, change) => {
      trackEvent('WEB - Note Updated Failed', {
        error,
      })
      if (error.message.includes('oplock error')) {
        trackEvent('WEB - Note Updated Failed - Oplock Error', {
          error,
        })

        // Refetch the note upon error, so the next time it works. This would be merged once we receive it in TipTapEditor
        void queryClient.invalidateQueries(noteQueryKey(change))
      }
    },
    retry: 3, // Retry a few times, in case we resolve the oplock error
  })
}
