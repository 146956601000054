import { User } from '../providers/UserProvider'
import {
  Note,
  calendarFilenameToTitle,
  filenameToKey,
  findMatches,
  isCalendarNote,
} from '../utils/syncUtils'
import { QueryClient, useQuery } from '@tanstack/react-query'
import { cacheKeys, privateKeys, teamKeys } from '../utils/queryKeyFactory'
import {
  BlockNoteEditor,
  DefaultBlockSchema,
  PartialBlock,
} from '@packages/blocknote-core'
import { Range } from '@tiptap/core'

export type FromNote = {
  title: string
  blocks: PartialBlock<DefaultBlockSchema>[]
  ranges: Range[]
}

export type UseNoteReferencesType = 'private' | 'team'
export type NoteReferenceMap = Map<
  string,
  { incoming: Map<string, FromNote>; outgoing: string[] }
>

function removeReferences(references: NoteReferenceMap, noteID: string) {
  if (!noteID) {
    return references
  }
  const noteReferences = references.get(noteID)

  if (noteReferences) {
    noteReferences.outgoing.forEach((outgoingRef) => {
      const reference = references.get(outgoingRef)
      if (reference) {
        reference.incoming.delete(noteID)
      }
    })
    references.delete(noteID)
  }

  return references
}

// Extracted logic to compute references
function computeReferences(
  notes: Map<string, Note>,
  existingNotes: Map<string, Note> | undefined = undefined,
  existingReferences: NoteReferenceMap | undefined = undefined,
  isUpdate = false
): NoteReferenceMap {
  const references =
    existingReferences ??
    new Map<string, { incoming: Map<string, FromNote>; outgoing: string[] }>()
  const lookupNotes = existingNotes ?? notes

  if (!(lookupNotes instanceof Map)) {
    throw new Error('lookupNotes must be a Map')
  }

  const referenceRegex = /\[\[([^\]]+)\]\]/g
  const scheduleRegex =
    /(?<=\s|^)>(\d{4}(?:-\d{2}(?:-\d{2})?|-(?:W\d{2}|Q[1-4])))\b/g

  // Create a title to note map for quick lookup
  const titleToNoteMap = new Map<string, Note>()
  for (const note of lookupNotes.values()) {
    const title = isCalendarNote(note.noteType)
      ? filenameToKey(note.filename)
      : note.title ?? 'Untitled'
    titleToNoteMap.set(title, note)
  }

  notes.forEach((note) => {
    if (!note.content) {
      return
    }

    const results = findMatches(note, [
      { regex: referenceRegex, group: 1, cleanRegex: null, replace: '' },
      { regex: scheduleRegex, group: 1, cleanRegex: null, replace: '' },
    ])

    const outgoing: string[] = [] //results.map((result) => result.match)

    results.forEach((result) => {
      const referencedNote = titleToNoteMap.get(result.match)
      let referencedNoteID = result.match // Use the match as the ID if we don't find a note, specifically for calendar notes, we still need to store the reference

      // Reference found, use the title in case of calendar notes and the recordName in case of regular notes
      if (referencedNote && !isCalendarNote(referencedNote.noteType)) {
        referencedNoteID = referencedNote.recordName
      }

      // If the outgoing list doesn't contain this reference yet, remove old references and add it (otherwise we will duplicate what's already there)
      if (
        !outgoing.includes(referencedNoteID) &&
        references.has(referencedNoteID)
      ) {
        // To avoid duplicates, remove existing incoming references from the currently scanned note in the matched note
        references.get(referencedNoteID).incoming.delete(note.recordName)
      }

      // Add the id as outgoing reference, so we can track them in case this note gets deleted, then we know where to remove them
      outgoing.push(referencedNoteID)

      // If there's no reference entry, create one
      if (!references.has(referencedNoteID)) {
        references.set(referencedNoteID, {
          incoming: new Map<string, FromNote>(),
          outgoing: [],
        })
      }

      // Add the incoming reference, if it doesn't exist yet
      const referencedNoteReferences = references.get(referencedNoteID)
      if (!referencedNoteReferences.incoming.has(note.recordName)) {
        referencedNoteReferences.incoming.set(note.recordName, {
          title: isCalendarNote(note.noteType)
            ? calendarFilenameToTitle(note.filename, true)
            : note.title ?? 'Untitled',
          blocks: [],
          ranges: [],
        })
      }

      // Get the note and assign the block and ranges, so we can show it in the referenes and know where it is in case the use clicks on it
      const fromNote = referencedNoteReferences.incoming.get(note.recordName)
      const block = BlockNoteEditor.notePlanToBlocks(result.line, '')[0]

      // Don't add scheduled tasks
      if (block.props?.['scheduled']) {
        return
      }

      fromNote.blocks.push(block)

      const from = result.range.from
      const to = result.range.to
      fromNote.ranges.push({ from, to })
    })

    // If it's an update, we need to remove outgoing references from the target notes that don't exist anymore
    if (isUpdate && references.has(note.recordName)) {
      const oldOutgoing = references.get(note.recordName)?.outgoing ?? []
      const toRemove = oldOutgoing.filter((o) => !outgoing.includes(o))
      toRemove.forEach((r) => {
        const target = references.get(r)
        if (target) {
          target.incoming.delete(note.recordName)
        }
      })
    }

    // Create a reference entry if there's none and the outgoing links are non-empty, otherwise we don't need to store an empty list
    if (!references.has(note.recordName) && outgoing.length > 0) {
      references.set(note.recordName, {
        incoming: new Map<string, FromNote>(),
        outgoing: [],
      })
    }

    // Store the outgoing links = results, so we can match and remove them later
    if (references.has(note.recordName)) {
      const noteReferences = references.get(note.recordName)
      noteReferences.outgoing = outgoing
      if (outgoing.length === 0 && noteReferences.incoming.size === 0) {
        references.delete(note.recordName)
      }
    }
  })

  return references
}

// Function to update the cache
export function updateReferencesCache(
  queryClient: QueryClient,
  cachedNotesQueryClient: QueryClient,
  noteToUpdate: Note | undefined,
  user: User
) {
  if (!noteToUpdate) {
    return
  }

  const notesToUpdate = new Map<string, Note>([
    [noteToUpdate.recordName, noteToUpdate],
  ])

  // Update private references
  if (user.cloudKitUserId) {
    queryClient.setQueryData<
      Map<string, { incoming: Map<string, FromNote>; outgoing: string[] }>
    >(privateKeys.references, (oldReferences) => {
      const calendarNotes = cachedNotesQueryClient.getQueryData<
        Map<string, Note>
      >(cacheKeys.privateCalendarNotes(user.cloudKitUserId))
      const projectNotes = cachedNotesQueryClient.getQueryData<
        Map<string, Note>
      >(cacheKeys.privateProjectNotes(user.cloudKitUserId))
      const existingNotes = new Map([
        ...(calendarNotes ?? []),
        ...(projectNotes ?? []),
      ])
      return new Map(
        computeReferences(notesToUpdate, existingNotes, oldReferences, true)
      )
    })
  }

  // Update team references
  if (user.supabaseUserId) {
    queryClient.setQueryData<
      Map<string, { incoming: Map<string, FromNote>; outgoing: string[] }>
    >(teamKeys.references, (oldReferences) => {
      // Merge logic here
      // return new Map([...(oldReferences ?? []), ...computeReferences(notesToUpdate, cachedNotesQueryClient.getQueryData(cacheKeys.team(user.supabaseUserId)))])
      const calendarNotes = cachedNotesQueryClient.getQueryData<
        Map<string, Note>
      >(cacheKeys.teamCalendarNotes(user.supabaseUserId))
      const projectNotes = cachedNotesQueryClient.getQueryData<
        Map<string, Note>
      >(cacheKeys.teamProjectNotes(user.supabaseUserId))
      const existingNotes = new Map([
        ...(calendarNotes ?? []),
        ...(projectNotes ?? []),
      ])
      return new Map(
        computeReferences(notesToUpdate, existingNotes, oldReferences, true)
      )
    })
  }
}

// Remove the references of the delete note. In addition to deleting the incoming references of the given note, we need to search for the outgoing references and remove the incoming references from the target notes.
export function deleteReferenceCache(
  queryClient: QueryClient,
  recordName: string,
  user: User
) {
  if (user.cloudKitUserId) {
    queryClient.setQueryData<
      Map<string, { incoming: Map<string, FromNote>; outgoing: string[] }>
    >(privateKeys.references, (oldReferences) => {
      return new Map(removeReferences(oldReferences, recordName))
    })
  }

  // Update team references
  if (user.supabaseUserId) {
    queryClient.setQueryData<
      Map<string, { incoming: Map<string, FromNote>; outgoing: string[] }>
    >(teamKeys.references, (oldReferences) => {
      return new Map(removeReferences(oldReferences, recordName))
    })
  }
}

export function useNoteReferences(
  type: UseNoteReferencesType,
  notes: Map<string, Note> | undefined
) {
  let queryKey = null
  if (type === 'private') {
    queryKey = privateKeys.references
  }
  if (type === 'team') {
    queryKey = teamKeys.references
  }

  return useQuery({
    enabled: !!queryKey && !!notes, // Has to return a boolean otherwise it crashes in Chrome
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: queryKey,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    refetchOnReconnect: 'always',
    queryFn: async () => computeReferences(notes),
  })
}
