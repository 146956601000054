import { Resizable } from 're-resizable'
import classNames from 'classnames'
import {
  type DragEvent,
  useState,
  type Dispatch,
  type SetStateAction,
} from 'react'
import { type Dayjs } from 'dayjs'
import { handleResizeStop } from '../utils/resize'
import { addAlphaToColor, darkenColor } from '../../../../utils/color'
import { useDarkMode } from '../../../../providers/DarkModeProvider'
import { type CalendarEvent } from '../../CalendarEvent'

export type EventContentProps = {
  setCalendarEventDrag: Dispatch<SetStateAction<CalendarEvent | null>>
  event: CalendarEvent
  eventHeight: number
  segmentHeight: number
  offsetPxTop?: number
  className?: string
  style?: React.CSSProperties
  selectedDay?: Dayjs
}

export function EventContent({
  setCalendarEventDrag,
  event,
  eventHeight,
  segmentHeight,
  offsetPxTop = 0,
  className = '',
  style = {},
  selectedDay,
}: EventContentProps) {
  const isDarkMode = useDarkMode()
  const [deltaHeight, setDeltaHeight] = useState<number | null>(null)
  const [maxHeight, setMaxHeight] = useState<number | null>(null)

  const isShortEvent = eventHeight < segmentHeight * 2
  const eventDate = event.startDate
  const bounds = document
    .getElementById('calendar-event-bounds')
    ?.getBoundingClientRect()
  let iconName
  if (event.contentType === 'taskListItem') {
    iconName = 'fa-regular fa-circle'
  } else if (event.contentType === 'bulletListItem') {
    iconName = 'fa-solid fa-circle-small'
  } else {
    iconName = 'fa-regular fa-square'
  }

  const position =
    event.contentType === 'bulletListItem'
      ? `text-[0.5rem] left-[0.6em] ${isShortEvent ? 'top-[0.45em]' : 'top-[0.45em]'}`
      : `left-[0.2em] ${isShortEvent ? 'top-[0.2em]' : 'top-[0.1em]'}`

  const icon = event.contentType !== 'event' && (
    <i
      className={`absolute ${position} ${iconName}${event.checked ? '-check' : ''}`}
    />
  )
  let colorClassName
  if (event.contentType === 'event') {
    colorClassName = event.color ? '' : 'blue'
  } else {
    colorClassName = 'orange'
  }

  const color =
    event.color && isDarkMode ? darkenColor(event.color, 50) : event.color

  return (
    <Resizable
      className={classNames(
        'relative h-full flex-grow user-none',
        { 'opacity-50': event.id === 'temporary' },
        { 'opacity-[0.46]': event.checked }
      )}
      style={{
        ...style,
        transform: deltaHeight
          ? `translateY(${-deltaHeight + (offsetPxTop ?? 0)}px)`
          : style.transform ?? '',
      }}
      onResize={(_e, dir, _ref, delta) => {
        dir == 'top' && setDeltaHeight(delta.height)
      }}
      onResizeStop={(e, dir, _ref, delta) => {
        e.preventDefault()
        handleResizeStop(event, segmentHeight, delta, dir)
        setDeltaHeight(0)
        // setMaxHeight(null);
      }}
      onResizeStart={(e, dir, ref) => {
        e.preventDefault()
        setMaxHeight(
          eventHeight -
            2 +
            (dir == 'top'
              ? Math.abs(bounds.top - ref.getBoundingClientRect().top)
              : Math.abs(bounds.bottom - ref.getBoundingClientRect().bottom))
        )
      }}
      size={{ height: eventHeight - 2, width: 'auto' }}
      enable={{
        top: true,
        right: false,
        bottom: true,
        left: false,
        topRight: false,
        bottomRight: false,
        bottomLeft: false,
        topLeft: false,
      }}
      grid={[1, segmentHeight]}
      maxHeight={maxHeight ?? 0 - 2}
      minHeight={segmentHeight}
      draggable='true'
      onDragEnter={(_e: DragEvent) => {
        const calendarEventList = document.getElementById(
          'calendar-events-list'
        )
        calendarEventList?.style.setProperty('pointer-events', 'none')
      }}
      onDragStart={(e: DragEvent) => {
        e.dataTransfer.setData(
          'text/plain',
          JSON.stringify({ ...event, type: 'timeblock' })
        )
        const target = e.target as HTMLElement

        const clientRect = target.getBoundingClientRect()
        const scaledElement = target.cloneNode(true) as HTMLElement

        scaledElement.id = 'dragged-event-image'
        scaledElement.style.width = String(clientRect.width * 0.6)
        scaledElement.style.height = String(clientRect.height * 0.6)

        // Hide the new element by moving it away, because setDragImage will copy it
        scaledElement.style.transform = 'translateX(-5000px)'
        document.body.appendChild(scaledElement)

        e.dataTransfer.setDragImage(scaledElement, 0, 0)
        setCalendarEventDrag(event)
      }}
    >
      <div
        id={`event-content-${event.id}`}
        className={`truncate event-content-background ${colorClassName} ${className} opacity-90`}
        style={{
          backgroundColor: addAlphaToColor(color, 0.1962),
          borderColor: addAlphaToColor(color, 0.924),
        }}
      >
        <p
          className={classNames(
            'time text-opacity-95 text-[0.65rem] ml-px -mb-0.5',
            { hidden: isShortEvent }
          )}
        >
          {(event.contentType !== 'event' ||
            eventDate.isSame(selectedDay, 'day')) && (
            <>
              <time dateTime={eventDate.format('YYYY-MM-DDTHH:mm')}>
                {eventDate.format('LT')}
              </time>
              {event.contentType === 'event' && (
                <>
                  <i className='fa-light fa-xs fa-alarm-clock mx-1' />
                  {event.calendarName}
                </>
              )}
            </>
          )}
        </p>
        <p className='label relative ml-px truncate text-xs font-semibold text-opacity-95'>
          {icon}
          <span className={`ml-${event.contentType === 'event' ? '0' : '5'}`}>
            {event.content}
          </span>
        </p>
      </div>
    </Resizable>
  )
}
