/* eslint-disable no-console */
import {
  BlockNoteEditor,
  type LinkMarkSuggestionItem,
} from '@packages/blocknote-core'
import { BlockNoteView, useBlockNote } from '@packages/blocknote-react'
import '@packages/blocknote-core/style.css'
import type React from 'react'
import { useRef, useEffect, useState, useMemo, useCallback } from 'react'
import { DiffMatchPatch } from 'diff-match-patch-typescript'
import { findAttachmentURLs } from '@packages/blocknote-core'
import { type Node } from 'prosemirror-model'
import { type Selection } from 'prosemirror-state'
import { useEditorContentDispatch } from '../../providers/EditorContentProvider'
import { useDarkMode } from '../../providers/DarkModeProvider'
import useBulletAndTaskItemCharacters from '../../hooks/useBulletAndTaskItemCharacters'
import { useDebouncedState } from '../../hooks/useKeyedDebounce'
import {
  type Note,
  type Change,
  isCalendarNote,
  SourceDatabase,
  type Attachment,
} from '../../utils/syncUtils'
import { useSaveNote } from '../../hooks/useSaveNote'
import { type WindowWithEditor } from '../../utils/WindowWithEditor'
import { TextUtils } from './TextUtils'

function EditorHeader() {
  return <div className='editor-header flex justify-between' />
}

function SkeletonLoader() {
  const lineLengths = [80, 95, 75, 85, 65]
  return (
    <div>
      <h2 className='skeleton my-12 h-8 w-2/5 rounded-md text-transparent'>
        Loading note content...
      </h2>
      {lineLengths.map((length, index) => {
        return (
          <div
            key={index}
            className='skeleton my-3 h-4 rounded-md'
            style={{ width: `${length}%` }}
          />
        )
      })}
    </div>
  )
}

function CustomEditorContent({
  editor,
  isLoading,
  isReady,
}: {
  editor: BlockNoteEditor | null
  isLoading: boolean
  isReady: boolean
}) {
  const [showSkeleton, setShowSkeleton] = useState(false)
  const [showEditor, setShowEditor] = useState(true)
  const skeletonTimeoutId = useRef(null)

  useEffect(() => {
    const updateEditor = async () => {
      if (isLoading || !editor) {
        setShowEditor(false)
        clearTimeout(skeletonTimeoutId.current)
        skeletonTimeoutId.current = setTimeout(() => {
          setShowSkeleton(true)
        }, 300)
        return () => {
          clearTimeout(skeletonTimeoutId.current)
        }
      }
      clearTimeout(skeletonTimeoutId.current)
      // delay 500ms until the content is rendered
      await new Promise((r) => setTimeout(r, 100))

      setShowSkeleton(false)
      setShowEditor(true)
      // scroll .editor-container-wrapper to the top after content is loaded
      const editorContentWrapper = document.querySelector(
        '.editor-container-wrapper'
      )
      if (editorContentWrapper) {
        // delay 200ms until the content is rendered
        await new Promise((r) => setTimeout(r, 200))
        editorContentWrapper.scroll(0, 0)
      }
    }
    updateEditor()
  }, [isLoading, editor])

  const handlePaste = useCallback(
    (event) => {
      const items = (event.clipboardData || event.originalEvent.clipboardData)
        .items

      for (const item of items) {
        TextUtils.loadAttachment(item.getAsFile(), editor)
      }

      event.preventDefault()
    },
    [editor]
  )

  return (
    <div className='relative'>
      <div
        className={`skeleton-loader absolute h-full w-full ${
          showSkeleton ? 'show' : 'hide'
        }`}
      >
        <SkeletonLoader />
      </div>
      <div
        className={`editor-content ${showEditor ? 'show' : 'hide'}`}
        onPaste={handlePaste}
      >
        {isReady && editor ? <BlockNoteView editor={editor} /> : null}
      </div>
    </div>
  )
}

type TipTapEditorProps = {
  isLoading: boolean
  note: Note | undefined
  // onLoad: (_blocks: Block<BlockSchema>[]) => void;
  // onChange: (_blocks: Block<BlockSchema>[]) => void;
  setNeedsUpload: (_needsUpload: boolean) => void
  onMarkClicked: (_event: MouseEvent) => void
  onLoadSuggestions: (
    _prefix: string,
    _keyword: string
  ) => (string | LinkMarkSuggestionItem)[]
  isEditable?: boolean
  handleClick?: (_event: React.MouseEvent<HTMLDivElement>) => void
  shouldForceUpdateEditor?: number
}

type Update = {
  note: Note | undefined
  content: string | undefined
}

const mergeNotes = (
  previousContent: string | undefined,
  currentContent: string | undefined,
  newContent: string | undefined
): string | undefined => {
  if (!previousContent || !newContent || !currentContent) {
    console.info('[MERGE] One of the contents is null')
    return undefined
  }

  const dmp = new DiffMatchPatch()
  const diffs = dmp.diff_main(previousContent, newContent)
  dmp.diff_cleanupEfficiency(diffs)
  console.log('diffs', diffs)
  if (!diffs || (diffs.length == 1 && diffs[0][0] == 0)) {
    // nothing to merge
    console.info('[MERGE] Nothing to merge')
    return undefined
  }

  const patches = dmp.patch_make(diffs)
  // console.log('patches', patches);
  const result = dmp.patch_apply(patches, currentContent)
  // console.log('result', result);
  const merged = result[0]
  // console.log('merged', merged);
  const applied = result[1]

  for (const didApply of applied) {
    if (!didApply) {
      // TODO show conflict resolution UI
      console.info("[MERGE] Some patch wasn't applied successfully.")
      return undefined
    }
  }

  console.info(
    '[MERGE] Success',
    patches,
    previousContent,
    newContent,
    currentContent
  )
  return merged
}

const newCursorIndex = (
  currentContent: string | undefined,
  newContent: string | undefined,
  cursorIndex: number
): number => {
  if (!currentContent || !newContent) {
    return cursorIndex
  }

  const dmp = new DiffMatchPatch()
  const diffs = dmp.diff_main(currentContent, newContent)
  dmp.diff_cleanupEfficiency(diffs)
  // console.log('diffs', diffs[0][0]);

  if (!diffs || (diffs.length == 1 && diffs[0][0] == 0)) {
    // nothing to merge
    return cursorIndex
  }

  const patches = dmp.patch_make(diffs)
  // console.log('patches', patches);

  for (const patch of patches) {
    if (patch.start1 > cursorIndex) {
      // Changes are after the cursor, so ignore
      continue
    }

    // Iterate over diffs in the current patch
    for (const [operation, text] of patch.diffs) {
      if (patch.start1 + text.length < cursorIndex) {
        // Entire diff is before the cursor
        if (operation === 1) {
          // Addition: increase cursor index
          cursorIndex += text.length
        } else if (operation === -1) {
          // Deletion: decrease cursor index
          cursorIndex -= text.length
        }
      } else {
        // Diff overlaps with cursor, need to handle partially
        const overlapLength = cursorIndex - patch.start1
        if (operation === 1) {
          // Addition: increase cursor index by overlap length
          cursorIndex += overlapLength
        } else if (operation === -1) {
          // Deletion: decrease cursor index by overlap length
          cursorIndex -= overlapLength
        }
        break // No need to check further diffs in this patch
      }
    }
  }

  return cursorIndex
}

function TipTapEditor({
  isLoading,
  note,
  /*onLoad, onChange,*/ setNeedsUpload,
  onMarkClicked,
  onLoadSuggestions,
  isEditable = true,
  handleClick = () => {
    /** */
  },
  shouldForceUpdateEditor,
}: TipTapEditorProps): JSX.Element {
  const isDarkMode = useDarkMode()
  const noteKey = isCalendarNote(note?.noteType)
    ? note?.filename
    : note?.recordName
  // Fix freeze of UI: memoize the initial content, so we don't have to re-parse it on every render.
  // We have to add note.parent as dependency because calendar notes can have the same filename across teamspaces, and it would update the content when switching
  const isEmptyAndNotEditable = !isEditable && note?.isEmpty

  const { taskItemCharacters, bulletItemCharacters } =
    useBulletAndTaskItemCharacters()
  const isUpdatingContent = useRef(false)

  // If the note is empty and not editable, initialize with a specific message
  const initialContentMessage = isEmptyAndNotEditable
    ? '> Subscribe to access full editing capabilities (including past notes) and much more!'
    : ''

  const memoDependency = isCalendarNote(note?.noteType)
    ? note?.filename
    : note?.recordName
  // When it's a calendar note, go by filename changes (and parent), if it's not a calendar note, go by recordName changes (otherwise it gets reloaded and cursor reset).

  const initialContent = useMemo(
    () =>
      note?.recordName || note?.filename
        ? BlockNoteEditor.notePlanToBlocks(
            isEmptyAndNotEditable ? initialContentMessage : note.content,
            note.attachments ?? ''
          )
        : [],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      memoDependency,
      note?.parent,
      shouldForceUpdateEditor,
      isDarkMode, // The editor is updated if this changes, so we need to update the initial content as well
      bulletItemCharacters, // The editor is updated if this changes, so we need to update the initial content as well
      taskItemCharacters, // The editor is updated if this changes, so we need to update the initial content as well
    ]
  )

  // Store here the cursor position and update it when the isDarkMode or character settings are changed, this prevents jumping to cursor position zero
  const cursorPositionRef = useRef<Selection | null>(null)
  useEffect(() => {
    if (editor) {
      cursorPositionRef.current = editor._tiptapEditor.state.selection
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDarkMode, bulletItemCharacters, taskItemCharacters])

  const [change, setChange] = useState<Change | undefined>(undefined)

  useEffect(() => {
    if (note?.recordName) {
      setChange({
        content: note.content ?? '',
        attachments:
          JSON.parse(note.attachments?.length > 0 ? note.attachments : '[]') ??
          [],
        recordName: note.recordName,
        filename: note.filename,
        parent: note.parent,
        noteType: note.noteType,
        modificationDate: note.fileModifiedAt,
      })
    }
  }, [note])

  // This debounce can handle multiple timeouts when we make changes to multiple notes before the debounce triggers.
  const [debouncedValue, setDebouncedValue] = useDebouncedState(change, 1000)

  const saveNote = useSaveNote((updatedNote: Note | undefined) => {
    // Successfully saved, update the prevUpdate, so that we don't attempt to merge it with the current content (unecessarily)
    prevUpdate.current = { note: updatedNote, content: updatedNote?.content }
  })

  const prevUpdate = useRef<Update>({ note, content: note?.content })
  const setEditorContent = useEditorContentDispatch()

  const [editorIsReady, setEditorIsReady] = useState(false)

  const editor: BlockNoteEditor | null = useBlockNote(
    {
      theme: isDarkMode ? 'dark' : 'light',
      initialContent,
      editable: isEditable,
      editorDOMAttributes: {
        class: 'h-full',
      },
      bulletItemCharacters,
      taskItemCharacters,
      onEditorReady: (editor: BlockNoteEditor) => {
        const blocks = editor.topLevelBlocks
        editor._tiptapEditor.commands.focus()

        setEditorContent(blocks)
        // Set the scroll position to 0, otherwise, it will scroll completely or half-way down in longer notes
        editor._tiptapEditor.commands.setTextSelection({
          from: cursorPositionRef.current?.from,
          to: cursorPositionRef.current?.to,
        })
        // TODO: This is a hack to make sure the editor is ready to be used.
        // We should find a better way to handle this, maybe by using a ref.
        setTimeout(() => {
          setEditorIsReady(true)
        }, 1000)
      },
      onEditorContentChange: (editor: BlockNoteEditor) => {
        if (isUpdatingContent.current) {
          return // Skip the callback if we are updating the content programmatically
        }

        if (note) {
          const blocks = editor.topLevelBlocks

          const content = BlockNoteEditor.blocksToNotePlan(
            blocks,
            note.source == SourceDatabase.CLOUDKIT
          )
          const updatedNote = {
            content,
            attachments: findAttachmentURLs(editor._tiptapEditor),
            recordName: note.recordName,
            filename: note.filename,
            parent: note.parent,
            noteType: note.noteType,
            modificationDate: new Date(),
          }

          setChange(updatedNote)
          setDebouncedValue(noteKey ?? '', updatedNote)
          setEditorContent(blocks)
        }
      },
      onMarkClicked,
      onLoadSuggestions,
    },
    [isDarkMode, initialContent, bulletItemCharacters, taskItemCharacters]
  )

  ;(window as WindowWithEditor).editor = editor

  // Save the note when the content changes, but debounced, so we don't save too often.
  useEffect(() => {
    if (debouncedValue) {
      // Check if the content has been changed at all, if not, there's no need to upload
      if (debouncedValue.content === prevUpdate.current.content) {
        return
      }
      saveNote.mutate(debouncedValue)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- If we include `saveNote` in the dependencies we have an infinite loop
  }, [debouncedValue])

  function updateAttachmentsIfNeeded(
    newAttachments: string,
    oldAttachments: string,
    noteSource: SourceDatabase,
    forceRedownload = false,
    editor: BlockNoteEditor | null
  ) {
    // If the attachment strings are different, update the attachments in the editor. They are most likely different because the old links expired.
    if (newAttachments != oldAttachments && editor) {
      // Treat Supabase and CloudKit differently, they have different methods of storing and referencing the attachments in the note
      if (note.source == SourceDatabase.SUPABASE) {
        // Note is from Supabase
        let attachments: Attachment[] = []
        try {
          attachments = JSON.parse(note.attachments).map((a: string) =>
            JSON.parse(a)
          )
        } catch (e) {
          console.error('Failed to parse attachments JSON', e)
          return
        }
        for (const attachment of attachments) {
          const filename = attachment.filename
          const url = attachment.url
          updateSupabaseAssetUrl(url, filename)
        }
      } else {
        // CloudKit
        const urls = JSON.parse(newAttachments)
        updateCloudKitAssetUrls(urls, forceRedownload)
      }
    }
  }

  // In CloudKit the images are sorted before the files and we only have an url. Each attachment is assigned in order of the occurences of the image/file markdown links in the note
  function updateCloudKitAssetUrls(urls: string[], forceRedownload = false) {
    if (!urls || urls.length === 0) {
      return
    }

    const { state, view } = editor._tiptapEditor
    const tr = state.tr
    let imageAttachmentIndex = 0
    let fileAttachmentIndex = 0

    // First pass to count image attachments and determine the starting index for file attachments (which is after the images)
    state.doc.descendants((node) => {
      node.marks.forEach((mark) => {
        if (
          mark.type.name === 'inlineAttachment' &&
          mark.attrs.title === 'image'
        ) {
          fileAttachmentIndex++
        }
      })
    })

    // Second pass to update URLs
    state.doc.nodesBetween(0, state.doc.content.size, (node, pos) => {
      node.marks.forEach((mark) => {
        if (mark.type.name === 'inlineAttachment') {
          let urlIndex: number
          let drawingUrl

          // Depending on if the inlineAttachment is an image or file, pick the correct index
          if (mark.attrs.title === 'image') {
            if (mark.attrs.filename.endsWith('drawing')) {
              drawingUrl = urls[imageAttachmentIndex] // Cache the drawing URL here so we can update it in the object
              imageAttachmentIndex++ // Skip drawings, after them we have the pngs
            }

            urlIndex = imageAttachmentIndex
            imageAttachmentIndex++
          } else if (mark.attrs.title === 'file') {
            urlIndex = fileAttachmentIndex
            fileAttachmentIndex++
          }

          // Check if the file isn't downloaded yet (might be the image or file link expired)
          if (
            urlIndex !== undefined &&
            urlIndex < urls.length &&
            (!mark.attrs.downloaded || forceRedownload)
          ) {
            const attr = {
              ...mark.attrs,
              downloadUrl: urls[urlIndex],
            }

            if (forceRedownload) {
              attr.downloaded = false
              attr.src = undefined
            }

            // If it's a drawing, also update the drawing URL
            if (drawingUrl) {
              attr.drawingUrl = drawingUrl
            }

            tr.addMark(pos, pos + node.nodeSize, mark.type.create(attr))
          }
        }
      })
    })

    if (tr.docChanged) {
      const newState = state.apply(tr)
      view.updateState(newState)
    }
  }

  function updateSupabaseAssetUrl(updatedUrl: string, filename: string) {
    if (!updatedUrl || !filename) {
      return
    }

    const { state, view } = editor._tiptapEditor
    const tr = state.tr
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let nodeToUpdate: { pos: number; node: Node; mark: any } | null = null

    state.doc.nodesBetween(0, state.doc.content.size, (node, pos) => {
      node.marks.forEach((mark) => {
        if (
          mark.type.name === 'inlineAttachment' &&
          mark.attrs.filename == filename &&
          !mark.attrs.downloaded
        ) {
          nodeToUpdate = { pos, node, mark }
        }
      })
    })

    if (nodeToUpdate) {
      const { pos, node, mark } = nodeToUpdate as {
        pos: number
        node: Node
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        mark: any
      }

      const attr = { ...mark.attrs, downloadUrl: updatedUrl }

      tr.addMark(pos, pos + node.nodeSize, mark.type.create(attr))

      const newState = state.apply(tr)
      view.updateState(newState)
    }
  }

  // Update editor content when there is a remote change, but only if the RecordChangeTag is different.
  // You need three versions of the note to merge it:
  // 1. The current local version = v1
  // 2. The new version you receive from the database = v2
  // 3. And a common ancestor of those two = v0
  // In NotePlan the ancestor is the last known uploaded note that’s cached.
  // Only the content is important, but we could cache the complete last uploaded version.
  useEffect(() => {
    if (!editor || !note) {
      return
    }

    // Get the current scroll position, so we can scroll back to it. Setting the content of the editor resets the scroll position to the bottom.
    const editorContentWrapper = document.querySelector(
      '.editor-container-wrapper'
    )

    // Cache scroll position and cursor index so we can restore it after an update
    const scrollPos = editorContentWrapper?.scrollTop
    const cursorIndex = editor._tiptapEditor.state.selection.from ?? 0

    function updateEditor(
      editor: BlockNoteEditor,
      note: Note,
      editorContent?: string,
      updateBlocks = true
    ) {
      if (updateBlocks) {
        isUpdatingContent.current = true // Set the flag before updating the content, so we don't trigger an accidental save and upload
        const blocks = BlockNoteEditor.notePlanToBlocks(
          note.content ?? '',
          note.attachments ?? '[]'
        )
        editor.replaceBlocks(editor.topLevelBlocks, blocks)
        isUpdatingContent.current = false // Reset the flag after updating the content
      }

      // Set the original cursor position to cursorIndex
      editor._tiptapEditor.commands.setTextSelection(
        newCursorIndex(
          editorContent ?? prevUpdate.current.content,
          note.content,
          cursorIndex
        )
      )

      // Scroll to the original position
      editorContentWrapper?.scroll(0, scrollPos ?? 0)

      // Update previous content
      prevUpdate.current = { note, content: note.content }
    }

    // Check if we loaded a different note, then we don't need to merge and can just set the content
    if (
      (note.content && !prevUpdate.current.note) ||
      prevUpdate.current.note?.recordName !== note.recordName
    ) {
      console.log('Loaded different or new note')
      updateEditor(editor, note, undefined, false)
      return
    }

    // Check if the recordChangeTags are different, if not, we don't need to merge
    if (prevUpdate.current.note?.recordChangeTag !== note.recordChangeTag) {
      const editorContent = BlockNoteEditor.blocksToNotePlan(
        editor.topLevelBlocks,
        note.source == SourceDatabase.CLOUDKIT
      )

      // Check if the content is different from the loaded content of the note, if not, we don't need to attempt to merge.
      if (editorContent === note.content) {
        // Update inlineAttachments if they didn't load correctly
        updateAttachmentsIfNeeded(
          note.attachments,
          prevUpdate.current.note?.attachments,
          note.source,
          true, // force redownload if the change tag updated, but none of the content
          editor
        )

        // nothing to merge
        prevUpdate.current = { note, content: note.content }
        return
      }

      // Previous note and this note are the same, so we are not out of date, no merge needed, just update
      else if (prevUpdate.current.content === editorContent && note.content) {
        updateEditor(editor, note, editorContent)
        return
      }

      // Second check is if the modification dates of the content and the note are the same, if they are different and we have an incoming change, we need to merge
      // This would mean we have local changes that are not uploaded yet, so we need to merge them with any incoming change
      // Ignore the dates if the modificationDate of the change is not set, means something went wrong and we probably don't have anything to update.
      if (
        !change.modificationDate ||
        prevUpdate.current.note?.fileModifiedAt >= change.modificationDate
      ) {
        // We haven't changed the content, so we can just update it and don't need to merge
        updateEditor(editor, note, editorContent)
        return
      }

      console.log(
        '[Merging] previous note vs change (check for modification date)',
        prevUpdate.current.note,
        change
      )

      const merged = mergeNotes(
        prevUpdate.current.content,
        editorContent,
        note.content
      )
      if (merged) {
        note.content = merged

        // We have changed the content with the merge, so save it (no debounce needed in this case).
        // This should usually happen after a conflict which triggers an "oplock" error = conflict when uploading.

        // First update the editor, otherwise, we won't have the updated attachment urls
        updateEditor(editor, note, editorContent)

        const updatedNote = {
          content: merged,
          attachments: findAttachmentURLs(editor._tiptapEditor),
          recordName: note.recordName,
          filename: note.filename,
          parent: note.parent,
          noteType: note.noteType,
          modificationDate: new Date(),
        } as Change
        setChange(updatedNote)
        saveNote.mutate(updatedNote)
      } else {
        // Merge failed, keep what we got here
        console.info("[MERGE] Wasn't necessary or failed")
        note.content = editorContent
        prevUpdate.current = { note, content: note.content }
      }
    } else {
      // Update inlineAttachments if they didn't load correctly
      updateAttachmentsIfNeeded(
        note.attachments,
        prevUpdate.current.note?.attachments,
        note.source,
        false, // Here we don't need to force download, the change tag didn't change
        editor
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [note, editor])

  // Show a dialog when the user tries to leave the page without saving
  useEffect(() => {
    if (prevUpdate.current.content !== undefined) {
      setNeedsUpload(prevUpdate.current.content !== note?.content)
    }

    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (
        prevUpdate.current.content !== undefined &&
        prevUpdate.current.content !== note?.content
      ) {
        // show dialog only if there are some changes that are not saved
        event.preventDefault()
        event.returnValue = ''
        return ''
      }
    }

    window.addEventListener('beforeunload', handleBeforeUnload)

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, [prevUpdate.current.content, note?.content, setNeedsUpload])

  // keyboard shortcuts
  useEffect(() => {
    function handleKeyDown(e: KeyboardEvent) {
      // Check for CMD (Mac) or CTRL (Windows/Linux), and check if the pressed key is "A" to select all the text
      if ((e.metaKey || e.ctrlKey) && e.key === 'a') {
        e.preventDefault()

        // Select all blocks
        if (editor?._tiptapEditor) {
          editor._tiptapEditor
            .chain()
            .focus()
            .setTextSelection({
              from: 0,
              to: editor._tiptapEditor.state.doc.content.size,
            })
            .run()
        }
      }
    }
    document.addEventListener('keydown', handleKeyDown)

    return function cleanup() {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [editor?._tiptapEditor])

  return (
    <div className='editor-container' onClick={handleClick}>
      <EditorHeader />
      <CustomEditorContent
        editor={editor}
        isLoading={isLoading}
        isReady={editorIsReady}
      />
    </div>
  )
}

export default TipTapEditor
