import {
  type DefaultBlockSchema,
  type PartialBlock,
  type PartialInlineContent,
  type StyledText,
} from '@packages/blocknote-core'
import classNames from 'classnames'
import { ChevronRightIcon } from '@heroicons/react/20/solid'
import { Disclosure } from '@headlessui/react'
import { useCallback, useEffect, useState } from 'react'
import { useLocalStorage } from 'usehooks-ts'
import { type Range } from '@tiptap/core'
import styles from '@packages/blocknote-core/extensions/Blocks/nodes/Block.module.css'
import { pluralize } from '../../utils/commons'
import { type FromNote } from '../../hooks/useNoteReferences'
import { type SearchContentResult } from '../search/Search'
import { useSaveNote } from '../../hooks/useSaveNote'
import useNote, { type useNoteConfig } from '../../hooks/useNote'
import { toggleTodoStateInLine } from '../../utils/syncUtils'

function SkeletonLoader() {
  const lineLengths = [80, 75, 65]
  return (
    <div>
      {lineLengths.map((length, index) => {
        return (
          <div
            key={index}
            className='skeleton my-3 h-4 rounded-md'
            style={{ width: `${length}%` }}
          />
        )
      })}
    </div>
  )
}

export function ReferenceBlock({
  result,
  onSelectNote,
  keyword,
}: {
  result: SearchContentResult | PartialBlock<DefaultBlockSchema>
  onSelectNote: () => void
  keyword?: string
}) {
  const block = 'block' in result ? result.block : result
  const searchResult = 'block' in result ? result : null

  // All this below is for updating the task state. It downloads the note by setting the line, then re-uploads it with the updated content and sets the line to null to avoid a loop.
  const [line, setLine] = useState<string | null>(null)

  // noteKey will be set when line is not null. This triggers loading the full note
  const noteKey = useCallback((): useNoteConfig | null => {
    // Initiate update if line is set and the content is different from what we had before
    if (!searchResult || !line || line == searchResult.content) {
      if (line) setLine(null)
      return null
    }

    return {
      noteType: searchResult.noteType,
      recordName: searchResult.recordName,
      filename: searchResult.filename,
      parent: searchResult.parent,
    }
  }, [line, searchResult])

  // Load the full note of this search result when noteKey is not null
  const { data: existingNote } = useNote(noteKey())

  // This is for saving the note after modifying the content
  const saveMutate = useSaveNote((_note) => {
    setLine(null)
  })

  // When the line is set and the note is loaded, update the given line and save it back to the database
  useEffect(() => {
    if (existingNote && line) {
      const contentArray = existingNote.content.split('\n')
      const index = searchResult.lineIndex

      if (contentArray[index] === searchResult.content) {
        contentArray[index] = line
        const newContent = contentArray.join('\n')

        saveMutate.mutate({
          filename: existingNote.filename,
          recordName: existingNote.recordName,
          content: newContent,
          attachments:
            JSON.parse(
              existingNote.attachments?.length > 0
                ? existingNote.attachments
                : '[]'
            ) ?? [],
          noteType: existingNote.noteType,
          parent: existingNote.parent,
          modificationDate: new Date(),
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [existingNote, line])

  return (
    <div
      className={`${styles.blockGroup} cursor-pointer pl-2`}
      data-node-type='blockGroup'
      onClick={onSelectNote}
    >
      <div
        data-id={block.id}
        className={styles.blockOuter}
        data-node-type='block-outer'
      >
        <div className={styles.block} data-node-type='blockContainer'>
          <div
            className={classNames({
              'flex items-center': true,
              '-ml-3': block.children?.length > 0 && block.type !== 'heading',
              '-ml-2': block.children?.length > 0 && block.type === 'heading',
            })}
          >
            <div
              className={classNames({
                pulse: Boolean(line),
                [styles.blockContent]: true,
                'text-zinc-500 dark:text-zinc-400 font-bold':
                  block.type === 'heading',
                'text-[15px] dark:opacity-90': block.type !== 'heading',
              })}
              data-content-type={block.type}
              data-checked={block.props?.checked ?? false}
              data-cancelled={block.props?.cancelled ?? false}
              data-scheduled={block.props?.scheduled ?? false}
              data-flagged={block.props?.flagged ?? 0}
              data-folded={block.props?.folded ?? false}
              data-visible={block.props?.visible ?? true}
            >
              {['taskListItem', 'checkListItem'].includes(block.type) && (
                <label
                  onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()

                    // Initiate downloading, updating and uploading note
                    if (!line && searchResult && searchResult.content) {
                      // Don't allow clicking when it's already loading
                      setLine(toggleTodoStateInLine(searchResult.content))
                    }
                  }}
                >
                  <input type='checkbox' />
                </label>
              )}
              <BlockContent content={block.content} keyword={keyword} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

function BlockContent({
  content,
  keyword,
}: {
  content: string | PartialInlineContent[]
  keyword?: string
}) {
  if (typeof content === 'string') {
    if (keyword) {
      return highlightText(content, keyword)
    }
    return <span>{content}</span>
  }
  return <InlineContent content={content} keyword={keyword} />
}

function highlightText(text: string, keyword: string) {
  const regex = new RegExp(`(${keyword})`, 'gi')
  const parts = text.split(regex)
  return (
    <span>
      {parts.map((part, index) => {
        if (part.toLowerCase() === keyword.toLowerCase()) {
          return <mark key={index}>{part}</mark>
        }
        return part
      })}
    </span>
  )
}

export function InlineContent({
  content,
  className,
  keyword,
}: {
  content: PartialInlineContent[]
  className?: string
  keyword?: string
}) {
  return (
    <div className={`break-all ${className ?? ''}`}>
      {content.map((inlineContent: PartialInlineContent, index) => {
        if (inlineContent.type === 'link') {
          return (
            <a
              href={inlineContent.href}
              key={index}
              className={styles.inlineContent}
            >
              {typeof inlineContent.content === 'string'
                ? inlineContent.content
                : inlineContent.content.map((text, index) =>
                    renderStyledText(text, index)
                  )}
            </a>
          )
        }
        return renderStyledText(inlineContent, index, keyword)
      })}
    </div>
  )
}

function renderStyledText(
  content: StyledText,
  index: number,
  keyword?: string
) {
  const text = keyword ? highlightText(content.text, keyword) : content.text
  if (content.styles.bold) {
    return <strong key={index}>{text}</strong>
  }
  if (content.styles.italic) {
    return <em key={index}>{text}</em>
  }
  if (content.styles.strikethrough) {
    return <del key={index}>{text}</del>
  }
  if (content.styles.underlined) {
    return <u key={index}>{text}</u>
  }
  if (content.styles.highlighted) {
    return <mark key={index}>{text}</mark>
  }
  if (content.styles.timeString) {
    return (
      <span data-content-type='time-string' key={index}>
        {text}
      </span>
    )
  }
  if (content.styles.code) {
    return <code key={index}>{text}</code>
  }
  if (content.styles.hashtag) {
    return (
      <a data-hashtag='hashtag' key={index}>
        {text}
      </a>
    )
  }
  if (content.styles.wikilink) {
    return (
      <a data-wikilink='wikilink' key={index}>
        {text}
      </a>
    )
  }
  if (content.styles.datelink) {
    return (
      <a data-datelink='datelink' key={index}>
        {text}
      </a>
    )
  }

  return (
    <span className='styledText' data-type={content.type} key={index}>
      {text}
    </span>
  )
}

function NoteReferencedFrom({
  id,
  note,
  onSelectNote,
}: {
  id: string
  note: FromNote
  onSelectNote: (_recordName: string, _range: Range) => void
}) {
  const [isOpen, setIsOpen] = useState(true)
  return (
    <Disclosure as='div' defaultOpen={isOpen} className='mt-2'>
      {({ open }) => (
        <>
          <Disclosure.Button
            onClick={() => {
              setIsOpen(!isOpen)
            }}
            className='flex'
          >
            <ChevronRightIcon
              className={classNames({
                'rotate-90': open,
                'text-gray-500 dark:text-gray-200 h-4 w-4 shrink-0 mr-1': true,
              })}
              aria-hidden='true'
            />
            <span className='flex items-center justify-between text-xs font-bold'>
              {note.title}
            </span>
          </Disclosure.Button>
          <Disclosure.Panel className='ml-10'>
            {note.blocks.map((child, index) => (
              <ReferenceBlock
                key={index}
                result={child}
                onSelectNote={() => {
                  onSelectNote(id, note.ranges[index])
                }}
              />
            ))}
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  )
}

type NoteReferenceProps = {
  isLoading: boolean
  references: Map<string, FromNote>
  onSelectNote: (_recordName: string) => void
}

export function NoteReference({
  isLoading,
  references,
  onSelectNote,
}: NoteReferenceProps) {
  const [isOpen, setIsOpen] = useLocalStorage('showReferences', true)
  const notes = Array.from(references.entries())
    .map(([recordName, note]) => ({ recordName, note }))
    .sort((a, b) => a.note.title.localeCompare(b.note.title))

  return (
    <Disclosure
      as='div'
      defaultOpen={isOpen}
      className='mx-auto mt-3 w-full max-w-3xl cursor-default'
      style={{ paddingInline: '54px' }}
    >
      {({ open }) => (
        <>
          <Disclosure.Button
            as='h1'
            className='flex items-center text-xs font-bold uppercase opacity-60'
            onClick={() => {
              setIsOpen(!isOpen)
            }}
          >
            <ChevronRightIcon
              className={classNames({
                'rotate-90': open,
                'text-gray-500 dark:text-gray-200 h-4 w-4 shrink-0 mr-1': true,
              })}
              aria-hidden='true'
            />
            <span className='opacity-60'>
              {references.size} {pluralize('Reference', references.size)}
            </span>
          </Disclosure.Button>
          <Disclosure.Panel className='my-3 rounded-md bg-zinc-100/70 px-0 py-1.5 dark:bg-zinc-800'>
            {isLoading ? (
              <SkeletonLoader />
            ) : (
              <div>
                {notes.map(
                  (item) =>
                    item.note.blocks.length > 0 && (
                      <NoteReferencedFrom
                        key={item.recordName}
                        id={item.recordName}
                        note={item.note}
                        onSelectNote={onSelectNote}
                      />
                    )
                )}
              </div>
            )}
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  )
}
