import * as React from 'react'
import { CloudKitClient } from '../lib/CloudKitClient'

export const cloudKitClientContext = React.createContext<
  CloudKitClient | undefined
>(undefined)
const cloudKitClient = new CloudKitClient()

export const useCloudKitClient = () => {
  const currentCloudKitClient = React.useContext(cloudKitClientContext)

  if (!currentCloudKitClient) {
    throw new Error('No QueryClient set, use CloudKitClientProvider to set one')
  }

  return currentCloudKitClient
}

export function CloudKitClientProvider({
  children,
}: {
  children: React.ReactNode
}) {
  const Context = cloudKitClientContext

  return <Context.Provider value={cloudKitClient}>{children}</Context.Provider>
}
