import { useEffect, useRef, useState } from 'react'
import { useWindowSize } from 'usehooks-ts'
import { type CalendarEvent, processEventsInBlock } from '../CalendarEvent'
import { useEditorContent } from '../../../providers/EditorContentProvider'
import { isCalendarNote } from '../../../utils/syncUtils'
import { useSidebarProvider } from '../../../providers/SidebarProvider'
import { useSelectedDate } from '../../../providers/SelectedDateProvider'
import VerticalLines from './components/VerticalLines'
import HorizontalLines from './components/HorizontalLines'
import { AllDayEvents } from './components/AllDayEvents'
import { getHourWidth } from './utils/time'
import { type PlaceholderEvent } from './types'
import { CalendarEvents } from './components/CalendarEvents'

type Props = {
  timelineDays: number
}

export function Timeline({ timelineDays }: Props) {
  const [scrolled, setScrolled] = useState(false)
  const container = useRef(null)
  const containerNav = useRef(null)
  const currentLineRef = useRef(null)
  const { height } = useWindowSize()
  const [calendarEventDrag, setCalendarEventDrag] =
    useState<CalendarEvent | null>(null)
  const [placeholderEvent, setPlaceholderEvent] =
    useState<PlaceholderEvent | null>(null)

  // const calendarHeight = useMemo(() => document.getElementById("calendar-view")?.offsetHeight ?? 200, [currentDate]);
  const calendarHeight =
    document.getElementById('calendar-view')?.offsetHeight ?? 200
  const timelineHeight = height - calendarHeight
  const [currentTime, setCurrentTime] = useState(new Date())

  // #region calendar events
  const [currentEvents, setCurrentEvents] = useState<CalendarEvent[]>([])
  const editorContent = useEditorContent()
  const { noteKey } = useSidebarProvider()
  const selectedDate = useSelectedDate()

  useEffect(() => {
    const updatedCurrentEvents: CalendarEvent[] = []
    if (!editorContent) {
      setCurrentEvents([])
      return
    }

    if (isCalendarNote(noteKey?.noteType) && selectedDate.active === 'day') {
      for (const block of editorContent) {
        updatedCurrentEvents.push(
          ...processEventsInBlock(block, selectedDate.date)
        )
      }

      if (
        JSON.stringify(currentEvents) !== JSON.stringify(updatedCurrentEvents)
      ) {
        setCurrentEvents(updatedCurrentEvents)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editorContent, noteKey?.noteType, selectedDate.active, selectedDate.date])
  // #endregion

  //  update the current time every minute
  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(new Date())
    }, 60000) // Update every minute

    return () => {
      clearInterval(timer)
    }
  }, [])
  // Scroll to the current time line.
  const scrollToCurrentLine = () => {
    if (currentLineRef.current && container.current && !scrolled) {
      currentLineRef.current.scrollIntoView({
        behavior: 'instant',
        block: 'center',
        inline: 'start',
        container: container.current,
      })
      setScrolled(true)
    }
  }

  useEffect(() => {
    document.ondrop = (e) => {
      e.preventDefault()

      if (!(e.target as Element).id.startsWith('week-column-')) {
        setPlaceholderEvent(null)
      }

      const calendarEventList = document.getElementById('calendar-events-list')
      calendarEventList?.style.removeProperty('pointer-events')

      const elementToDelete = document.getElementById('dragged-event-image')
      if (elementToDelete) {
        elementToDelete.parentNode?.removeChild(elementToDelete)
      }
    }
    document.ondragover = (e) => {
      e.preventDefault()
    }
    document.ondragenter = (e) => {
      e.preventDefault()
    }
  })

  const hourWidth = getHourWidth()

  return (
    <div
      className='isolate flex flex-col border-t'
      style={{
        width: 250 + timelineDays * 50,
        height: timelineHeight,
        maxHeight: timelineHeight,
      }}
    >
      <AllDayEvents selectedDay={selectedDate.date} labelWidth={hourWidth} />
      <div
        ref={container}
        className='flex flex-auto flex-col overflow-y-auto overflow-x-clip bg-transparent'
      >
        <div
          style={{ width: '165%' }}
          className='flex max-w-full flex-none flex-col'
        >
          <div
            ref={containerNav}
            className='sticky top-0 z-30 flex-none bg-transparent'
          >
            <div className='-mr-px flex border-r text-sm leading-6 text-gray-500'>
              <div className='col-end-1 flex' style={{ width: hourWidth }} />
              {/* {range(timelineDays).map((day) => (
                <div key={`weekday-${day}`} className={`flex flex-auto items-center justify-center space-x-1 bg-white py-1 text-xs dark:bg-gray-800`}>
                  {selectedDay.add(day, 'day').format('ddd DD')}
                </div>
              ))} */}
            </div>
          </div>
          <div className='flex flex-auto'>
            <div
              className='sticky left-0 z-10 flex-none bg-transparent'
              style={{ width: hourWidth }}
            />
            <div className='grid flex-auto grid-cols-1 grid-rows-1'>
              {/* Events */}
              <div
                id='calendar-event-bounds'
                className={`col-start-1 col-end-2 row-start-1 grid grid-cols-${timelineDays} pointer-events-none`}
                style={{
                  gridTemplateRows: '1rem repeat(96, 1rem) auto',
                  transform: `translateY(1rem)`,
                  height: 'calc(100% - (1rem + 4px))',
                }}
              />
              <ol
                id='calendar-events-list'
                className={`col-start-1 col-end-2 row-start-1 grid grid-cols-${timelineDays} z-10`}
                style={{ gridTemplateRows: '1.9rem repeat(96, 1rem) auto' }}
              >
                <CalendarEvents
                  setCalendarEventDrag={setCalendarEventDrag}
                  selectedDay={selectedDate.date}
                  currentEvents={currentEvents}
                  placeholderEvent={placeholderEvent}
                />
              </ol>
              {/* Horizontal lines */}
              <HorizontalLines />
              {/* Vertical lines */}
              <VerticalLines
                calendarEventDrag={calendarEventDrag}
                timelineDays={timelineDays}
                selectedDay={selectedDate.date}
                currentTime={currentTime}
                scrollToCurrentLine={scrollToCurrentLine}
                currentEvents={currentEvents}
                setCalendarEventDrag={setCalendarEventDrag}
                setPlaceholderEvent={setPlaceholderEvent}
                onUpdateCurrentEvents={setCurrentEvents}
                currentLineRef={currentLineRef}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
