/* eslint-disable no-console */
import {
  type Dispatch,
  type SetStateAction,
  createContext,
  useContext,
  useEffect,
  useRef,
} from 'react'
import type CloudKit from 'tsl-apple-cloudkit'
import { useQueryClient } from '@tanstack/react-query'
import { type AuthChangeEvent, type Session } from '@supabase/supabase-js'
import { useLocalStorage } from 'usehooks-ts'
import {
  signOut,
  session,
  setUserMetaData,
  getUserMetaData,
  setUpAuth,
} from '../lib/supabase/auth'
import { registerForNotifications } from '../lib/supabase/notification'
import { trackEvent } from '../lib/analytics'
import { useAccessTokenQueryClient, useSignOut } from './AccessTokenProvider'
import { useCachedNotesQueryClient } from './CachedNotesProvider'
import { useCloudKitClient } from './CloudKitClientProvider'

export type User = null | {
  cloudKitUserId?: string
  supabaseUserId?: string
  email?: string
  confirmed_at?: Date
}
export type UserMetaData = { cloudKitUserId?: string; hasNotes?: string }

export function mainId(user: User | undefined): string | undefined {
  if (user?.cloudKitUserId) {
    return user.cloudKitUserId
  } else if (user?.supabaseUserId) {
    return user.supabaseUserId
  }
  return undefined
}

// #region Context
const UserStateContext = createContext<User | undefined>(undefined)
const UserDispatchContext = createContext<
  Dispatch<SetStateAction<User>> | undefined
>(undefined)

export function useUserState() {
  return useContext(UserStateContext)
}

export const useUserDispatch = () => {
  return useContext(UserDispatchContext)
}
// #endregion

// #region UI functions
function reload() {
  window.location.href = '/'
}

function hideSignInForm() {
  document.querySelector('#sign-in-container')?.classList.add('hidden')
  document.querySelector('#authContainer')?.classList.add('hidden')
}

function showSignInForm() {
  document.querySelector('#sign-in-container')?.classList.remove('hidden')
  document.querySelector('#sign-out-container')?.classList.add('hidden')
  document.querySelector('#authContainer')?.classList.remove('hidden')
  document.querySelector('#cloudkit-sign-in')?.classList.remove('hidden')

  document
    .querySelector('#teamspace-sign-in-container')
    ?.classList.add('hidden')
  document
    .querySelector('#apple-sign-out-button-container')
    ?.classList.add('hidden')
  document.querySelector('#supabase-signout')?.classList.add('hidden')
}

export function showTeamspaceSignIn(visible = true) {
  if (visible) {
    document
      .querySelector('#teamspace-sign-in-container')
      ?.classList.remove('hidden')
    document.querySelector('#authContainer')?.classList.remove('hidden')
  } else {
    document
      .querySelector('#teamspace-sign-in-container')
      ?.classList.add('hidden')
    document.querySelector('#authContainer')?.classList.add('hidden')
  }
}

export function showTeamspaceSignOut() {
  document.querySelector('#supabase-signout')?.classList.remove('hidden')
  document.querySelector('#authContainer')?.classList.remove('hidden')
  document.querySelector('#sign-out-container')?.classList.remove('hidden')
  document
    .querySelector('#apple-sign-out-button-container')
    ?.classList.add('hidden')

  // Hide the teampspace login form
  document
    .querySelector('#teamspace-sign-in-container')
    ?.classList.add('hidden')
}

export function showSignOut(
  supabaseSignedIn: boolean,
  cloudKitSignedIn: boolean
) {
  if (supabaseSignedIn && !cloudKitSignedIn) {
    // If cloudkit is signed in, show only cloudkit logout, because it's preferred
    document.querySelector('#supabase-signout')?.classList.remove('hidden')
    document
      .querySelector('#apple-sign-out-button-container')
      ?.classList.add('hidden')
  } else {
    document.querySelector('#supabase-signout')?.classList.add('hidden')
    document
      .querySelector('#apple-sign-out-button-container')
      ?.classList.remove('hidden')
  }
  document.querySelector('#sign-out-container')?.classList.remove('hidden')
  document.querySelector('#authContainer')?.classList.remove('hidden')
  document
    .querySelector('#teamspace-sign-in-container')
    ?.classList.add('hidden')
}
// #endregion

/*
Login scenarios:
1. Just CloudKit
2. Just Supabase
3. First, CloudKit, then Supabase Teamspaces (sidebar has to be reloaded)
4. CloudKit + Teamspace (Supabase) - user logs out (on purpose) 
  = logout supabase and reset the sidebar
   
5. CloudKit + Teamspace (Supabase) - 24h auto logout
  = keep Supabase login, when logging in again with CloudKit 
    5a. Except it's a different user that logged in, then logout supabase and reset the sidebar
*/

export function UserProvider({ children }: { children: React.ReactNode }) {
  const [user, setUser] = useLocalStorage<User>('user', null)
  const cloudKitClient = useCloudKitClient()
  const queryClient = useQueryClient()
  const cachedNotesQueryClient = useCachedNotesQueryClient()
  const accessTokenQueryClient = useAccessTokenQueryClient()
  const signOutGoogle = useSignOut()
  const userId = mainId(user)

  function signOutGoogleMutation() {
    if (userId) {
      signOutGoogle.mutate({ userid: userId })
    }
  }

  // We need to store the user in a ref, because the user state is not updated immediately.
  // With this the functions below can access the current user state.
  const userReference = useRef(user)
  useEffect(() => {
    userReference.current = user
  }, [user])

  // Logging for debug reasons
  // console.log('extension', cachedNotesQueryClient?.getQueryData(cacheKeys.extension));

  function signInWithCloudKit(id: string) {
    setUser((previousUser) => ({ ...previousUser, cloudKitUserId: id }))
  }

  function signInWithSupabase(id: string, email: string, confirmed_at: Date) {
    setUser((previousUser) => ({
      ...previousUser,
      supabaseUserId: id,
      email,
      confirmed_at,
    }))
  }

  function signOutCloudKit() {
    setUser(null)
    reload()
  }

  function signOutSupabase() {
    setUser((previousUser) => {
      if (previousUser?.cloudKitUserId) {
        return { cloudKitUserId: previousUser.cloudKitUserId }
      }
      return null
    })
  }

  async function setupCloudKit(userID: string) {
    console.log('setupCloudKit, userID:', userID)

    // If the user logged in with CloudKit and Supabase for teamspaces, he will get signed out automatically out of CloudKit every 24h.
    // We want to keep Supabase signed in in this case unless a different CloudKit user logs in. Then sign out the supabase user.
    if (
      userReference.current?.supabaseUserId &&
      userReference.current?.cloudKitUserId &&
      userReference.current?.cloudKitUserId !== userID
    ) {
      // Log out supabase here if the stored user ID is different or null
      await signOut()
    }

    trackEvent('WEB - CloudKit Login', {
      cloudKitUserId: userID,
    })

    signInWithCloudKit(userID)
    void cloudKitClient.registerForNotifications(
      userReference.current,
      queryClient,
      cachedNotesQueryClient
    )

    // If we are logged into a teamspace, handle that here. Otherwise, if we are logged out of CloudKit, but logged into Supabase, the user will be null
    await handleSupabaseSessionUpdate(await session())

    cloudKitClient.onSignedOut(async () => {
      queryClient.clear()
      cachedNotesQueryClient?.clear()
      accessTokenQueryClient.clear()

      // If we are signed into a teamspace (supabase), we need to logout that too
      if (userReference.current?.supabaseUserId) {
        // Update the meta data since we are logged out now.
        const meta = await getUserMetaData()

        await setUserMetaData({ ...meta, cloudKitUserId: undefined })
        await signOut()
      }

      signOutGoogleMutation()
      signOutCloudKit()
    })
  }

  function handleSupabaseAuthEvent(event: AuthChangeEvent, session: Session) {
    if (event === 'USER_UPDATED') {
      return
    }

    if (event === 'SIGNED_OUT') {
      queryClient.clear()
      cachedNotesQueryClient?.clear()
      accessTokenQueryClient.clear()

      signOutSupabase()

      // If we are not logged into CloudKit log out Google as well
      if (!userReference.current?.cloudKitUserId) {
        signOutGoogleMutation()
      }
      return
    }

    console.log('auth state changed:', event, userReference.current)
    void handleSupabaseSessionUpdate(session)
  }

  async function handleSupabaseSessionUpdate(session: Session) {
    console.log('handleSupabaseSession', session)
    if (session && session.user && session.user.aud === 'authenticated') {
      // If the user is signed into CloudKit, set a meta data value in supabase, so we know this is meant as a teamspace login when CloudKit gets logged out

      // Fetch the meta data of the user
      let meta: UserMetaData = {}
      try {
        meta = await getUserMetaData()
      } catch (error) {
        console.error('Failed to get user meta data:', error)
      }

      // Update the meta data if needed
      if (meta?.cloudKitUserId !== userReference.current?.cloudKitUserId) {
        // Case 1: If the cloudKitUserId is empty but the meta data is not, we need to show the login form
        // Case 2: If the cloudKitUserId is different from the meta data (none is empty) we need to logout the supabase user

        // Case 1
        if (!userReference.current?.cloudKitUserId && meta?.cloudKitUserId) {
          console.log(
            'CloudKit is logged out, but teamspace was logged in, show the login form'
          )
          return
        }

        // Case 2
        if (
          userReference.current?.cloudKitUserId &&
          meta?.cloudKitUserId &&
          userReference.current?.cloudKitUserId !== meta?.cloudKitUserId
        ) {
          console.log(
            'CloudKit ID is different from the last login, logout teamspace'
          )
          signOutGoogleMutation()
          await signOut()
          return
        }

        // Update the meta data, it means we are logged in with CloudKit and a teamspace
        await setUserMetaData({
          ...meta,
          cloudKitUserId: userReference.current?.cloudKitUserId,
        })
      }

      const confirmed_at = new Date(session.user.email_confirmed_at)
      signInWithSupabase(session.user.id, session.user.email, confirmed_at)
      trackEvent('WEB - Supabase Login', {
        supabaseUserId: session.user.id,
        email: session.user.email,
        confirmed_at,
      })

      registerForNotifications(
        session.user.id,
        queryClient,
        cachedNotesQueryClient
      )
      showTeamspaceSignIn(false)
    }
  }

  useEffect(() => {
    cloudKitClient.setUpAuth(
      async (userIdentity: CloudKit.UserIdentity | null) => {
        if (userIdentity) {
          // The user is authenticated
          await setupCloudKit(userIdentity.userRecordName)
        } else {
          // Not authenticated
          cloudKitClient.onSignedIn((userIdentity: CloudKit.UserIdentity) => {
            setupCloudKit(userIdentity.userRecordName)
          })
        }
        setUpAuth(handleSupabaseAuthEvent)
      },
      async (error: CloudKit.CKError | undefined) => {
        trackEvent('WEB - CloudKit Login Error', {
          error,
        })

        // Logout supabase (teamspaces) as well, if we ran into a cloudkit error
        if (userReference.current?.supabaseUserId) {
          await signOut()
        }
      }
    )

    function handleFocus() {
      // Attempt to register to notifications again, in case we lost the connection
      registerForNotifications(
        userReference.current?.supabaseUserId,
        queryClient,
        cachedNotesQueryClient
      )
      cloudKitClient.registerForNotifications(
        userReference.current,
        queryClient,
        cachedNotesQueryClient
      )
    }

    // sometimes we loose the subscription, so we need to re-register
    window.addEventListener('focus', handleFocus)

    // Clean up function to remove the event listener
    return () => {
      window.removeEventListener('focus', handleFocus)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps -- we only run this once on mount
  }, [])

  useEffect(() => {
    if (user === null) {
      showSignInForm()
      return
    }

    hideSignInForm()
  }, [user])

  return (
    <UserStateContext.Provider value={user}>
      <UserDispatchContext.Provider value={setUser}>
        {children}
      </UserDispatchContext.Provider>
    </UserStateContext.Provider>
  )
}
