import { type TeamspaceMember } from '../../hooks/useListTeamspaceMembers'
import { supabase } from '../SupabaseClient'

export async function fetchMembers(
  teamspaceID: string
): Promise<TeamspaceMember[]> {
  if (!teamspaceID) {
    return []
  }

  // First get the shared user ids, and the owner
  const { data: sharedWithData, error: sharedWithError } = await supabase
    .from('notes')
    .select(
      'user_id, shared_with, has_shared_with_admin_role, has_shared_with_guest_role'
    )
    .eq('id', teamspaceID)

  if (sharedWithError) {
    // eslint-disable-next-line no-console
    console.debug(sharedWithError)
    throw new Error(sharedWithError.message)
  }

  // Resolve the shared user ids to user emails using the user_profiles table, which has RLS setup so just admins can lookup the emails
  const ownerID = sharedWithData[0].user_id
  const shared = sharedWithData[0].shared_with ?? []
  const { data: sharedUsers, error } = await supabase
    .from('user_profiles')
    .select('*')
    .in('id', [...shared, ownerID])

  if (error) {
    // eslint-disable-next-line no-console
    console.error(error)
    throw new Error(error.message)
  }

  // Fetch also the invited users to this teamspace
  const { data: invitedUsers, error: invitedUsersError } = await supabase
    .from('invitations')
    .select('invitee_email, role')
    .eq('note_id', teamspaceID)
    .eq('status', 'pending')

  if (invitedUsersError) {
    // eslint-disable-next-line no-console
    console.error(invitedUsersError)
    throw new Error(invitedUsersError.message)
  }

  // Map the sharedUsers to an TemspaceMember array
  const members: TeamspaceMember[] = []
  const admins = sharedWithData[0].has_shared_with_admin_role ?? []
  const guests = sharedWithData[0].has_shared_with_guest_role ?? []
  for (const user of sharedUsers) {
    let role: string
    if (user.id === ownerID) {
      role = 'owner'
    } else if (admins.includes(user.id)) {
      role = 'admin'
    } else if (guests.includes(user.id)) {
      role = 'guest'
    } else {
      role = 'member'
    }
    members.push({ email: user.email, role })
  }

  // Add the invited users
  for (const user of invitedUsers) {
    members.push({
      email: user.invitee_email,
      role: `pending (${user.role})`,
    })
  }

  // Sort owners up
  members.sort((a, b) => {
    if (a.role === 'owner') {
      return -1
    } else if (b.role === 'owner') {
      return 1
    }
    return 0
  })

  return members
}

export async function inviteTeamspaceMember(
  teamspaceID: string,
  email: string
) {
  // We need to make an entry in the table "invitations" with the note_id (i.e. teamspaceID), the invitee_email (email of the invited person)
  // and then we can get the token which can be shared as a link with the other user or send an email from the systen
  const { error } = await supabase
    .from('invitations')
    .insert({
      note_id: teamspaceID.toLowerCase(),
      invitee_email: email.toLowerCase(),
      role: 'guest',
    })
    .single()

  if (error) {
    // eslint-disable-next-line no-console
    console.error(error)
    throw new Error(error.message)
  }

  return true
}

export async function leaveTeamspace(teamspaceID: string) {
  // Call database function 'leaveTeamspace'
  const { error } = await supabase.rpc('leave_teamspace', {
    teamspace_id: teamspaceID,
  })

  if (error) {
    // eslint-disable-next-line no-console
    console.error(error)
    throw new Error(error.message)
  }

  return true
}

export async function removeTeamspaceMember(
  teamspaceID: string,
  email: string
) {
  // This user can be either a shared user or an invited user, so we need to check both tables
  // Additionally, we need to resolve the email to an ID to remove it from the shared_with table

  // First, check if the user is a shared user
  // Look up the shared_with column in the notes table and join it with the user_profiles table to get the email
  const { data: sharedWithData, error: sharedWithError } = await supabase
    .from('notes')
    .select(
      'shared_with, has_shared_with_admin_role, has_shared_with_guest_role'
    )
    .eq('id', teamspaceID)

  if (sharedWithError) {
    // eslint-disable-next-line no-console
    console.error(sharedWithError)
    throw new Error(sharedWithError.message)
  }

  if (sharedWithData.length == 0) {
    throw new Error('The teamspace is not shared with anyone')
  }

  if (sharedWithData[0].shared_with) {
    // Now fetch the email addresses using the user ids in the shared_with
    const { data: sharedUsers, error: sharedUserError } = await supabase
      .from('user_profiles')
      .select('*')
      .in('id', sharedWithData[0].shared_with)

    if (sharedUserError) {
      // eslint-disable-next-line no-console
      console.error(sharedUserError)
      throw new Error(sharedUserError.message)
    }

    // Check if one of the entries in sharedUsers contains the given email
    const removedUser = sharedUsers.find((user) => user.email == email)

    // Check if the user is a shared user
    if (removedUser) {
      // Remove the user from the shared_with list
      const { error: updateError } = await supabase
        .from('notes')
        .update({
          shared_with: sharedWithData[0].shared_with.filter(
            (member: string) => member !== removedUser.id
          ),
          has_shared_with_admin_role:
            sharedWithData[0].has_shared_with_admin_role.filter(
              (member: string) => member !== removedUser.id
            ),
          has_shared_with_guest_role:
            sharedWithData[0].has_shared_with_guest_role.filter(
              (member: string) => member !== removedUser.id
            ),
        })
        .eq('id', teamspaceID)

      if (updateError) {
        // eslint-disable-next-line no-console
        console.error(updateError)
        throw new Error(updateError.message)
      }

      return true
    }
  }

  // If not, check if the user is an invited user
  const { data: invitedUsers, error: invitedUsersError } = await supabase
    .from('invitations')
    .select('id')
    .eq('note_id', teamspaceID)
    .eq('invitee_email', email)

  if (invitedUsersError) {
    // eslint-disable-next-line no-console
    console.error(invitedUsersError)
    throw new Error(invitedUsersError.message)
  }

  // Check if the user is an invited user
  if (invitedUsers.length > 0) {
    // Remove the user from the invited list
    const { error: deleteError } = await supabase
      .from('invitations')
      .delete()
      .eq('id', invitedUsers[0].id)

    if (deleteError) {
      // eslint-disable-next-line no-console
      console.error(deleteError)
      throw new Error(deleteError.message)
    }

    return true
  }

  // If not, throw an error
  throw new Error('User not found')
}

export async function updateTeamspaceUserRole(
  teamspaceID: string,
  email: string,
  role: string
) {
  // 1. Check if the user still has a pending invitation and update the role there instead
  const { data: invitationData, error: invitationError } = await supabase
    .from('invitations')
    .select('id')
    .eq('note_id', teamspaceID)
    .eq('invitee_email', email)
    .eq('status', 'pending')

  if (invitationError) {
    // eslint-disable-next-line no-console
    console.error(invitationError)
    throw new Error(invitationError.message)
  }

  if (invitationData.length > 0) {
    // Update the role in the invitations table
    const { error: updateError } = await supabase
      .from('invitations')
      .update({ role })
      .eq('id', invitationData[0].id)

    if (updateError) {
      // eslint-disable-next-line no-console
      console.error(updateError)
      throw new Error(updateError.message)
    }

    return true
  }

  // 2. The user has already accepted the invitation. Get the id of the user from the user_profiles table
  const { data: user, error: userError } = await supabase
    .from('user_profiles')
    .select('id')
    .eq('email', email)

  if (userError) {
    // eslint-disable-next-line no-console
    console.error(userError)
    throw new Error(userError.message)
  }

  if (user.length == 0) {
    throw new Error('User not found')
  }

  // 3. Get the has_shared_with_admin_role array of user IDs from the note with the id = teamspaceID and add this user to it, if this teamspace is shared with this user
  const { data: sharedWithData, error: sharedWithError } = await supabase
    .from('notes')
    .select(
      'shared_with, has_shared_with_admin_role, has_shared_with_guest_role'
    )
    .eq('id', teamspaceID)

  if (sharedWithError) {
    // eslint-disable-next-line no-console
    console.error(sharedWithError)
    throw new Error(sharedWithError.message)
  }

  // Check if the user[0].id is inside the shared_with array
  if (
    sharedWithData.length == 0 ||
    sharedWithData[0].shared_with.length == 0 ||
    !sharedWithData[0].shared_with.includes(user[0].id)
  ) {
    throw new Error('The teamspace is not shared with this user')
  }
  let hasSharedWithAdminRole =
    sharedWithData[0].has_shared_with_admin_role || []
  let hasSharedWithGuestRole =
    sharedWithData[0].has_shared_with_guest_role || []

  // Now we need to either add the user to the role or remove him
  if (role.toLowerCase() === 'admin') {
    // If the user is an admin, remove him from the guest list
    hasSharedWithGuestRole = hasSharedWithGuestRole.filter(
      (id: string) => id !== user[0].id
    )

    // Check if the user id is not already inside has_shared_with_admin_role, then we don't need to add
    if (!hasSharedWithAdminRole.includes(user[0].id)) {
      hasSharedWithAdminRole.push(user[0].id)
    }
  } else if (role.toLowerCase() === 'guest') {
    // If the user is a guest, remove him from the admin list
    hasSharedWithAdminRole = hasSharedWithAdminRole.filter(
      (id: string) => id !== user[0].id
    )

    if (!hasSharedWithGuestRole.includes(user[0].id)) {
      hasSharedWithGuestRole.push(user[0].id)
    }
  } else if (role.toLowerCase() === 'member') {
    hasSharedWithAdminRole = hasSharedWithAdminRole.filter(
      (id: string) => id !== user[0].id
    )
    hasSharedWithGuestRole = hasSharedWithGuestRole.filter(
      (id: string) => id !== user[0].id
    )
  }

  // 4. Update the has_shared_with_admin_role and has_shared_with_guest_role columns
  const { error: updateError } = await supabase
    .from('notes')
    .update({
      has_shared_with_admin_role: hasSharedWithAdminRole,
      has_shared_with_guest_role: hasSharedWithGuestRole,
    })
    .eq('id', teamspaceID)

  if (updateError) {
    // eslint-disable-next-line no-console
    console.error(updateError)
    throw new Error(updateError.message)
  }

  return true
}

export async function acceptInvitation(teamspaceID: string) {
  // Call the stored procedure to accept the invitation (the function checks also if an invitation exist at all)
  const { error } = await supabase.rpc('accept_invitation', {
    teamspace_id: teamspaceID,
  })

  if (error) {
    // eslint-disable-next-line no-console
    console.error(error)
    throw new Error(error.message)
  }

  return true
}
