import { CalendarEvent } from '../../CalendarEvent'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { minBy } from 'lodash' // TODO: utilize useHooks or use single lodash modules to take advantage of tree shaking
import { NumberSize } from 're-resizable'
import { Direction } from 're-resizable/lib/resizer'
import { updateContentNodesAndBlock } from './updateContent'

export function handleResizeStop(event: CalendarEvent, segmentHeight: number, delta: NumberSize, direction: Direction) {
  const segmentDuration = segmentHeight / 15
  const deltaDuration = delta.height / segmentDuration

  let newStartDate = event.startDate
  let newEndDate = event.endDate

  if (direction == 'top') {
    newStartDate = event.startDate.add(-deltaDuration, 'minute')
    newStartDate = newStartDate.minute(minBy([0, 15, 30, 45, 60], (s) => Math.abs(newStartDate.minute() - s)))
  } else {
    newEndDate = event.endDate.add(deltaDuration, 'minute')
    newEndDate = newEndDate.minute(minBy([0, 15, 30, 45, 60], (s) => Math.abs(newEndDate.minute() - s)))
  }
  updateContentNodesAndBlock(event.id, event, newStartDate, newEndDate)
}
