import { Plugin } from "prosemirror-state";
import styles from "../../Block.module.css";

export const FoldMarkerHandleClickPlugin = () => {
  return new Plugin({
    props: {
      handleClickOn: (view, _pos, _node, nodePos, event) => {
        // If the user clicks on the fold marker, set folded to false.
        if (
          (event.target as HTMLElement).classList.contains(styles.foldMarker)
        ) {
          view.dispatch(
            // Set folded to false, while keeping other attributes the same.
            view.state.tr.setNodeMarkup(nodePos, undefined, {
              ...view.state.doc.nodeAt(nodePos)!.attrs,
              folded: false,
            })
          );
        }
      },
    },
  });
};
