import type React from 'react'
import { useCallback, useEffect, useMemo, useState } from 'react'
import classnames from 'classnames'
import dayjs, { type Dayjs } from 'dayjs'
import '@szhsin/react-menu/dist/core.css'
import {
  BlockNoteEditor,
  type DefaultBlockSchema,
  type Block,
} from '@packages/blocknote-core'
import { useSaveNote } from '../../hooks/useSaveNote'
import useNote, { type useNoteConfig } from '../../hooks/useNote'
import { type Note, NoteType } from '../../utils/syncUtils'
import {
  type SelectedDate,
  isSameDay,
  isSameWeek,
  selectedDateToDay,
  selectedDateToKey,
  weekToDate,
} from '../../providers/SelectedDateProvider'
import { useUserState } from '../../providers/UserProvider'
import { useSidebarProvider } from '../../providers/SidebarProvider'
import useBulletAndTaskItemCharacters from '../../hooks/useBulletAndTaskItemCharacters'
import { useNoteReferences } from '../../hooks/useNoteReferences'
import { type WindowWithEditor } from '../../utils/WindowWithEditor'
import { eachDayOfMonth, eachWeekOfMonth } from './eachDayOfX'
import { WeekDays } from './WeekDays'
import { CalendarMenu } from './CalendarMenu'

function monthToDate({ month, year }: { month: number; year: number }): Dayjs {
  return dayjs().year(year).month(month)
}

function isSameMonth(
  { month, year }: { month: number; year: number },
  date: Dayjs
): boolean {
  return date.month() === month && date.year() === year
}

export type CalendarProps = {
  selectedDate: SelectedDate
  onToday: () => void
  onChangeWeek: (_week: { week: number; year: number }) => void
  onChangeDay: (_day: Dayjs) => void
  timelineDays: number
  // onSetTimelineDays: (_days: number) => void;
}
export function Calendar({
  selectedDate,
  onToday,
  onChangeWeek,
  onChangeDay,
  timelineDays,
}: // onSetTimelineDays,
CalendarProps) {
  const user = useUserState()
  const selectedDay = selectedDateToDay(selectedDate)
  const [currentMonth, setCurrentMonth] = useState({
    month: selectedDay.month(),
    year: selectedDay.year(),
  })
  const days = useMemo(
    () => eachDayOfMonth(currentMonth.month, currentMonth.year),
    [currentMonth]
  )
  const weeks = useMemo(
    () => eachWeekOfMonth(currentMonth.month, currentMonth.year),
    [currentMonth]
  )

  const {
    privateCalendarNotesMap,
    privateNotesMap,
    // teamNotesMap,
    // teamCalendarNotesMap,
  } = useSidebarProvider()

  const { data: privateReferences } = useNoteReferences(
    'private',
    privateNotesMap
  )

  // TODO: Include teamspace calendar notes
  const { taskItemCharacters } = useBulletAndTaskItemCharacters()

  // Query references and notes if they have open tasks for the days in the list
  const hasTasks = useMemo(() => {
    return days.map((day) => {
      const dayKey = day.format('YYYYMMDD')
      let note: Note | undefined
      if (privateCalendarNotesMap) {
        for (const noteItem of privateCalendarNotesMap.values()) {
          if (noteItem.title === dayKey) {
            note = noteItem
            break
          }
        }

        if (privateReferences) {
          const incomingReferences = privateReferences.get(
            day.format('YYYY-MM-DD')
          )?.incoming
          if (incomingReferences) {
            for (const fromNote of incomingReferences.values()) {
              for (const block of fromNote.blocks) {
                if (block.type === 'taskListItem') {
                  return true
                }
              }
            }
          }
        }
      }

      const char = taskItemCharacters.join('|')
      return note
        ? new RegExp(
            `^(\\s*[${char}] (?!.*\\[x\\]|\\[-\\]|\\[>\\]))`,
            'gm'
          ).test(note.content)
        : false
    })
  }, [days, privateCalendarNotesMap, privateReferences, taskItemCharacters])

  function handlePreviousMonth() {
    const newDate = dayjs()
      .year(currentMonth.year)
      .month(currentMonth.month)
      .subtract(1, 'month')
    setCurrentMonth(() => ({ month: newDate.month(), year: newDate.year() }))
  }

  function handleNextMonth() {
    const newDate = dayjs()
      .year(currentMonth.year)
      .month(currentMonth.month)
      .add(1, 'month')
    setCurrentMonth(() => ({ month: newDate.month(), year: newDate.year() }))
  }

  // jump to month if selectedDate changed
  useEffect(() => {
    if (!isSameMonth(currentMonth, selectedDay)) {
      setCurrentMonth(() => ({
        month: selectedDay.month(),
        year: selectedDay.year(),
      }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDay]) // Don't add the dependency on currentMonth, otherwise it will not switch to the next month clicking the arrow buttons

  // #region <drop>
  const [dropDate, setDropDate] = useState<SelectedDate | null>(null)
  const noteKey = useCallback((): useNoteConfig | null => {
    if (!dropDate) {
      return null
    }

    return {
      noteType: selectedDate.teamspace
        ? NoteType.TEAM_SPACE_CALENDAR_NOTE
        : NoteType.CALENDAR_NOTE,
      recordName: null,
      filename: selectedDateToKey(dropDate),
      parent: selectedDate.teamspace,
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dropDate])

  const { data: dropNote } = useNote(noteKey())
  const [dropText, setDropText] = useState<string | null>(null)
  const saveDropNote = useSaveNote(onSaveSucccess)

  function onSaveSucccess() {
    // remove dragged item

    const editor = (window as WindowWithEditor).editor
    editor?._tiptapEditor.chain().focus().deleteSelection().run()
  }

  // prepend task on drop
  useEffect(() => {
    if (dropNote && dropText && dropNote.recordName) {
      saveDropNote.mutate({
        filename: dropNote.filename ?? '',
        recordName: dropNote.recordName ?? '',
        content: `${dropText}\n${dropNote.content ?? ''}`,
        attachments:
          JSON.parse(
            dropNote.attachments && dropNote.attachments.length > 0
              ? dropNote.attachments
              : '[]'
          ) ?? [],
        noteType: dropNote.noteType,
        parent: dropNote.parent,
        modificationDate: new Date(),
      })
      setDropDate(null)
      setDropText(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dropNote, dropText])

  // The user can drop a task into a day or week and we need to remove it from the current editor and prepend it to the target note.
  function handleTaskDrop(e: React.DragEvent<HTMLButtonElement>) {
    // Get block text, which the user is dragging currently, so we know what to prepend to the target note.
    // example: <div data-id=\"b0ad8da5-e879-4a5a-87e9-a0b6a46f1e18\" class=\"_blockOuter_164rt_26\" data-node-type=\"block-outer\" data-pm-slice=\"0 0 []\"><div data-id=\"b0ad8da5-e879-4a5a-87e9-a0b6a46f1e18\" class=\"_block_164rt_26\" data-node-type=\"blockContainer\"><div class=\"_blockContent_164rt_44\" data-content-type=\"taskListItem\"><label><input type=\"checkbox\"><span></span></label><div data-flagged=\"0\" data-checked=\"false\" data-cancelled=\"false\" data-scheduled=\"false\" class=\"_inlineContent_164rt_427\">test</div></div></div></div>
    const html = e.dataTransfer.getData('text/html')
    const element = document.createElement('div')
    element.innerHTML = html

    // Loop through each child element and get the block id, then get the text of the block and it.
    // We have to loop in case the user has multiple lines selected and dragged them.
    const lines: string[] = []
    for (let i = 0; i < element.childElementCount; i++) {
      const child = element.children[i]
      const blockId: string | null = (child as HTMLElement).getAttribute(
        'data-id'
      )

      if (blockId && blockId.length > 0) {
        const block: Block<DefaultBlockSchema> | undefined = (
          window as WindowWithEditor
        ).editor?.getBlock(blockId)
        if (block) {
          // TODO: Missing is copying the attachments to the target note.
          // If there's no teamspace set and we are logged into CloudKit, then we need to use the legacy attachment format.
          // Because we need isLegacyAttachment = true only for CloudKit notes and those never have a teamspace set.
          const isCloudKitSignedIn = Boolean(user.cloudKitUserId)
          const isCloudKitNote = !selectedDate.teamspace && isCloudKitSignedIn
          lines.push(BlockNoteEditor.blocksToNotePlan([block], isCloudKitNote))
        }
      }
    }

    // The text that will be prepended to the target note.
    setDropText(lines.join('\n'))
  }
  // #endregion

  return (
    <div id='calendar-view' style={{ width: 250 + timelineDays * 50 }}>
      <div className='text-center lg:col-start-8 lg:col-end-13 lg:row-start-1 xl:col-start-9'>
        <div className='flex items-center justify-between space-x-2 pl-2 pt-2.5 text-gray-900'>
          <div className='flex flex-auto'>
            <button
              type='button'
              data-tooltip-id='my-tooltip'
              data-tooltip-content='Open today (⌃T)'
              className='flex flex-none text-left text-lg text-black hover:bg-gray-500/25 dark:text-white'
              onClick={onToday}
            >
              {monthToDate(currentMonth).format('MMMM YYYY')}
            </button>
          </div>

          <CalendarMenu />
          {/* <SettingsMenu timelineDays={timelineDays} onSetTimelineDays={onSetTimelineDays} /> */}
          <button
            type='button'
            className='flex flex-none items-center justify-center p-1 hover:bg-gray-500/25'
            onClick={handlePreviousMonth}
          >
            <span className='sr-only'>Previous month</span>
            <i
              className='fa-solid fa-arrow-left h-5 w-5 text-sm'
              aria-hidden='true'
            />
          </button>
          <button
            type='button'
            className='flex flex-none items-center justify-center p-1 hover:bg-gray-500/25'
            onClick={handleNextMonth}
          >
            <span className='sr-only'>Next month</span>
            <i
              className='fa-solid fa-arrow-right h-5 w-5 text-sm'
              aria-hidden='true'
            />
          </button>
        </div>

        <div className='ml-2 mt-2 flex'>
          <div className='calendar-weeks flex flex-col'>
            <div className='mb-1 text-xs text-orange-500'>CW</div>
            {weeks.map((week) => (
              <button
                key={`calendar-week-number-${week.week}`}
                type='button'
                onClick={() => {
                  onChangeWeek(week)
                }}
                onDrop={(e) => {
                  e.preventDefault()
                  e.stopPropagation()
                  if (!isSameWeek(selectedDate, week)) {
                    // remove highlights
                    e.currentTarget.classList.remove(
                      'bg-orange-200/40',
                      'border-orange-400'
                    )
                    e.currentTarget.classList.add('border-transparent')
                    // fetch note
                    setDropDate({
                      ...week,
                      active: 'week',
                      date: weekToDate(week),
                    })
                    handleTaskDrop(e)
                  }
                }}
                onDragEnd={(e) => {
                  e.preventDefault()
                  e.stopPropagation()
                  if (!isSameWeek(selectedDate, week)) {
                    // remove highlights
                    e.currentTarget.classList.remove(
                      'bg-orange-200/40',
                      'border-orange-400'
                    )
                    e.currentTarget.classList.add('border-transparent')
                  }
                }}
                onDragOver={(e) => {
                  e.preventDefault()
                  e.stopPropagation()

                  if (!isSameWeek(selectedDate, week)) {
                    // add highlights
                    e.currentTarget.classList.add(
                      'bg-orange-200/40',
                      'border-orange-400'
                    )
                    e.currentTarget.classList.remove('border-transparent')
                  }
                }}
                onDragLeave={(e) => {
                  e.preventDefault()
                  e.stopPropagation()
                  if (!isSameWeek(selectedDate, week)) {
                    // remove highlights
                    e.currentTarget.classList.remove(
                      'bg-orange-200/40',
                      'border-orange-400'
                    )
                    e.currentTarget.classList.add('border-transparent')
                  }
                }}
                onDragEnter={(e) => {
                  e.preventDefault()
                  e.stopPropagation()
                }}
                className={classnames(
                  'leading-tight text-orange-500 text-xs bg-transparent hover:bg-zinc-100 dark:hover:bg-zinc-700 focus:z-10 rounded-none m-[0.053rem] pt-[0.32em] pb-[0.48em] pl-[0.49em] pr-[0.49em] border-transparent',
                  {
                    'font-semibold text-gray-600 !bg-blue-300/30':
                      selectedDate.active === 'week' &&
                      isSameWeek(selectedDate, week),
                  }
                )}
              >
                <div className='flex flex-col items-center space-y-0'>
                  <span className='mb-2'>{week.week}</span>
                </div>
              </button>
            ))}
          </div>

          <div className='calendar-days flex-auto'>
            <WeekDays />

            <div className='isolate mt-1 grid w-full grid-cols-7 bg-transparent text-sm'>
              {days.map((day, dayIdx) => (
                <button
                  id={`${dayIdx}: ${isSameDay(selectedDate, day)}`}
                  key={`calendar-day-view-${day}-${dayIdx}`}
                  type='button'
                  onClick={() => {
                    onChangeDay(day)
                  }}
                  className={classnames(
                    'leading-tight hover:bg-zinc-100 dark:hover:bg-zinc-700 focus:z-10 rounded-none m-0 m-[0.05rem] border-2 border-inset border-transparent',
                    {
                      'font-semibold':
                        selectedDate.active === 'day' &&
                        (isSameDay(selectedDate, day) ||
                          day.isSame(dayjs(), 'day')),
                      'text-gray-900 dark:text-gray-50':
                        !isSameDay(selectedDate, day) &&
                        isSameMonth(currentMonth, day) &&
                        !day.isSame(dayjs(), 'day'),
                      'text-gray-400':
                        !isSameDay(selectedDate, day) &&
                        !isSameMonth(currentMonth, day) &&
                        !day.isSame(dayjs(), 'day'),
                      'text-blue-400': day.isSame(dayjs(), 'day'),
                      'bg-red-300/25':
                        !isSameDay(selectedDate, day) &&
                        hasTasks[dayIdx] &&
                        day.isBefore(dayjs().startOf('day')),
                      'bg-blue-300/30':
                        selectedDate.active === 'day' &&
                        isSameDay(selectedDate, day),
                    },
                    dayIdx === 0 &&
                      day.weekday() > 0 &&
                      `col-start-${day.weekday()}`
                  )}
                  onDrop={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    if (!isSameDay(selectedDate, day)) {
                      // remove highlights
                      e.currentTarget.classList.remove(
                        'bg-orange-200/40',
                        'border-orange-400'
                      )
                      e.currentTarget.classList.add('border-transparent')
                      // fetch note
                      setDropDate({
                        active: 'day',
                        date: day,
                        week: day.week(),
                        year: day.year(),
                      })
                      handleTaskDrop(e)
                    }
                  }}
                  onDragEnd={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    if (!isSameDay(selectedDate, day)) {
                      // remove highlights
                      e.currentTarget.classList.remove(
                        'bg-orange-200/40',
                        'border-orange-400'
                      )
                      e.currentTarget.classList.add('border-transparent')
                    }
                  }}
                  onDragOver={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    if (!isSameDay(selectedDate, day)) {
                      // add highlights
                      e.currentTarget.classList.add(
                        'bg-orange-200/40',
                        'border-orange-400'
                      )
                      e.currentTarget.classList.remove('border-transparent')
                    }
                  }}
                  onDragLeave={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    if (!isSameDay(selectedDate, day)) {
                      // remove highlights
                      e.currentTarget.classList.remove(
                        'bg-orange-200/40',
                        'border-orange-400'
                      )
                      e.currentTarget.classList.add('border-transparent')
                    }
                  }}
                  onDragEnter={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                  }}
                >
                  <div className='flex flex-col items-center space-y-0'>
                    <time
                      dateTime={day.format('yyyy-MM-DD')}
                      className={classnames(' text-center')}
                    >
                      {day.date()}
                    </time>
                    <svg
                      className={`stroke-current text-orange-500 ${
                        hasTasks[dayIdx] ? 'opacity-100' : 'opacity-0'
                      }`}
                      xmlns='http://www.w3.org/2000/svg'
                      viewBox='0 0 8 8'
                      fill='none'
                      strokeWidth='1'
                      width='8'
                      height='8'
                    >
                      <circle cx='4' cy='4' r='2.3' />
                    </svg>
                  </div>
                </button>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
