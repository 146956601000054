import { Note, NoteType, SourceDatabase } from '../../utils/syncUtils'
import { supabase } from '../SupabaseClient'
import { loadNoteFrom, loadNotesFrom } from './NoteUtils'
import { SupabaseNote } from './types'

export async function fetchNoteById(currentUserId: string, id: string) {
  const { data, error } = await supabase.from('notes').select('*').eq('id', id).limit(1)

  if (error) {
    // eslint-disable-next-line no-console
    console.error(error)
    throw error
  }

  if (data && data.length > 0) {
    return await loadNoteFrom(currentUserId, data[0])
  } else {
    throw new Error('Note not found')
  }
}

export async function fetchNoteByFilename(
  currentUserId: string,
  filename: string,
  noteType: NoteType = NoteType.CALENDAR_NOTE,
  parent: string | null = null
  /*fetchEncryptedContent = false*/
) {
  // NOTE: To fetch decrypted content, use the `decrypted_notes` table instead of `notes`
  let query = supabase
    // Note: use the view `readable_notes`, because it catches the empty string decryption issue by checking before decrypting.
    // If we update the RLS policies of the notes table, we need to update the view `readable_notes` where condition.
    // .from(fetchEncryptedContent ? 'notes' : 'readable_notes') //'decrypted_notes')
    .from('notes')
    .select('*')
    .eq('note_type', noteType)
    .eq('filename', filename)

  if (noteType === NoteType.TEAM_SPACE_CALENDAR_NOTE && parent != null) {
    query = query.eq('parent', parent)
  }

  const { data, error } = await query.limit(1)

  if (error) {
    // eslint-disable-next-line no-console
    console.error(error)
    throw error

    // If we get a code 22000, the note has empty content and this couldn't be decrypted properly, so load all the metadata again, but set content to empty
    // if (error.code !== '22000') {
    //   throw error;
    // } else {
    //   console.log("the content couldn't be decrypted, load the original and set content to nothing", data);

    //   // To fix this, we need to load the note from the notes table, which has the encrypted content, but it's anyways probably empty, therefore the error
    //   const note = await this.fetchNoteByFilename(currentUserId, filename, noteType, parent, true);
    //   note.content = '';
    //   return note;
    // }
  }

  if (data && data.length > 0) {
    return await loadNoteFrom(currentUserId, data[0])
  }

  const emptyNote: Note = {
    content: '',
    noteType: noteType,
    filename: filename,
    parent: parent,
    isEmpty: true,
    source: SourceDatabase.SUPABASE,
  }

  return emptyNote
}

// This is just fetching the ids fast, so that we know if the user has access to a specific teamspace or not after he was removed
export async function listMyTeamspaceNotes(): Promise<string[]> {
  const pageSize = 1000
  let pageIndex = 0
  let hasMoreData = true
  let allNoteIds: string[] = []

  while (hasMoreData) {
    const start = pageIndex * pageSize
    const end = start + pageSize - 1

    const { data, error } = await supabase
      .from('notes')
      .select('id')
      .in('note_type', [
        NoteType.TEAM_SPACE,
        NoteType.TEAM_SPACE_NOTE,
        NoteType.TEAM_SPACE_CALENDAR_NOTE,
      ])
      .range(start, end)

    if (error) {
      // eslint-disable-next-line no-console
      console.error(error)
      throw error
    }

    if (!data || data.length < pageSize) {
      hasMoreData = false // No more data to fetch
    }

    allNoteIds = allNoteIds.concat(data.map((note) => note.id))
    pageIndex++ // Increment page index for the next iteration
  }

  return allNoteIds
}

export async function fetchPrivateNotes(
  currentUserId: string,
  noteType: 'project' | 'calendar'
): Promise<Map<string, Note>> {
  const pageSize = 1000
  let pageIndex = 0
  let hasMoreData = true
  const noteTypeFilter =
    noteType === 'project'
      ? [NoteType.PROJECT_NOTE, NoteType.ASSET_PROJECT_NOTE]
      : [NoteType.CALENDAR_NOTE, NoteType.ASSET_CALENDAR_NOTE]

  // const startTime = performance.now()
  // console.log('[Supabase] FETCHING NOTES STARTED')

  let notes: SupabaseNote[] = []
  while (hasMoreData) {
    const start = pageIndex * pageSize
    const end = start + pageSize - 1

    const { data, error } = await supabase
      .from('notes') //'decrypted_notes')
      .select('*')
      .in('note_type', noteTypeFilter)
      .range(start, end)

    if (error) {
      // eslint-disable-next-line no-console
      console.error(error)
      throw error
    }

    if (!data || data.length < pageSize) {
      hasMoreData = false // No more data to fetch
    }

    // eslint-disable-next-line no-console
    console.debug(`[Supabase] FETCHED PAGE ${pageIndex} OF NOTES`, data)
    notes = notes.concat(data)

    pageIndex++ // Increment page index for the next iteration
  }

  // const endTime = performance.now()
  // console.log(`[Supabase] FETCHED NOTES in ${(endTime - startTime).toFixed(2)}ms`, notes)
  return await loadNotesFrom(currentUserId, notes)
}

// Team spaces are folders with the type = 10, load them into another part of the sidebar. They might be shared with me or I might be the owner
export async function fetchTeamNotes(
  currentUserId: string,
  noteType: 'project' | 'calendar'
): Promise<Map<string, Note>> {
  const pageSize = 1000
  let pageIndex = 0
  let hasMoreData = true
  let notes: SupabaseNote[] = []
  const noteTypeFilter =
    noteType === 'project'
      ? [NoteType.TEAM_SPACE_NOTE, NoteType.TEAM_SPACE]
      : [NoteType.TEAM_SPACE_CALENDAR_NOTE]

  while (hasMoreData) {
    const start = pageIndex * pageSize
    const end = start + pageSize - 1

    const { data, error } = await supabase
      // .from('readable_notes')
      .from('notes')
      .select('*')
      .in('note_type', noteTypeFilter)
      .range(start, end)

    if (error) {
      // eslint-disable-next-line no-console
      console.error(error)
      throw error
    }

    if (!data || data.length < pageSize) {
      hasMoreData = false // No more data to fetch
    }

    notes = notes.concat(data)
    pageIndex++ // Increment page index for the next iteration
  }

  return loadNotesFrom(currentUserId, notes)
}

export async function hasPrivateNotes(currentUserId: string): Promise<boolean> {
  const { data, error } = await supabase
    .from('notes')
    .select('id')
    // .in('note_type', [NoteType.PROJECT_NOTE, NoteType.ASSET_PROJECT_NOTE]) // Doesn't matter what note
    .eq('user_id', currentUserId)
    .limit(1)

  if (error) {
    // eslint-disable-next-line no-console
    console.error('Error fetching private notes:', error)
    throw error
  }

  // eslint-disable-next-line no-console
  console.debug('Has private notes:', data && data.length > 0)
  return data && data.length > 0
}
