/**
 * Darkens a given hex color by a specified amount.
 *
 * This function takes a hex color string and an amount by which to darken the color.
 * It returns the darkened color as a hex string.
 *
 * @param color - The hex color string to be darkened. It can start with or without a '#'.
 * @param amount - The amount by which to darken the color. This value is subtracted from each RGB component.
 * @returns - The darkened hex color string. The returned string will include a '#' if the input color included it.
 *
 * @example
 * // Darken the color '#ff0000' by 20 units
 * const darkenedColor = darkenColor('#ff0000', 20);
 * console.log(darkenedColor); // Outputs: '#e60000'
 */
export function darkenColor(color: string, amount: number): string {
  const usePound = color.startsWith('#')
  const num = parseInt(color.slice(1), 16)

  let r = Math.floor(num / (256 * 256)) - amount
  let g = Math.floor((num / 256) % 256) - amount
  let b = (num % 256) - amount

  r = r < 0 ? 0 : r
  g = g < 0 ? 0 : g
  b = b < 0 ? 0 : b

  const rHex = r.toString(16).padStart(2, '0')
  const gHex = g.toString(16).padStart(2, '0')
  const bHex = b.toString(16).padStart(2, '0')

  return (usePound ? '#' : '') + rHex + gHex + bHex
}

/**
 * Adds alpha to a given hex color.
 *
 * This function takes a hex color string and an alpha value to be added to the color.
 * It returns the color as a hex string with alpha.
 *
 * @param color - The hex color string to which alpha is to be added. It can start with or without a '#'.
 * @param alpha - The alpha value to be added to the color. This value is a decimal between 0 and 1.
 * @returns - The hex color string with alpha. The returned string will include a '#' if the input color included it.
 *
 * @example
 * // Add alpha 0.5 to the color '#ff0000'
 * const colorWithAlpha = addAlphaToColor('#ff0000', 0.5);
 * console.log(colorWithAlpha); // Outputs: '#ff000080'
 */
export function addAlphaToColor(
  color: string | undefined,
  alpha: number | undefined
): string | undefined {
  if (color === undefined || alpha === undefined) {
    return undefined
  }

  const usePound = color.startsWith('#')
  const hex = usePound ? color.slice(1) : color

  // Check if the color already has an alpha component
  if (hex.length === 8) {
    // Replace the existing alpha component
    const newHex = hex.slice(0, 6)
    const a = Math.round(alpha * 255)
    const aHex = a.toString(16).padStart(2, '0')
    return (usePound ? '#' : '') + newHex + aHex
  }

  const num = parseInt(hex, 16)

  const r = Math.floor(num / (256 * 256))
  const g = Math.floor((num / 256) % 256)
  const b = num % 256

  const a = Math.round(alpha * 255)

  const rHex = r.toString(16).padStart(2, '0')
  const gHex = g.toString(16).padStart(2, '0')
  const bHex = b.toString(16).padStart(2, '0')
  const aHex = a.toString(16).padStart(2, '0')

  return (usePound ? '#' : '') + rHex + gHex + bHex + aHex
}
