import { createContext, useState, useContext, useCallback } from 'react'
import { Notification, NotificationType } from '../components/Notification'

type NotificationContextType = {
  showNotification: (_notification: NotificationType) => void
  hideNotification: () => void
}

const NotificationContext = createContext<NotificationContextType | undefined>(
  undefined
)

type NotificationProviderProps = {
  children: React.JSX.Element[]
}

export const NotificationProvider = ({
  children,
}: NotificationProviderProps) => {
  const [notification, setNotification] = useState<
    NotificationType | undefined
  >(undefined)

  const showNotification = useCallback((notification: NotificationType) => {
    setNotification(notification)
  }, [])

  const hideNotification = useCallback(() => {
    setNotification(undefined)
  }, [])

  return (
    <NotificationContext.Provider
      value={{ showNotification, hideNotification }}
    >
      {children}
      <Notification notification={notification} onClose={hideNotification} />
    </NotificationContext.Provider>
  )
}

export const useNotification = () => {
  const context = useContext(NotificationContext)
  if (context === undefined) {
    throw new Error(
      'useNotification must be used within a NotificationProvider'
    )
  }
  return context
}
