import { useEffect, useRef, useState } from 'react'
import {
  getUserMetaData,
  setUserMetaData,
  signInWithEmailOTP,
  signOut,
  verifyOTP,
} from '../../lib/supabase/auth'
import { trackEvent } from '../../lib/analytics'
import SlideOver from './SlideOver'

function NotePlanIcon({ type = 'icon' }) {
  return (
    <div className='sm:mx-auto sm:w-full sm:max-w-md'>
      <img
        className={`
          ${
            type === 'icon'
              ? 'mx-auto h-24 w-auto drop-shadow-md'
              : 'mx-auto w-32'
          }
        `}
        src={type === 'icon' ? 'icon-mac.png' : 'full_logo.png'}
        alt='NotePlan'
      />
    </div>
  )
}

type LoginCardProps = {
  heading: string
  description: string
  src: string
  click: () => void
  allowDarkMode?: boolean | undefined
}

function LoginCard({
  heading,
  description,
  src,
  click,
  allowDarkMode,
}: LoginCardProps) {
  return (
    <div
      className='flex w-full cursor-pointer flex-row items-center justify-between gap-3 rounded-md border border-stone-200 dark:border-stone-600 p-5 dark:bg-stone-900'
      onClick={click}
      role='button'
      tabIndex={0}
      onKeyDown={(e) => {
        if (e.key === 'Enter' || e.key === ' ') {
          click()
        }
      }}
    >
      <div className='flex items-center gap-3'>
        <img
          className={`mb-0 h-6 w-6 ${allowDarkMode !== false ? 'dark:filter dark:invert' : ''}`}
          src={src}
          alt='NotePlan'
        />
        <div className=''>
          <p className='mb-0 text-lg text-black dark:text-white'>{heading}</p>
          <p className='mb-0 text-xs text-gray-500 dark:text-gray-300'>
            {description}
          </p>
        </div>
      </div>
      <div>
        <i className='fa fa-chevron-right text-gray-300' />
      </div>
    </div>
  )
}

type HeaderProps = {
  heading: string
  description: string
  info?: React.ReactNode
}

function Header({ heading, description, info = null }: HeaderProps) {
  return (
    <div className='mt-20'>
      <p className='mt-5 text-center text-3xl font-bold text-black dark:text-gray-100'>
        {heading}
      </p>
      <p className='mt-3 text-center text-sm dark:text-gray-200'>
        {description}
      </p>
      {info ? info : null}
    </div>
  )
}

function EmailOTPSignIn({
  isTeamspace,
  id,
}: {
  isTeamspace: boolean
  id: string
}) {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [email, setEmail] = useState<string | null>(null)
  const [token, setToken] = useState<string | null>(null)
  const [hasError, setHasError] = useState<boolean>(false)

  // const [password, setPassword] = useState(null);
  const buttonRef = useRef<HTMLButtonElement>(null)

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value)
  }

  // const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setPassword(event.target.value as unknown as null);
  // };

  const handleTokenChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setToken(event.target.value)
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    // Stop event from propagating, or CMD+A will select the text of the editor behind it
    event.stopPropagation()

    if (event.key === 'Enter') {
      buttonRef.current?.click()
    }
  }

  const handleExistingSignIn = async () => {
    try {
      // We need to check if supabase is already logged (from a previous teamspace login) it or has the meta field set if this is not a teamspace login, then logout and reset the meta field
      if (!isTeamspace) {
        // console.debug(
        //   '[Sign In/Out] Already signed-in, check meta and sign out'
        // )
        const meta = await getUserMetaData()

        if (meta.cloudKitUserId) {
          // console.debug('[Sign In/Out] meta data found, resetting...', meta)
          await setUserMetaData({ ...meta, cloudKitUserId: undefined })
        }

        await signOut()
      }
    } catch (error) {
      // console.error('Error while checking if already signed-in', error)
    }
  }

  const handleSignIn = async () => {
    // NOTICE: To set and use passwords after logging in with the magic link (after verifyOTP is called)
    // use supabase.updatePassword(password) to set the password. After that, the user can login with the password.
    // (to enable the password login flow, just uncomment the password field in the returned html)
    // To make the sign in flow work properly, we need to detect if it's an existing user after the user enters the
    // email address and hits continue. If it's an existing user, we can ask to set a password.
    // If it's a new user, we can send a magic link / OTP and ask for the password after that.
    // Or if it's a new user, let him set the password right away and confirm the email address.
    // Notion sends an OTP and asks for the password after that, Craft works only with OTP and magic links.

    // if (password && email) {
    // const success = await supabase.signInWithEmailPassword(email, password);
    // console.log('email and password success', success);
    // } else

    setHasError(false)

    if (email && token) {
      setIsLoading(true)
      const success = await verifyOTP(email, token)

      if (success) {
        trackEvent('WEB - Supabase Token Login Successful')
        document
          .getElementById(`sign-up-token-container${id}`)
          ?.classList.add('hidden')
        setToken(null) // Reset here so we can do it again
        setEmail(null)
        ;(document.getElementById(`email${id}`) as HTMLInputElement).value = ''
        ;(document.getElementById(`token${id}`) as HTMLInputElement).value = ''
      } else {
        setHasError(true)
      }

      setIsLoading(false)
    } else if (email) {
      setIsLoading(true)

      await handleExistingSignIn()

      const success = await signInWithEmailOTP(email)
      if (success) {
        trackEvent('WEB - Entered Email Supabase Login')
        document
          .getElementById(`sign-up-token-container${id}`)
          ?.classList.remove('hidden')
        // Set focus on the token input field
        document.getElementById(`token${id}`)?.focus()
      } else {
        setHasError(true)
      }
      setIsLoading(false)
    }
  }

  return (
    <div className='mx-auto max-w-[380px] sm:w-full'>
      <div className='pb-6 sm:rounded-lg'>
        {hasError ? (
          <div className='relative mb-4 flex flex-col rounded-md border border-red-200 bg-red-100 p-4 text-sm leading-6 dark:border-red-600 dark:bg-red-600/30'>
            <div className='flex items-center space-x-2'>
              <i className='far fa-triangle-exclamation -mt-2 text-lg text-red-600 dark:text-red-300' />
              <p className='text-sm font-bold text-gray-600 dark:text-gray-300'>
                Ooops, something went wrong!
              </p>
            </div>
            <span className='mt-0 pt-0 text-left text-sm text-gray-600 dark:text-gray-300'>
              Please reload the page and try again in 60 seconds. If this
              doesn&apos;t work, reach out to hello@noteplan.co
            </span>
          </div>
        ) : null}
        {!hasError && isTeamspace ? (
          <div className='relative mb-4 flex flex-col rounded-md border border-gray-200 bg-gray-100 p-4 text-sm leading-6 dark:border-gray-600 dark:bg-gray-600/30'>
            <div className='flex items-center space-x-2'>
              <i className='far fa-info-circle text-lg text-gray-600 dark:text-gray-300' />
              <p className='text-sm font-bold text-gray-600 dark:text-gray-300'>
                Data won&apos;t be stored on iCloud (CloudKit)
              </p>
            </div>
            <span className='mt-0 pt-0 text-left text-sm text-gray-600 dark:text-gray-300'>
              <span>
                Notes in a teamspace are stored on a non-CloudKit database
                (still encrypted) and therefore needs a separate login. This
                makes sharing more flexible.
              </span>
              {/* <span>
                  To sync with the native apps, use &apos;Sign in with Apple ID&apos; above. The
                  email sign-in won&apos;t access iCloud.
                </span> */}
            </span>
          </div>
        ) : null}

        <div className='space-y-3'>
          <div>
            {isTeamspace ? (
              <div className='text-left'>
                <span className='text-xs font-bold uppercase text-gray-600 dark:text-gray-300'>
                  Teamspace Login:
                </span>
              </div>
            ) : null}

            <div className='mt-2'>
              <input
                id={`email${id}`}
                name='email'
                type='email'
                onChange={handleEmailChange}
                onKeyDown={handleKeyDown}
                autoComplete='email'
                placeholder='Enter your email address'
                required
                className='block w-full rounded-md border-0 px-3 py-1.5 text-gray-900  shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-amber-600 sm:text-sm sm:leading-6 dark:text-gray-800 dark:ring-gray-600 dark:placeholder:text-gray-500'
              />
            </div>
            {/* <div className="mt-2">
              <input
                id="password"
                name="password"
                type="password"
                onChange={handlePasswordChange}
                autoComplete="current-password"
                placeholder="Enter your password"
                required
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div> */}
            <div id={`sign-up-token-container${id}`} className='hidden'>
              <p className='py-2 pb-6 text-center text-sm text-gray-400'>
                We just sent you a temporary sign up code. Please check your
                inbox and paste the sign up code below.
              </p>
              <div className='mt-2'>
                <input
                  id={`token${id}`}
                  name='token'
                  type='text'
                  onChange={handleTokenChange}
                  onKeyDown={handleKeyDown}
                  placeholder='Enter your sign up code'
                  required
                  className='block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-amber-600 sm:text-sm sm:leading-6 dark:text-gray-800 dark:ring-gray-600 dark:placeholder:text-gray-500'
                />
              </div>
            </div>
          </div>
          <div>
            <button
              disabled={isLoading}
              ref={buttonRef}
              type='submit'
              className='relative flex w-full justify-center rounded-md bg-amber-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-amber-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-amber-600 disabled:bg-amber-600/30 disabled:text-transparent dark:bg-amber-600 dark:hover:bg-amber-600 disabled:dark:bg-amber-600/30'
              onClick={() => {
                void handleSignIn()
              }}
            >
              Continue
              <div
                className='inline-loader absolute'
                style={{
                  left: 'calc(50% - 7.5px)',
                  top: 'calc(50% - 7.5px)',
                  display: isLoading ? 'inline' : 'none',
                }}
              />
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

function EmailOTPSignOut() {
  const handleSignOut = () => {
    void signOut()
  }

  return (
    <div
      id='supabase-signout'
      className='hidden sm:mx-auto sm:w-full sm:max-w-[480px]'
    >
      <div className='px-6 pb-10 pt-6 sm:rounded-lg sm:px-12'>
        <button
          type='submit'
          className='flex w-full justify-center rounded-md bg-amber-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-amber-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-amber-600 dark:bg-amber-600 dark:hover:bg-amber-600'
          onClick={handleSignOut}
        >
          Sign out
        </button>
      </div>
    </div>
  )
}

function TeamspaceSignOut() {
  useEffect(() => {
    async function fetchMetaData() {
      try {
        const meta = await getUserMetaData()
        if (meta.cloudKitUserId) {
          document
            .getElementById('teamspace-signout')
            ?.classList.remove('hidden')
        }
        // Do something with the meta data
      } catch {
        // console.log('Not signed into a teamspace')
      }
    }
    void fetchMetaData()
  }, [])

  const handleSignOut = async () => {
    await signOut()
    // reload the page
    window.location.reload()
  }

  return (
    <div
      id='teamspace-signout'
      className='mx-auto hidden w-[400px] max-w-full pb-2 text-center'
    >
      <div className='mb-4 rounded-md border border-yellow-200 bg-yellow-100 p-4 text-sm dark:border-yellow-600 dark:bg-yellow-600/30'>
        <div className='flex items-center justify-center space-x-2'>
          <i className='far fa-triangle-exclamation -mt-2 text-lg text-yellow-600 dark:text-yellow-300' />
          <p className='text-sm font-bold text-gray-600 dark:text-gray-300'>
            Signed into a Teamspace
          </p>
        </div>
        <span className='mt-0 pt-0 text-left text-sm text-gray-600 dark:text-gray-300'>
          You{`'`}re logged in to a Teamspace with your email, but you got
          automatically logged out from iCloud. You can log out of the Teamspace
          now.
        </span>

        <div className='mx-auto mt-4 max-w-[217.3px]'>
          <div className='rounded-lg'>
            <button
              type='submit'
              className='flex w-full justify-center rounded-md bg-amber-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-amber-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-amber-600 dark:bg-amber-600 dark:hover:bg-amber-600'
              onClick={() => {
                void handleSignOut()
              }}
            >
              Sign out
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export function SignIn() {
  const [open, setOpen] = useState<boolean>(false)

  useEffect(() => {
    trackEvent('WEB - Login Page Visited')
  }, [])

  const joiningTeamspaceID = () => {
    // Get the current pathname
    const pathname = window.location.pathname + window.location.search

    // Define the pattern to match the structure 'join&id=UUID'
    const pattern = /join\?id=([\da-f-]+)/

    // Match the pattern to the pathname
    const match = pathname.match(pattern)

    // If there's a match and it has at least two elements (the full match and one capturing group)
    if (match?.[1]) {
      // Return the ID (which should be in the second element of the match array)
      return match[1]
    }

    // If no match is found, return undefined
    return undefined
  }

  const handleActiveView = (view: string) => {
    const defaultView = document.getElementById('default-signin-view') // default container
    const appleView = document.getElementById('apple-signin-view') // apple signin view
    const supabaseView = document.getElementById('supabase-signin-view') // supabase signin view

    if (view === 'default') {
      defaultView?.classList.remove('hidden')
      appleView?.classList.add('hidden')
      appleView?.classList.remove('flex')
      supabaseView?.classList.add('hidden')
      supabaseView?.classList.remove('flex')
    } else if (view === 'apple') {
      trackEvent('WEB - Login Apple Signin Option Clicked')
      appleView?.classList.remove('hidden')
      appleView?.classList.add('flex')
      defaultView?.classList.add('hidden')
      supabaseView?.classList.add('hidden')
      supabaseView?.classList.remove('flex')
    } else if (view === 'supabase') {
      trackEvent('WEB - Login Supabase Option Clicked')
      supabaseView?.classList.remove('hidden')
      supabaseView?.classList.add('flex')
      defaultView?.classList.add('hidden')
      appleView?.classList.add('hidden')
      appleView?.classList.remove('flex')
    }
  }

  const handleSignOutClick = () => {
    document.getElementById('sign-out-container')?.classList.add('hidden')
    document.getElementById('authContainer')?.classList.add('hidden')
  }

  const handleTeamspaceClick = (e: React.MouseEvent | React.KeyboardEvent) => {
    // Check if the background was clicked "teamspace-sign-in-background"
    if ((e.target as HTMLDivElement).id !== 'teamspace-sign-in-background')
      return

    document
      .getElementById('teamspace-sign-in-container')
      ?.classList.add('hidden')
    document.getElementById('authContainer')?.classList.add('hidden')
  }

  const trackMixpanelAppleSignIn = () => {
    trackEvent('WEB - Apple Signin Button Clicked')
  }

  return (
    <div className='fullscreen fixed z-50' id='authContainer'>
      {/* Teamspace Sign in (Supabase) */}
      <div
        className='fullscreen z-60 hidden backdrop-blur-md backdrop-brightness-75 backdrop-grayscale dark:backdrop-brightness-150'
        id='teamspace-sign-in-container'
        role='button'
        tabIndex={0}
        onClick={(e) => {
          handleTeamspaceClick(e)
        }}
      >
        <div
          className='flex min-h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8'
          id='teamspace-sign-in-background'
        >
          <div className='rounded-lg bg-white pt-4 text-center drop-shadow-2xl sm:mx-auto sm:w-full sm:max-w-[440px] dark:bg-[#1f1f1f]'>
            <NotePlanIcon />
            <h2 className='mt-2 text-center text-3xl font-medium leading-9 tracking-tight text-gray-700 dark:text-gray-200'>
              NotePlan
            </h2>
            <EmailOTPSignIn isTeamspace id='1' />
          </div>
        </div>
      </div>
      <div
        className='flex min-h-full shrink-0 justify-center bg-white dark:bg-gray-800 lg:px-0'
        id='sign-in-container'
      >
        <div className='relative z-10 flex w-[60%] flex-1 flex-col bg-white dark:bg-stone-900 px-4 py-10 md:flex-none lg:px-12 xl:px-48'>
          <main className=''>
            {/* default */}
            <div
              className='mx-auto flex w-full flex-col gap-14 md:px-0'
              id='default-signin-view'
            >
              {(() => {
                const teamspaceID = joiningTeamspaceID()
                return teamspaceID === undefined ? (
                  <Header
                    heading='Welcome to NotePlan!'
                    description='Choose a login / signup method below to get started'
                  />
                ) : (
                  <>
                    <Header
                      heading='Teamspace Invitation'
                      description='You need to be logged in to join a teamspace or use the native app'
                    />
                    <LoginCard
                      heading='Join using the native app (iOS or macOS)'
                      description='Open NotePlan on your device to accept the invitation'
                      src='60x60.svg'
                      allowDarkMode={false}
                      click={() => {
                        window.location.href = `noteplan://x-callback-url/acceptTeamspaceInvite?teamspace_id=${teamspaceID}`
                      }}
                    />
                    <a
                      href='https://testflight.apple.com/join/O10uVN0K'
                      target='_blank'
                      rel='noopener noreferrer'
                      className='-mt-12 text-sm text-orange-500 hover:text-orange-800 dark:text-orange-400 dark:hover:text-orange-300 flex items-center'
                    >
                      <i className='fas fa-circle-down text-sm mr-1'></i>
                      Download Beta
                    </a>

                    <div className='mt-4 text-center'>
                      <div className='flex items-center text-gray-600 dark:text-gray-300'>
                        <hr className='flex-grow border-t border-gray-300 my-1 dark:border-gray-400' />
                        <span className='mx-2 flex-shrink-0'>
                          or login to continue on the web
                        </span>
                        <hr className='flex-grow border-t border-gray-300 my-1 dark:border-gray-400' />
                      </div>
                      <p className='text-sm text-gray-500 dark:text-gray-300 mt-2'>
                        If you login with your Apple ID, make sure to login into
                        a teamspace as well
                      </p>
                    </div>
                  </>
                )
              })()}
              <div className='flex w-full flex-col gap-4'>
                <LoginCard
                  heading='I’m already using the app'
                  description='Sign in with Apple ID (I’m an existing user using the native MacOS / iOS app)'
                  src='apple.svg'
                  click={() => {
                    handleActiveView('apple')
                  }}
                />
                <LoginCard
                  heading='I want to use the web version with Email'
                  description='Signup / Login with email to get started with NotePlan web.'
                  src='globe.svg'
                  click={() => {
                    handleActiveView('supabase')
                  }}
                />
                <button
                  type='button'
                  className='cursor-pointer text-center text-xs text-gray-500 underline'
                  onClick={() => {
                    setOpen(true)
                  }}
                >
                  Having login issues or questions about data encryption?
                </button>
              </div>
            </div>

            {/* apple */}
            <div
              className='mx-auto hidden w-full flex-col gap-14 md:px-0'
              id='apple-signin-view'
            >
              <Header
                heading="Let's get started!"
                description='Use the same Apple ID you are logged in with your devices.'
              />
              <div className='flex w-full flex-col gap-4'>
                <TeamspaceSignOut />
                <div className='relative mx-auto h-[39.3px] w-[217.3px] rounded-md border border-dashed border-slate-400 p-0'>
                  <button
                    type='button'
                    id='apple-sign-in-button'
                    className='absolute -left-0.5 -top-0.5 m-0 bg-transparent p-0'
                    onClick={trackMixpanelAppleSignIn}
                  />
                  <div
                    className='inline-loader absolute'
                    style={{
                      left: 'calc(50% - 7.5px)',
                      top: 'calc(50% - 7.5px)',
                      zIndex: -1,
                    }}
                  />
                </div>
                <div className='flex flex-col justify-center'>
                  <p className='mb-6 mt-2 text-center'>or</p>
                  <a
                    className='mx-auto cursor-pointer'
                    href='https://apps.apple.com/app/apple-store/id1505432629?pt=118158142&ct=login&mt=8'
                  >
                    <div className='hidden xl:block'>
                      <img
                        src='download-appstore-button-mac.svg'
                        alt='Download NotePlan on the Mac App Store'
                        className='h-10 w-auto transition-all hover:opacity-80 active:opacity-100'
                      />
                    </div>

                    <div className='block xl:hidden'>
                      <img
                        src='download-appstore-button-ios.svg'
                        alt='Download NotePlan on the Mac App Store'
                        className='h-10 w-auto transition-all hover:opacity-80 active:opacity-100'
                      />
                    </div>
                  </a>
                </div>
                <button
                  type='button'
                  className='cursor-pointer text-center text-sm'
                  onClick={() => {
                    handleActiveView('supabase')
                  }}
                >
                  Not using an Apple device?
                </button>
              </div>
            </div>

            {/* web */}
            <div
              className='mx-auto hidden w-full flex-col gap-14 md:px-0'
              id='supabase-signin-view'
            >
              <Header
                heading="Let's get started!"
                description='Signup / Login with email to get started with NotePlan web.'
                info={
                  <div
                    className='mx-auto mt-4 w-fit rounded-lg bg-gray-100 p-4 text-center text-sm text-gray-800 dark:bg-gray-800 dark:text-gray-300'
                    role='alert'
                  >
                    <span className='font-medium'>Please Note:</span> Email
                    sign-up does not sync with the native apps yet.
                  </div>
                }
              />
              <div className='flex w-full flex-col gap-4'>
                <div
                  id='email-sign-in'
                  className='transition-opacity duration-500'
                >
                  <EmailOTPSignIn isTeamspace={false} id='2' />
                  <div className='flex justify-center'>
                    <button
                      type='button'
                      className='cursor-pointer text-center text-sm'
                      onClick={() => {
                        handleActiveView('apple')
                      }}
                    >
                      Want to login with Apple ID?
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
        <div className='hidden bg-orange-100 dark:bg-orange-100 sm:contents md:block lg:relative lg:block lg:flex-1'>
          {/* <Image
            className="absolute inset-0 h-full w-full object-cover"
            src={backgroundImage}
            alt=""
            unoptimized
          /> */}
          <div className='absolute top-8 right-8'>
            <NotePlanIcon type='full' />
          </div>
          <div className='flex items-center justify-center h-full py-24 overflow-hidden'>
            <img
              src='web-screenshot-mac.png'
              alt=''
              className='ml-60 transform scale-[1.5] origin-left'
              loading='lazy'
            />
          </div>
        </div>
        <SlideOver open={open} setOpen={setOpen} />
      </div>
      {/* Sign Out */}
      <div
        className='fullscreen z-60 hidden backdrop-blur-md backdrop-brightness-75 backdrop-grayscale dark:backdrop-brightness-150'
        id='sign-out-container'
        onClick={handleSignOutClick}
        role='button'
        tabIndex={0}
      >
        <div className='flex min-h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8'>
          <div className='rounded-lg bg-white pt-4 text-center drop-shadow-2xl sm:mx-auto sm:w-full sm:max-w-[340px] dark:bg-[#1f1f1f]'>
            <NotePlanIcon />
            <h2 className='mt-2 text-center text-3xl font-medium leading-9 tracking-tight text-gray-700 dark:text-gray-200'>
              NotePlan
            </h2>

            {/* Supabase */}
            <EmailOTPSignOut />

            {/* CloudKit */}
            <div
              className='px-6 py-12 sm:px-12'
              id='apple-sign-out-button-container'
            >
              <button
                type='button'
                id='apple-sign-out-button'
                className='bg-transparent'
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
