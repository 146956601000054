import { Editor, Mark } from "@tiptap/core"
import { Node } from "prosemirror-model"
import attachmentURLCache from "../helpers/attachmentURLCache"
import { EditorState } from "prosemirror-state"
import { v4 as uuidv4 } from "uuid"

// We could then search for the block ID and update it with the image
const imageExtension = [
  "png",
  "jpeg",
  "jpg",
  "bmp",
  "tiff",
  "ico",
  "svg",
  "drawing",
]

export function uniqueFilename(filename: string, state: EditorState): string {
  if (filename === "image.png") {
    return uuidv4() + ".png" // Create a uuid for the filename
  }

  let uniqueFilename = filename

  const isFilenameUnique = (filenameToCheck: string) => {
    let foundDuplicate = false
    state.doc.descendants((node: Node) => {
      if (foundDuplicate) {
        return false
      } // Early exit if duplicate is found
      node.marks.forEach((mark) => {
        // Check if the attribute exists
        if (mark.attrs.filename === undefined) {
          return
        }

        const markFilenameLastComponent = mark.attrs.filename.split("/").pop()
        const filenameToCheckLastComponent = filenameToCheck.split("/").pop()
        if (
          mark.type.name === "inlineAttachment" &&
          markFilenameLastComponent === filenameToCheckLastComponent
        ) {
          foundDuplicate = true
          return
        }
      })
      return true
    })
    return !foundDuplicate
  }

  while (!isFilenameUnique(uniqueFilename)) {
    const extensionIndex = uniqueFilename.lastIndexOf(".")
    const baseName =
      extensionIndex !== -1
        ? uniqueFilename.slice(0, extensionIndex)
        : uniqueFilename
    const extension =
      extensionIndex !== -1 ? uniqueFilename.slice(extensionIndex) : ""
    const randomId = Math.random().toString(36).substring(2, 8) // Generate a short random id
    uniqueFilename = `${baseName}_${randomId}${extension}`
  }

  return uniqueFilename
}

// Utils
export function isFilenameImage(filename: string, title: string): boolean {
  const ext = filename.split(".").pop()
  return title === "image" || imageExtension.includes(ext ?? "")
}

// Gets you the URLs of all attachments in the editor
export function findAttachmentURLs(editor: Editor | null): string[] {
  if (editor == null) {
    return []
  }
  const state = editor.state
  const urls: string[] = []

  state.doc.nodesBetween(0, state.doc.content.size, (node) => {
    node.marks.forEach((mark) => {
      if (mark.type.name === "inlineAttachment") {
        // If there's a drawingSrc, first add this. After the drawing always follows the png version of the drawing
        if (mark.attrs.drawingSrc) {
          urls.push(
            JSON.stringify({
              title: mark.attrs.title,
              filename: mark.attrs.filename,
              url: mark.attrs.drawingSrc,
            })
          )
        }

        urls.push(
          JSON.stringify({
            title: mark.attrs.title,
            filename: mark.attrs.drawingSrc
              ? mark.attrs.filename.replace(".drawing", ".png")
              : mark.attrs.filename,
            url: mark.attrs.src,
          })
        )
      }
    })
  })

  return urls
}

// Get the blob url from an image or file
async function downloadAsset(downloadUrl: string): Promise<string> {
  try {
    const response = await fetch(downloadUrl)
    if (!response.ok) {
      throw new Error(`Failed to download asset. Status: ${response.status}`)
    }
    const blob = await response.blob()
    return URL.createObjectURL(blob)
  } catch (error) {
    console.error("Error downloading asset:", error)
    return ""
  }
}

// Updates the attachment with the new URL and marks it as downloaded so the image gets rendered and it doesn't get re-downloaded
function setAttachmentAttribute(
  editor: Editor,
  url: string,
  downloadUrl: string | null,
  drawingUrl: string | null,
  HTMLAttributes: any
) {
  const { state, view } = editor
  const tr = state.tr
  var nodeToUpdate: { pos: number; node: Node; mark: any } | null = null

  state.doc.nodesBetween(0, state.doc.content.size, (node, pos) => {
    node.marks.forEach((mark) => {
      if (mark.type.name === "inlineAttachment") {
        if (downloadUrl && mark.attrs.downloadUrl === downloadUrl) {
          nodeToUpdate = { pos, node, mark }
        } else if (drawingUrl && mark.attrs.drawingUrl === drawingUrl) {
          nodeToUpdate = { pos, node, mark }
        }
        return
      }
    })
  })

  if (nodeToUpdate) {
    const { pos, node, mark } = nodeToUpdate as {
      pos: number
      node: Node
      mark: any
    }

    var attr = HTMLAttributes

    if (downloadUrl) {
      attr.downloaded = true
      attr.src = url
    } else if (drawingUrl) {
      attr.drawingSrc = url
    }

    tr.addMark(pos, pos + node.nodeSize, mark.type.create(attr))
  }

  const newState = state.apply(tr)
  view.updateState(newState)
}

// Actual inline attachment extension

export const InlineAttachment = Mark.create({
  name: "inlineAttachment",
  content: "inline*",
  selectable: true,
  indentable: false,

  addAttributes() {
    return {
      title: {
        default: false,
        parseHTML: (element) => element.getAttribute("title"),
        renderHTML: (attributes) => ({
          title: attributes.title,
        }),
      },
      downloaded: {
        default: false,
        parseHTML: (element) => element.getAttribute("downloaded"),
        renderHTML: (attributes) => ({
          downloaded: attributes.downloaded,
        }),
      },
      filename: {
        default: null,
        parseHTML: (element) => element.getAttribute("filename"),
        renderHTML: (attributes) => ({
          filename: attributes.filename,
        }),
      },
      downloadUrl: {
        default: null,
        parseHTML: (element) => element.getAttribute("downloadUrl"),
        renderHTML: (attributes) => ({
          downloadUrl: attributes.downloadUrl,
        }),
      },
      src: {
        default: null,
        parseHTML: (element) => element.getAttribute("src"),
        renderHTML: (attributes) => ({
          src: attributes.src,
        }),
      },
      drawingUrl: {
        default: null,
        parseHTML: (element) => element.getAttribute("drawingUrl"),
        renderHTML: (attributes) => ({
          drawingUrl: attributes.drawingUrl,
        }),
      },
      drawingSrc: {
        default: null,
        parseHTML: (element) => element.getAttribute("drawingSrc"),
        renderHTML: (attributes) => ({
          drawingSrc: attributes.drawingSrc,
        }),
      },
    }
  },

  parseHTML() {
    return [
      {
        tag: "img",
        getAttrs: (element: HTMLElement | string) => {
          if (typeof element === "string") {
            return false
          }

          if (element.getAttribute("data-content-type") === this.name) {
            return {
              src: element.getAttribute("src") || null,
            }
          }

          return false
        },
      },
    ]
  },

  renderHTML({ HTMLAttributes }) {
    const {
      src,
      filename,
      downloadUrl,
      downloaded,
      title,
      drawingUrl,
      drawingSrc,
    } = HTMLAttributes
    const isImage = isFilenameImage(filename ?? "", title ?? "")

    // When the user downloads the file, give it a proper name
    let downloadName = filename
      ? decodeURIComponent(filename.split("/").pop())
      : "download"

    // If we have a proper title, use that as the downloadName
    if (title.length > 0 && title !== "image" && title !== "file") {
      downloadName = title
    }

    let imageSrc = HTMLAttributes.src

    if (!src && filename && downloadUrl && !downloaded) {
      if (attachmentURLCache.has(filename) && !drawingUrl) {
        setAttachmentAttribute(
          this.editor!,
          attachmentURLCache.get(filename)!,
          downloadUrl,
          null,
          HTMLAttributes
        )

        // Update it here otherwise it won't be set
        imageSrc = attachmentURLCache.get(filename)!
      } else if (downloadUrl && downloadUrl.length > 0) {
        // If it's available, we need to download the drawing and the png. We use two assets, but show only one. The png is for the web version to display the drawing.
        if (drawingUrl && !drawingSrc) {
          downloadAsset(drawingUrl).then((url) => {
            if (!url || url.length === 0) {
              return
            }
            setAttachmentAttribute(
              this.editor!,
              url,
              null,
              drawingUrl,
              HTMLAttributes
            )
          })
        }

        // If the downloadUrl is set, download the asset
        downloadAsset(downloadUrl).then((url) => {
          if (!url || url.length === 0) {
            return
          }

          // Cache the url to the blob so we don't need to redownload the image
          attachmentURLCache.set(filename, url)
          setAttachmentAttribute(
            this.editor!,
            url,
            downloadUrl,
            null,
            HTMLAttributes
          )
        })

        // Temporarily load the old one again
        imageSrc = attachmentURLCache.get(filename)!
      }
    }

    if (!isImage) {
      return [
        "span",
        {
          ...HTMLAttributes,
          "data-content-type": this.name,
          contenteditable: "false",
        },
        ["div", { class: "inline-loader" }],
        [
          "a",
          {
            href: HTMLAttributes.src,
            target: "_self",
            download: downloadName,
          },
          ["i", { class: "far fa-paperclip" }],
          ["span", 0],
        ],
      ]
    } else {
      return [
        "span",
        {
          isDrawing: drawingUrl ? "true" : "false",
          ...HTMLAttributes,
          "data-content-type": this.name,
          style: "position: relative; display: inline-block;",
          contenteditable: "false",
        },
        ["div", { class: "inline-loader", style: "position: absolute;" }],
        [
          "img",
          {
            src: imageSrc,
            style: "height: auto;", // Ensure the image fills the container
          },
        ],
        [
          "a",
          {
            href: src,
            download: downloadName,
            target: "_self",
            style: "position: absolute; top: 0; right: 2px; padding: 5px;", // Position the download link
            class: "download-button", // Add a class for styling
          },
          [
            "i",
            {
              class: "fa fa-circle-down",
              style: "color: #000000AA; font-size: 19px; padding: 4px;",
            },
          ],
          ["div", 0], // Use FontAwesome icon
        ],
      ]
    }
  },

  // addProseMirrorPlugins() {
  //   const plugins = [
  //     new Plugin({
  //       props: {
  //         // This `props` object can contain ProseMirror view props, such as event handlers
  //         handleDOMEvents: {
  //           mouseover(view, event) {
  //             console.log("mouse over", event.target);
  //             const downloadButton = (
  //               event.target as HTMLElement
  //             ).querySelector(".download-button") as HTMLElement;
  //             if (downloadButton) {
  //               downloadButton.style.display = "block"; // Show the download button
  //               return true;
  //             }
  //             return false; // Return true if the event is handled and should not propagate
  //           },
  //           mouseout(view, event) {
  //             console.log("mouse out", event.target);
  //             const downloadButton = (
  //               event.target as HTMLElement
  //             ).querySelector(".download-button") as HTMLElement;
  //             if (downloadButton) {
  //               downloadButton.style.display = "none"; // Hide the download button
  //               return true; // Event is handled
  //             }

  //             return false;
  //           },
  //         },
  //       },
  //     }),
  //   ];

  //   return plugins;
  // },
})
