import { useCloudKitClient } from '../providers/CloudKitClientProvider'
import { useUserState } from '../providers/UserProvider'
import { privateKeys } from '../utils/queryKeyFactory'
import { useSafeQuery } from './useSafeQuery'

export default function useLoginCheck() {
  const ck = useCloudKitClient()
  const user = useUserState()

  // eslint-disable-next-line @tanstack/query/exhaustive-deps
  const queryResult = useSafeQuery({
    enabled: !!user && !!user.cloudKitUserId,
    retry: 0,
    refetchOnWindowFocus: true,
    refetchOnMount: true,
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: privateKeys.logincheck,
    queryFn: async () => {
      if (user.cloudKitUserId) {
        return ck.testFetch()
      }
    },
  })

  return queryResult
}
