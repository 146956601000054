import { useQuery } from '@tanstack/react-query'
import dayjs, { type Dayjs } from 'dayjs'
import { useCallback, useRef } from 'react'
import { useGapiIsReady } from '../providers/GapiProvider'
import {
  accessTokenContext,
  useAccessToken,
  useAccessTokenQueryClient,
} from '../providers/AccessTokenProvider'
import { type CalendarEvent } from '../modules/calendar/CalendarEvent'
import { calculateEventPosition } from '../modules/calendar/timeline/utils/event'

function gapiEventsToCalendarEvents(
  data: gapi.client.calendar.Event[],
  selectedDay: Dayjs
): CalendarEvent[] {
  return data.map((event) => {
    const start = event.start.dateTime ?? event.start.date
    const end = event.end.dateTime ?? event.end.date
    return {
      id: event.id,
      content: event.summary,
      position: calculateEventPosition(dayjs(start), dayjs(end), selectedDay),
      startDate: dayjs(start),
      endDate: dayjs(end),
      contentType: 'event',
      allDay: Boolean(event.start.date),
      description: event.description,
    }
  })
}

export function useGoogleCalendarEvents(selectedDay: dayjs.Dayjs) {
  const key: string = selectedDay.format('YYYYMMDD')
  const gapiIsReady = useGapiIsReady()
  const { data: accessToken, isStale } = useAccessToken()
  const accessTokenQueryClient = useAccessTokenQueryClient()
  const hasInvalidatedQueries = useRef(false) // to avoid infinite rendering loops

  const queryResult = useQuery<
    gapi.client.calendar.Event[],
    gapi.client.HttpRequestRejected,
    CalendarEvent[],
    string[]
  >({
    context: accessTokenContext,
    enabled: Boolean(accessToken) && !isStale && gapiIsReady,
    refetchInterval: 5000 * 60, // every 5 minute
    refetchIntervalInBackground: false,
    retry: false, // no need because we refetch every 5 minute anyway and we need it to fail fast for 401 and 403 errors
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: ['googleCalendarEvents', key],
    queryFn: async () => {
      const request = {
        calendarId: 'primary',
        // select only events from selectedDay
        timeMin: selectedDay.startOf('day').toISOString(),
        timeMax: selectedDay.endOf('day').toISOString(),
        showDeleted: false,
        singleEvents: true,
        orderBy: 'startTime' as gapi.client.calendar.EventsOrder,
      }
      const response = await gapi.client.calendar.events.list(request)
      hasInvalidatedQueries.current = false
      return response.result.items
    },
    select: useCallback(
      (data: gapi.client.calendar.Event[]) =>
        gapiEventsToCalendarEvents(data, selectedDay),
      [selectedDay]
    ), // pass selectedDay and use useCallback for stability
  })
  // handle 401 and 403 error
  const { error } = queryResult
  if (
    !isStale &&
    (error?.status === 401 || error?.status === 403) &&
    !hasInvalidatedQueries.current
  ) {
    hasInvalidatedQueries.current = true
    void accessTokenQueryClient.invalidateQueries()
  }
  return queryResult
}
