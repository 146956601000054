import CloudLoading from '../../components/CloudLoading'
import { copyMarkdown } from '../../utils/commons'
import OpenButton from '../calendar/OpenButton'
import { showSignOut, useUserState } from '../../providers/UserProvider'
import { useSelectedDateDispatch } from '../../providers/SelectedDateProvider'
import { useIsMobile } from '../../hooks/useIsMobile'
import { NoteType } from '../../utils/syncUtils'
import { NoteTitle } from './NoteTitle'

type Props = {
  needsUpload: boolean
  showCalendar: boolean
  toggleCalendar: () => void
  noteType: NoteType
}

export const NoteHeader = ({ needsUpload, showCalendar, toggleCalendar, noteType }: Props) => {
  const user = useUserState()
  const selectedDateDispatch = useSelectedDateDispatch()
  const isMobile = useIsMobile()
  const isTeamItem = noteType === NoteType.TEAM_SPACE_CALENDAR_NOTE || noteType === NoteType.TEAM_SPACE_NOTE

  return (
    <div className={`flex justify-between pt-1.5 pb-1 relative ` + (isTeamItem ? `border-b-2 border-dotted border-green-300` : ``)}>
      {/* To show the user that this is shared note (so that there are no accidents)  */}
      <div id="spacer-note-heading" className="w-2.5"></div>
      <div className="note-header w-full ml-auto mr-auto flex justify-between pr-5">
        <NoteTitle onToday={() => selectedDateDispatch({ type: 'today' })} />

        <div className="flex items-center justify-center"></div>
        <div className="flex">
          <button
            className="py-1 mr-2 hidden"
            onClick={(_e) => {
              copyMarkdown()
            }}
          >
            Copy Markdown
          </button>
          <div id="copy-done-check" className="hidden">
            <i className="fas fa-check"></i>
          </div>
          <button
            data-tooltip-id="my-tooltip"
            data-tooltip-content="Previous day (⌃⌘←)"
            type="button"
            className="flex flex-none items-center justify-center p-1.5 hover:bg-gray-500/25  "
            onClick={() => selectedDateDispatch({ type: 'shift', direction: -1 })}
          >
            <span className="sr-only">Previous day</span>
            <i className="h-5 w-5 fa-solid fa-arrow-left text-sm" aria-hidden="true" />
          </button>

          <button
            data-tooltip-id="my-tooltip"
            data-tooltip-content="Next day (⌃⌘→)"
            type="button"
            className="flex flex-none items-center justify-center p-1.5 hover:bg-gray-500/25"
            onClick={() => selectedDateDispatch({ type: 'shift', direction: 1 })}
          >
            <span className="sr-only">Next day</span>
            <i className="h-5 w-5 fa-solid fa-arrow-right text-sm" aria-hidden="true" />
          </button>
          {isMobile && (
            <div className="ml-8 w-full">
              <OpenButton onClick={toggleCalendar} showCalendar={showCalendar} />
            </div>
          )}
        </div>
      </div>

      <div className="flex pr-2">
        <CloudLoading needsUpload={needsUpload} />
        <button
          data-tooltip-id="my-tooltip"
          data-tooltip-content="Logout"
          type="button"
          className="flex flex-none items-center justify-center p-1.5 hover:bg-gray-500/25"
          onClick={() => showSignOut(!!user.supabaseUserId, !!user.cloudKitUserId)}
        >
          <i className="fas fa-user"></i>
        </button>
      </div>
    </div>
  )
}
