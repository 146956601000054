import {
  type QueryKey,
  type UseQueryResult,
  useQueryClient,
} from '@tanstack/react-query'
import type CloudKit from 'tsl-apple-cloudkit'
import { type PostgrestError } from '@supabase/supabase-js'
import { useCloudKitClient } from '../providers/CloudKitClientProvider'
import {
  type Note,
  type NoteType,
  getSupabaseFileExtension,
  isCalendarNote,
  isTeamspaceNote,
} from '../utils/syncUtils'
import { useUserState } from '../providers/UserProvider'
import { cacheKeys, noteQueryKey } from '../utils/queryKeyFactory'
import {
  useCachedNotesQueryClient,
  useNotesExtension,
} from '../providers/CachedNotesProvider'
import {
  fetchNoteByFilename,
  fetchNoteById,
  listMyTeamspaceNotes,
} from '../lib/supabase/fetch'
import { useSafeQuery } from './useSafeQuery'

export type useNoteConfig = {
  noteType: NoteType
  recordName: string | null
  filename: string
  parent: string | null
}

export default function useNote(
  config: useNoteConfig | null
): UseQueryResult<Note, Error | CloudKit.CKError | PostgrestError> {
  const ck = useCloudKitClient()
  const queryClient = useQueryClient()
  const cachedNotesQueryClient = useCachedNotesQueryClient()
  const user = useUserState()

  // if filename doesn't contain an extension, we add the extension according if it is a private or team note
  const { data: privateExt } = useNotesExtension(user)
  const ext = isTeamspaceNote(config?.noteType)
    ? getSupabaseFileExtension()
    : privateExt

  if (ext && config && config.filename) {
    if (
      config.filename.split('.').length === 1 ||
      !config.filename.split('.')[1]
    ) {
      config.filename = `${config.filename}.${ext}`
    }
  }

  const queryKey: QueryKey =
    config && config.recordName !== 'search' ? noteQueryKey(config) : undefined

  // We don't get a notification when access to a teamspace was removed, so actively check this here, when something suspicious happens.
  // Two cases are possible: We query for an ID but get an "Note not found" error we get an empty calendar note back (happens more commonly, but the teamspace check is as 'cheap' as possible)
  async function verifyTeamspaceAccess() {
    const teamspaceNotes = await listMyTeamspaceNotes()

    // Update the cache
    cachedNotesQueryClient.setQueryData<Map<string, Note>>(
      cacheKeys.teamProjectNotes(user.supabaseUserId),
      (oldData: Map<string, Note>) => {
        const newMap = new Map<string, Note>()
        teamspaceNotes.forEach((recordName) => {
          if (oldData.has(recordName)) {
            newMap.set(recordName, oldData.get(recordName))
          }
        })
        return newMap
      }
    )
  }

  function findNote(notesKey: QueryKey, config: useNoteConfig) {
    const notes =
      cachedNotesQueryClient.getQueryData<Map<string, Note>>(notesKey)
    if (!notes) {
      return undefined
    }
    if (isCalendarNote(config.noteType)) {
      return Array.from(notes.values()).find((note) => {
        if (isTeamspaceNote(config.noteType)) {
          return (
            note.filename === config.filename && note.parent === config.parent
          )
        }
        return note.filename === config.filename
      })
    }
    if (notes instanceof Map) {
      return notes.get(config.recordName)
    }
  }

  const queryResult = useSafeQuery<
    Note,
    Error | CloudKit.CKError | PostgrestError
  >({
    enabled:
      Boolean(ext) &&
      Boolean(user) &&
      Boolean(config) &&
      Boolean(config.filename),
    retry: 2,
    refetchOnWindowFocus: true,
    refetchOnMount: true,
    refetchOnReconnect: 'always',
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey,
    staleTime: 0, // set this to a minimal value, so we can utilize initialData and prevent an immidiate refetch. UPDATE: Set it to 0 otherwise we don't load the updated note. It will still fetch the note from the cache first, but update it immidiately.
    initialData: () => {
      if (!config || !user) {
        return undefined
      }
      if (user.cloudKitUserId && !isTeamspaceNote(config.noteType)) {
        const queryKey = isCalendarNote(config.noteType)
          ? cacheKeys.privateCalendarNotes(user.cloudKitUserId)
          : cacheKeys.privateProjectNotes(user.cloudKitUserId)
        return findNote(queryKey, config)
      }
      if (user.supabaseUserId) {
        const queryKey = isTeamspaceNote(config.noteType)
          ? isCalendarNote(config.noteType)
            ? cacheKeys.teamCalendarNotes(user.supabaseUserId)
            : cacheKeys.teamProjectNotes(user.supabaseUserId)
          : isCalendarNote(config.noteType)
            ? cacheKeys.privateCalendarNotes(user.supabaseUserId)
            : cacheKeys.privateProjectNotes(user.supabaseUserId)
        return findNote(queryKey, config)
      }
    },
    queryFn: async () => {
      if (!config || !user) {
        return undefined
      }

      if (user.cloudKitUserId && !isTeamspaceNote(config.noteType)) {
        if (!isCalendarNote(config.noteType)) {
          // eslint-disable-next-line no-console
          console.debug('[useNote] query project note', config.recordName)
          return ck.fetchNoteByRecordName(config.recordName)
        }
        // eslint-disable-next-line no-console
        console.debug('[useNote] query calendar note', config.filename)
        const calendarNote = queryClient.getQueryData<Note>(queryKey)
        if (calendarNote?.recordName) {
          return ck.fetchNoteByFilename(config.filename, true)
        }
        return ck.fetchNoteByFilename(config.filename, false)
      }

      if (user.supabaseUserId) {
        // eslint-disable-next-line no-console
        console.debug('[useNote] query supabase note', config)
        if (!isCalendarNote(config.noteType)) {
          return fetchNoteById(user.supabaseUserId, config.recordName)
        }
        // This can be a private or a teamspace calendar note (depends on the type and parent, we need the parent so we know which teamspace)
        const note = await fetchNoteByFilename(
          user.supabaseUserId,
          config.filename,
          config.noteType,
          config.parent
        )

        // An empty teamspace note can mean we lost access, but doesn't have to, check now:
        if (note.isEmpty && isTeamspaceNote(config.noteType)) {
          verifyTeamspaceAccess()
        }

        return note
      }

      throw new Error('No client signed in')
    },
  })

  const { isError, error } = queryResult

  // If the note wasn't found, and it's a teamspace note, it's very likely that we lost access to the teamspace, check this and update the cache.
  if (
    user?.supabaseUserId &&
    isError &&
    (error as Error).message === 'Note not found' &&
    isTeamspaceNote(config?.noteType)
  ) {
    verifyTeamspaceAccess()
  }

  return queryResult
}
